import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { firestore, auth } from 'config/firebase';

const CustomContainer = styled(Container)`
  h3,
  div {
    text-align: center;
  }
  p {
    text-align: center;
  }
`;

export default function TestDetails(props) {
  // const { match } = props;
  const [user1, setUser1] = useState({});
  const [courses, setCourses] = useState([]);
  // const [course, setCourse] = useState();
  const { id } = useParams();
  console.log(id);
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setUser1(user);
    });
  }, [1]);

  // const course = {
  //   location: 'E11 Islamabad',
  //   instructor: 'Mr. Noman',
  //   joiningDate: '22-10-2020',
  //   joiningFee: '$15',
  //   transactionDate: '15-10-2020',
  //   paymentStatus: 'Complete',
  //   test: {
  //     id: '101',
  //     name: 'Truck driving test',
  //   },
  // };

  useEffect(() => {
    if (user1.uid) {
      firestore
        .collection('testHistory')
        .where('id', '==', id)
        .onSnapshot(snapshot => {
          snapshot.docs.map(doc => setCourses(doc.data()));
        });
    }
  }, [user1.uid]);
  console.log(user1);
  console.log(courses);

  // useEffect(() => {
  //   setCourse(courses[0]);
  // }, [courses]);
  return (
    <CustomContainer fluid style={{ marginTop: '5%' }}>
      <Row className="justify-content-center">
        <Col xs={12} sm={12}>
          <h1>Test Details</h1>
        </Col>

        {courses ? (
          <Col className="mt-2">
            {/* <p>{courses}</p>
             */}
           
            <p>
              <b>Test Id: {courses.id}</b>
            </p>
            <p>Test Name: {courses.title}</p>
            <p>Location: {courses.location}</p>
            <p>Course Instructor: {courses.instructor}</p>
            <p>Course Joining Date: {courses.joiningDate}</p>
            <p>Course Joining Fee: {courses.joiningFee}</p>
            <p>Transaction Date: {courses.transactionDate}</p>
            <p>Payment Status: {courses.paymentStatus}</p>
          </Col>
        ) : (
          <Col className="mt-2">
            <h2>Loading</h2>
          </Col>
        )}
        <Col sm={12} xs={12}>
          <div>
            <Button
              onClick={() => (window.location = '/test-history')}
              className="mt-2"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
    </CustomContainer>
  );
}

import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

// components
import RoutesFrontend from './Routes/RoutesFrontend';
import RoutesLogin from './Routes/RoutesLogin';
import Register from './Routes/RoutesRegister';
import VerifyOTP from './Routes/RoutesRegister/VerifyOTP/VerifyOTP';

let analyticsTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
// Google Analytics Code
if (analyticsTrackingId) {
  ReactGA.initialize(analyticsTrackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

//History
const history = createBrowserHistory();
history.listen(location => {
  window.scrollTo(0, 0);

  if (analyticsTrackingId)
    ReactGA.pageview(location.pathname + location.search);
});

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/register" component={Register} />
        <Route path="/login" component={RoutesLogin} />
        <Route path="/" component={RoutesFrontend} />
      </Switch>
    </Router>
  );
}

export default Routes;

import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { Formik } from 'formik';
import Countdown from 'react-countdown';
import { axiosClient } from 'helper';
import { useParams, useHistory } from 'react-router-dom';
import { BASE_URL_V2 } from 'store';
import { useSelector } from 'react-redux';

const CardTitle = styled(Card.Title)`
  color: #fc8200;
`;

const CardText = styled(Card.Text)`
  font-weight: bold;
`;

const Options = styled(Form.Check)`
  background-color: #f0f0f0;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 4px;

  .form-check-input {
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    border: 1px;
  }
`;

const QuestionCard = styled(Card)`
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.12);
`;

const RightScore = styled.h3`
  text-align: center;
  i {
    color: green;
    margin-right: 10px;
  }
`;

const WrongScore = styled.h3`
  text-align: center;
  i {
    color: red;
    margin-right: 10px;
  }
`;

const Timer = styled.div`
  display: flex;
  align-items: center;

  p,
  h3 {
    margin-bottom: 0px;
  }
`;

export default function BasicTest(props) {
  const {
    setHideResultCard,
    quizType,
    setScore,
    setUserName,
    setTotalQuestions,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    setCourseId
  } = props;

  const history = useHistory();

  // getting the testId from url params.
  let { testId } = useParams();

  // getting username from state.
  const { firstName, lastName } = useSelector(state => state.studentDetails);

  // getting userId from state.
  const { userId } = useSelector(state => state.studentLogin);

  const [correctScore, setCorrectScore] = useState(0);
  const [wrongScore, setWrongScore] = useState(0);
  // const [startTime, setStartTime] = useState(null);
  // const [endTime, setEndTime] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [date, setDate] = useState(0);
  const [testDuration, setTestDuration] = useState(0);
  const [hidden, setHidden] = useState(false);

  const [loading, setLoading] = useState(true);
  const [submitAnswerLoading, setSubmitAnswerLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);

  // function to get the questions for testId.
  async function getTestQuestions(testId) {
    setLoading(true);
    console.log(testId,'testId')
    try {
      const { data } = await axiosClient().get(
        `/student/getQuestions?testId=${testId}`,
      );
      setQuestions(data);
      getTestDuration(testId);
      console.log(data,'shabam');
    } catch (error) {
      setError(error);
      console.log(error,'shbam');
      setLoading(false);
    }
  }

  // function to get the test duration for the test.
  async function getTestDuration(testId) {
    setLoading(true);
    try {
      const { data } = await axiosClient().get(`/student/getTestDuration?id=${testId}`);
      console.log(data);
      setTestDuration(data.duration);
      console.log(data,'durationc')
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
      setLoading(false);
    }
  }

  // function for ending test and showing result card.
  function endTest() {
    setScore([correctScore, wrongScore]);
    setTotalQuestions(questions.length);
    setUserName(`${firstName} ${lastName}`);
    setHideResultCard(false);
    setHidden(true);
    setEndTime(moment().format('DD/MM/YYYY, hh:mm:ss'));
  }

  async function submitAnswer(answer) {
    console.log( questions.length,questions)
    // checking the current question index is less than the length of total questions.
    if (questionIndex < questions.length) {
      const dataToBeSent = {
        questionId: questions[questionIndex]._id,
        answer: answer,
        userId: userId,
        testId: testId,
      };

      try {
        setSubmitAnswerLoading(true);
        const { data } = await axiosClient().post(
          '/student/submitAnswer',
          dataToBeSent,
        );
        console.log(data);
        setQuestionIndex(questionIndex + 1);
        if (data.answer === true) {
          setCorrectScore(correctScore + 1);
          console.log('rrrrrrrrrr')
          setSubmitAnswerLoading(false);
        } else if (data.answer === false) {
          setWrongScore(wrongScore + 1);
          setSubmitAnswerLoading(false);
        } else {
          setSubmitAnswerLoading(false);
        }
      } catch (error) {
        console.error(error);
        setSubmitAnswerLoading(false);
      }
    }
  }

  // fetching all questions for test from backend.
  useEffect(() => {
    getTestQuestions(testId);
  }, [testId]);

  // setting the countdown timer.
  useEffect(() => {
    setDate(Date.now() + 1000 * 59.8 * testDuration);
  }, [testDuration]);

  // Checking if questions finished and ending test.
  useEffect(() => {
    if (
      !submitAnswerLoading &&
      !loading &&
      questions &&
      questions.length !== 0 &&
      questionIndex === questions.length
    ) {
      endTest();
    }
  }, [questionIndex, loading, questions, submitAnswerLoading]);

  // Redirecting to initial form page when user refreshes page during the test.
  useEffect(() => {
    if (sessionStorage.getItem('visited') === '1') {
      history.push('/theoryTestForm');
    }
    sessionStorage.setItem('visited', '1');
  }, []);

  useEffect(() => {
    if (!hidden) setStartTime(moment().format('DD/MM/YYYY, hh:mm:ss'));
  }, [hidden]);

  // component for the countdown timer.
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      endTest();
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          fontSize: '1.25rem',
        }}
      >
        <strong>Remaining Time: </strong>
        <strong style={{ marginLeft: '0.25rem' }}> {minutes} </strong>
        <p> m </p>
        <strong style={{ marginLeft: '0.25rem' }}> {seconds} </strong>
        <p> s</p>
      </div>
    );
  };

  return (
    <>
      {!hidden && questionIndex < questions.length ? (
        !loading ? (
          <Row
            className="justify-content-center align-items-top"
            style={{ marginTop: '60px' }}
          >
            <Col xs={12} sm={10}>
              <QuestionCard>
                {submitAnswerLoading ? <i
                      style={{ marginLeft: '1.5rem' }}
                      className="fa fa-spin fa-spinner"
                      aria-hidden="true"
                    ></i> : 
                <Row className="justify-content-center align-items-center">
                  <Col
                    xs={12}
                    sm={12}
                    className="text-center"
                    style={{ padding: '15px' }}
                  >
                    <img
                      src={`${questions[questionIndex].questionImage}`}
                     
                      width="500px"
                      height="auto"
                    />
                  </Col>
                  <Col className="text-center">
                    {/* <audio
                      src={`${BASE_URL}/${questions[questionIndex].questionAudio}`}
                      controls
                      controlsList="nodownload"
                    /> */}
                    <audio controls>
                      <source
                        src={`${questions[questionIndex].questionAudio}`}
                        type="audio/mpeg"
                      />
                    </audio>
                  </Col>
                </Row>}
                {!submitAnswerLoading ? (
                  <Card.Body>
                    <CardTitle>
                      <h2>Q{questionIndex + 1}</h2>
                    </CardTitle>
                    <Formik
                      // enableReinitialize
                      onSubmit={(values, { resetForm }) => {
                        console.log('Answer Submitted');
                        submitAnswer(values.answer);
                      }}
                      initialValues={{
                        answer: '',
                      }}
                    >
                      {({ handleSubmit, handleChange, values }) => (
                        <>
                          <Form onSubmit={handleSubmit}>
                            <Row className="align-items-center justify-content-between">
                              <Col xs={12} sm={9}>
                                <CardText>
                                  {questions[questionIndex].question}{' '}
                                </CardText>
                                <Form.Group>
                                  <Options type="radio">
                                    <Form.Check.Input
                                      type="radio"
                                      name="answer"
                                      value="a"
                                      id={questions[questionIndex].optionA}
                                      onChange={handleChange}
                                    />
                                    <Form.Check.Label
                                      htmlFor={questions[questionIndex].optionA}
                                    >
                                      {questions[questionIndex].optionA}
                                    </Form.Check.Label>
                                  </Options>
                                  <Options type="radio">
                                    <Form.Check.Input
                                      type="radio"
                                      name="answer"
                                      value="b"
                                      id={questions[questionIndex].optionB}
                                      onChange={handleChange}
                                    />
                                    <Form.Check.Label
                                      htmlFor={questions[questionIndex].optionB}
                                    >
                                      {questions[questionIndex].optionB}
                                    </Form.Check.Label>
                                  </Options>
                                  <Options type="radio">
                                    <Form.Check.Input
                                      type="radio"
                                      name="answer"
                                      value="c"
                                      id={questions[questionIndex].optionC}
                                      onChange={handleChange}
                                    />
                                    <Form.Check.Label
                                      htmlFor={questions[questionIndex].optionC}
                                    >
                                      {questions[questionIndex].optionC}
                                    </Form.Check.Label>
                                  </Options>
                                  <Options type="radio">
                                    <Form.Check.Input
                                      type="radio"
                                      name="answer"
                                      value="d"
                                      id={questions[questionIndex].optionD}
                                      onChange={handleChange}
                                    />
                                    <Form.Check.Label
                                      htmlFor={questions[questionIndex].optionD}
                                    >
                                      {questions[questionIndex].optionD}
                                    </Form.Check.Label>
                                  </Options>
                                </Form.Group>
                              </Col>
                              <Col xs={12} sm={3}>
                                <h4 className="text-center">Score</h4>
                                <RightScore>
                                  <i className="fas fa-check-circle"></i>
                                  {correctScore}
                                </RightScore>
                                <WrongScore>
                                  <i className="fas fa-times-circle"></i>
                                  {wrongScore}
                                </WrongScore>
                              </Col>
                            </Row>
                            <Row className="align-items-center">
                              <Col xs={1} sm={1}>
                                <Button variant="primary" type="submit">
                                  Next
                                </Button>
                              </Col>
                              <Col>
                                <Timer>
                                  <Countdown date={date} renderer={renderer} />
                                </Timer>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </Card.Body>
                ) : (
                  <h2
                    style={{
                      textAlign: 'center',
                      minHeight: '20rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Submitting Answer
                    <i
                      style={{ marginLeft: '1.5rem' }}
                      className="fa fa-spin fa-spinner"
                      aria-hidden="true"
                    ></i>
                  </h2>
                )}
              </QuestionCard>
            </Col>
            <Col xs={12} sm={2} className="text-left">
              {/* <p>
                Quiz type: <strong>{quizType}</strong>{' '}
              </p> */}
              <p>
                Name: <strong>{firstName + ' ' + lastName}</strong>{' '}
              </p>
              <p>
                Start Time: <strong>{startTime}</strong>{' '}
              </p>
            </Col>
          </Row>
        ) : (
          <h2 style={{ textAlign: 'center' }}>
            Loading Test{' '}
            <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
          </h2>
        )
      ) : null}
    </>
  );
}
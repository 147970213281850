import React from 'react';
import TopBar from './TopBar';

export default function index() {
  return (
    <>
      <TopBar />
    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FreeTest() {

  const {t} = useTranslation()
  return (
    <div className="freeTest">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content">
              <h2 className="d-inline-block m-0 font-weight-bold">
               {t('try_test')}
              </h2>
              <Link to="/theoryTestForm" className="btn btn-lg">
              {t('get_test')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Courses from './Courses';
import ContactUs from './ContactUs';
import About from './About';
import CourseDetails from './CourseDetails';
import Register from '../RoutesRegister';
import TheoryTestForm from './TheoryTestForm';
import SpecialOffer from './SpecialOffer';
import BookCourse from './BookCourse';
import TheoryTest from './TheoryTest';
import Gallery from './Gallery';
import Document from './Document';
// import PrivateRoute from '../../../common/PrivateRoute';
import Home from './Home';
import AllPromotions from './AllPromotions';

export default function SiteRoutes() {
  return (
    <Switch>
      <Route path="/register" component={Register} />
      <Route path="/contact" component={ContactUs} />
      <Route exact path="/courses" component={Courses} />
      <Route exact path="/courses/:id" component={CourseDetails} />
      <Route path="/about" component={About} />
      <Route path="/theoryTestForm" component={TheoryTestForm} />
      {/* <PrivateRoute
        path="/theoryTest/:userName/:quizType/:testid"
        component={TheoryTest}
      /> */}
      <Route
        path="/theoryTest/:testId"
        render={props => <TheoryTest {...props} />}
      />

      <Route path="/special-offer" component={SpecialOffer} />
      <Route path="/promotions" component={AllPromotions} />
      
      <Route path="/bookCourse/:id" component={BookCourse} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/document" component={Document} />
      {/* <Route exact path="/chat" component={Chat} /> */}
      <Route exact path="/" component={Home} />
    </Switch>
  );
}

import React, { useState, useEffect} from 'react';
import { get } from 'services/RestService'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'

export default function About() {
  const {t} = useTranslation()
  const [homeData, setHomeData] = useState()
  const langRedux = useSelector(state => state.language);
  const getData = () => {
    
      get('/admin/getHome').then((data) => {
        setHomeData(data)
      }).catch((err) => {
        
        console.log(err)
      })
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="about">
      <div className="clippy-upper"></div>
      <div className="container">
        <div className="row mb-5">
          <div className="col">
            <h1 className="text-center">
              {
                      homeData?.[
                        langRedux.language === 'en'
                          ? 'titleEnglish'
                          : 'titleArabic'
                      ]
                    }
            </h1>
            <div className="divider"></div>
            <h6 className="text-center text-theme-secondary">
              {
                      homeData?.[
                        langRedux.language === 'en'
                          ? 'taglineEnglish'
                          : 'taglineArabic'
                      ]
                    }
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="description">
            {
                      homeData?.[
                        langRedux.language === 'en'
                          ? 'descriptionEnglish'
                          : 'descriptionArabic'
                      ]
                    }
            </p>
          </div>
        </div>
      </div>
      <div className="clippy-bottom"></div>
    </div>
  );
}

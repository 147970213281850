import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import banner from 'images/banner.jpg';
import { axiosClient } from 'helper';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../../../stateManagement/actions/studentActions';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import StripeCheckout from 'react-stripe-checkout';
import { BASE_API_URL_V2, BASE_URL_V2 } from 'store';
import axios from 'axios';
const FormGroup = styled(Form.Group)`
  margin-bottom: 10px;
`;

const schema = yup.object({
  // fullName: yup.string().required(),
  category: yup.string().required(),
  course: yup.string().required(),
  level: yup.string().required(),
});

export default function InitialForm() {
  window.toast = toast;
  const { t } = useTranslation();
  const [courses, setCourses] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState(null);
  const [loading, setLoading] = useState(true);
  const allCouce = useSelector(state => state.getCourses);
  const [showPaypalButton, setShowPaypalButton] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [amount, setAmount] = useState(undefined);
  const [data, setData] = useState(null);
  const [test, setTest] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { studentId } = useSelector(state => state.studentLogin);
  const langRedux = useSelector(state => state.language);

  // filtering tests/courses by category
  const handleCategoryChange = category => {
    setFilteredCourses(
      allCouce?.courses?.filter(course => course?.category?._id === category),
    );
  };

  // async function for fetching the available tests from backend.
  async function getTests() {
    setLoading(true);
    try {
      const { data, config } = await axios.get(
        `${BASE_API_URL_V2}/student/getCategories`,
      );
      setCourses(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // async function for fetching the available tests from backend.
  const getTestId = async values => {
    const query = {
      courseId: values.course,
      categoryId: values.category,
      testType: values.level === 'advance' ? 'advance' : 'basic',
    };
    try {
      const { data, config } = await axiosClient().post(
        `/student/getTestId`,
        query,
      );
      console.log('-===>', data);
      if (data) {
        if (data.test.testType === 'basic') {
          history.push({
            pathname: `/theoryTest/${data.test._id}`,
            state: {
              detail: data.test.passingPercentage,
              course: data.test.course,
            },
          });
        } else {
          setAmount({ data: data.test.testPrice });
          setTest(data.test);
          setShowPaypalButton(true);
        }
        // history.push(`/theoryTest/${data[0]._id}`);
      } else {
        window.toast.error('No test exist on this course');
      }
    } catch (error) {
      let err = '';

      if (error && error.response && error.response.data)
        err = error.response.data.msg || error.response.data;
      else err = 'please check internet connection';

      err = err || 'plaease try again later';
      window.toast.error(err.message);
      console.log(err.error);
    }
  };

  // fetching the tests on page load.
  useEffect(() => {
    getTests();
    dispatch(getCourses());
  }, []);

  // setting the visited to 0 again for letting user enter test.
  useEffect(() => {
    if (sessionStorage.getItem('visited') === '1') {
      sessionStorage.setItem('visited', '0');
    }
  }, []);
  const { userId } = useSelector(state => state.studentLogin);
  // function for saving transaction to backend.
  async function saveTransaction(details) {
    let formData = new FormData();
    formData.append('type', 'Test');
    formData.append('source', details.id);
    formData.append('test', JSON.stringify(test));

    try {
      const response = await axiosClient().post(
        `/student/createTestTransaction`,
        formData,
      );

      console.log(response);

      history.push({
        pathname: `/theoryTest/${test._id}`,
        state: { detail: test.passingPercentage, course: test.course },
      });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <ToastContainer newestOnTop={true} />

      <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
        <div className="overlay">
          <h1 className="text-white mb-2">{t('theory_test_form')}</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="font-weight-bold">
                  {t('home')}
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                {t('theory_test_form')}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Formik
        enableReinitialize
        validationSchema={schema}
        // handles submit for the initial form, resets the form and redirects to the test.
        onSubmit={(values, { resetForm }) => {
          if (values.level === 'basic') {
            setData(values);
            getTestId(values);
          } else {
            setData(values);
            getTestId(values);
          }
          //history.push(`/theoryTest/${values.course}`);
        }}
        initialValues={{
          // fullName: '',
          category: '',
          // this course value should be the test Id.
          course: '',
          level: '',
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row
                className="justify-content-center align-items-top"
                style={{ padding: '60px 0px' }}
              >
                <Col xs={12} sm={8}>
                  <FormGroup>
                    <Form.Control
                      name="category"
                      as="select"
                      value={values.category}
                      onChange={e => {
                        handleChange(e);
                        handleCategoryChange(e.target.value);
                      }}
                      isInvalid={!!errors.category}
                      custom
                    >
                      <option value="">{t('select_course_category')}</option>
                      {!loading &&
                        courses?.map(course => (
                          <option key={course._id} value={course._id}>
                            {
                              course?.[
                                langRedux.language === 'en'
                                  ? 'nameEnglish'
                                  : 'nameArabic'
                              ]
                            }
                          </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.category}
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup>
                    <Form.Control
                      name="course"
                      as="select"
                      value={values.course}
                      onChange={handleChange}
                      isInvalid={!!errors.course}
                      custom
                    >
                      <option value="">{t('select_course')}</option>
                      {!loading &&
                        filteredCourses !== null &&
                        filteredCourses.map(course => (
                          <option key={course._id} value={course._id}>
                            {
                              course?.[
                                langRedux.language === 'en'
                                  ? 'nameEnglish'
                                  : 'nameArabic'
                              ]
                            }
                          </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.course}
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup>
                    <Form.Control
                      name="level"
                      as="select"
                      value={values.level}
                      onChange={handleChange}
                      isInvalid={!!errors.level}
                      custom
                    >
                      <option value="">{t('select_level')}</option>
                      <option key="1" value="basic">
                        Basic
                      </option>
                      <option key="2" value="advance">
                        Advance
                      </option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.level}
                    </Form.Control.Feedback>
                  </FormGroup>
                  {/* <FormGroup>
                    <Form.Control
                      name="fullName"
                      type="text"
                      placeholder="Full Name"
                      value={values.fullName}
                      onChange={handleChange}
                      isInvalid={!!errors.fullName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.fullName}
                    </Form.Control.Feedback>
                  </FormGroup> */}
                  {studentId ? (
                    <>
                      {showPaypalButton ? (
                        <>
                          <div
                            style={{
                              display: `${paymentLoading ? 'none' : 'block'}`,
                              textAlign: 'center',
                              height: 'auto',
                            }}
                          >
                            <StripeCheckout
                              amount={data?.testPrice}
                              token={token => {
                                setPaymentLoading(false);
                                saveTransaction(token);
                              }}
                              stripeKey="pk_test_51JhtyqJ5Kas2RengvoM0UPYY18rQdXvKedFI9UyBfRefWLt170qqOx3KJOFwjV9buGG2jNVFNxkpK507iFSx0cFP00giO29e9q"
                            />
                          </div>
                          <h2
                            style={{
                              display: `${paymentLoading ? 'block' : 'none'}`,
                            }}
                          >
                            Loading
                          </h2>
                        </>
                      ) : (
                        <Button type="submit" style={{ width: '100%' }}>
                          {t('start')}
                        </Button>
                      )}
                    </>
                  ) : (
                    <Link
                      className="btn btn-primary"
                      to={'/login'}
                      style={{ width: '100%' }}
                    >
                      {t('start')}
                    </Link>
                  )}
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  );
}

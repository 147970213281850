import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
// import {
//   checkIfFilesAreCorrectType,
//   checkIfFilesAreTooBig,
// } from '../../../../utils/fileValidation';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
const schema = yup.object({
  tradeLicense: yup.mixed().required('Trade license is required'),
  certificate: yup.mixed().required('Certficate is required'),
  emiratesId: yup.mixed().required('Emirates Id is required'),
  companyLogo: yup.mixed().required('Company Logo is required'),
  trnCertificate: yup.mixed().required('TRN Certificate is required'),
});
const getBase64 = file =>
  new Promise(function(resolve, reject) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject('Error: ', error);
  });

export default function Documents(props) {
  const { setStep, data } = props;
  const { t } = useTranslation();
  let fileNamesArray = [
    t('trade_licence'),
    t('certificate'),
   t('emirate_id'),
   t('compony_logo'),
   t('trn_certicate'),
  ];
  let objData = {
    'TradeLicense': undefined,
    'Certificate': undefined,
    'EmiratesId': undefined,
    'CompanyLogo': undefined,
    'TRNCertificate': undefined,
  };
  const [fileNames, setFileName] = useState(fileNamesArray);
  const [fileD, setFileD] = useState(objData);

  return (
    <Col xs={12} sm={12}>
      <Formik
        validationSchema={schema}
        onSubmit={() => {
          console.log('heelo');
          let mdb = {
            ...data,
            fileD,
          };
          props.change(mdb);
          setStep(3);
        }}
        initialValues={{
          tradeLicense: undefined,
          certificate: undefined,
          emiratesId: undefined,
          companyLogo: undefined,
          trnCertificate: undefined,
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate id="reg-form" onSubmit={handleSubmit}>
            <Form.Group>
              <Form.File
                name="tradeLicense"
                label={fileNames[0]}
                value={values.tradeLicense}
                isInvalid={!!errors.tradeLicense}
                id="tradeLicense"
                feedback={errors.tradeLicense}
                onChange={e => {
                  const temp = fileNames;

                  temp[0] = e.target.files[0]?.name || fileNamesArray[0];
                  setFileName([...fileNames, temp]);

                  handleChange(e);
                  getBase64(e.target.files[0])
                    .then(result => {
                      setFileD({
                        ...fileD,
                      
                          'TradeLicense': e.target.files[0],
                        
                      });
                    })
                    .catch(e => console.log(e));
                }}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.File
                name="certificate"
                label={fileNames[1]}
                id="certificate"
                value={values.certificate}
                isInvalid={!!errors.certificate}
                feedback={errors.tradeLicense}
                onChange={e => {
                  const temp = fileNames;
                  
                  temp[1] = e.target.files[0]?.name || fileNamesArray[1];
                  setFileName([...fileNames, temp]);
                  handleChange(e);
                  getBase64(e.target.files[0])
                    .then(result => {
                      setFileD({
                        ...fileD,
                        
                          'Certificate': e.target.files[0],
                        
                      });
                    })
                    .catch(e => console.log(e));
                }}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.File
                name="emiratesId"
                label={fileNames[2]}
                id="emiratesId"
                value={values.emiratesId}
                isInvalid={!!errors.emiratesId}
                feedback={errors.tradeLicense}
                onChange={e => {
                  const temp = fileNames;
                 
                  temp[2] = e.target.files[0]?.name || fileNamesArray[2];
                  setFileName([...fileNames, temp]);
                  handleChange(e);
                  getBase64(e.target.files[0])
                  .then(result => {
                    setFileD({
                      ...fileD,
                      
                        'EmiratesId': e.target.files[0],
                      
                    });
                  })
                    .catch(e => console.log(e));
                  console.log(fileD);
                }}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.File
                name="companyLogo"
                label={fileNames[3]}
                id="companyLogo"
                value={values.companyLogo}
                isInvalid={!!errors.companyLogo}
                feedback={errors.tradeLicense}
                onChange={e => {
                  const temp = fileNames;
                
                  temp[3] = e.target.files[0]?.name || fileNamesArray[3];
                  setFileName([...fileNames, temp]);
                  handleChange(e);
                  getBase64(e.target.files[0])
                  .then(result => {
                    setFileD({
                      ...fileD,
                      
                        'CompanyLogo': e.target.files[0],
                      
                    });
                    console.log(fileD);
                  })
                  .catch(e => console.log(e));
                }}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.File
                name="trnCertificate"
                label={fileNames[4]}
                id="trnCertificate"
                value={values.trnCertificate}
                isInvalid={!!errors.trnCertificate}
                feedback={errors.tradeLicense}
                onChange={e => {
                 
                  const temp = fileNames;
                  temp[4] = e.target.files[0]?.name || fileNamesArray[4];
                  setFileName([...fileNames, temp]);
                  handleChange(e);
                  getBase64(e.target.files[0])
                  .then(result => {
                    setFileD({
                      ...fileD,
                      
                        'TRNCertificate': e.target.files[0],
                      
                    });
                  })
                    .catch(e => console.log(e));
                }}
                custom
              />
            </Form.Group>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

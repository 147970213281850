import React from 'react';
import Course from './CourseSections/Courses';
import Footer from 'components/Footer';

export default function Courses() {
  return (
    <div>
      <Course />
      <Footer />
    </div>
  );
}

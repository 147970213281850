import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { get } from 'services/RestService';
import { imageChecker } from 'store';
import banner from 'images/banner.jpg';
import { Link } from 'react-router-dom';
import Footer from 'components/Footer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function TermsConditions() {
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filter, setFilter] = useState(null);
  const { t } = useTranslation();

  const MenuItems = styled.h4`
    color: #fff;
    margin: 0px 0px 20px 20px;
    text-align: center;
    background: #fc8200;
    padding: 10px;
    background-color: #fc8200;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      color: #fff;
    }
    &.active-item {
      color: #2c2e94;
      border: 1px solid #2c2e94;
      border-radius: 5px;
      background: #2c2e94;
      color: white;
    }
  `;
  const getGalleryImages = () => {
    get('/student/getGallerys')
      .then(data => {
        setGalleryImages(data);
        console.log(data, 'f');
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };
  // function to filter the courses by status.
  function filterCourses(type) {
    if (galleryImages) {
      if (type === null) {
        var course = {};
        var arr = [];
        for (let i = 0; i < galleryImages.length; i++) {
          arr = arr.concat(galleryImages[i].imagesPath);
        }
        course.imagesPath = arr;
        console.log(arr, 'jaan');
        setFilteredCourses(course);
      } else {
        setFilteredCourses(
          galleryImages.filter(course => course.type === type)[0],
        );
      }
    }
    console.log(galleryImages, 'yeah');
  }

  // fetching gallery images.
  useEffect(() => {
    getGalleryImages();
  }, []);

  // for filtering the courses everytime the filter is changed.
  useEffect(() => {
    filterCourses(filter);
    console.log(filter);
  }, [filter, galleryImages]);

  return (
    <>
      <div
        className="banner"
        style={{ backgroundImage: `url(${banner})`, marginBottom: 20 }}
      >
        <div className="overlay">
          <h1 className="text-white mb-2">{t('gallary')}</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="font-weight-bold">
                  {t('home')}
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                {t('gallary')}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container style={{ paddingBottom: 80, paddingTop: 20 }}>
        <Col sm={12} xs={12} style={{ marginTop: 20 }}>
          <Row className="justify-content-space-around mb-5">
            <Col>
              <MenuItems
                className={filter === null ? 'active-item' : ''}
                onClick={() => {
                  setFilter(null);
                }}
              >
                {t('all')}
              </MenuItems>
            </Col>
            {galleryImages.map(data => (
              <Col-md-12>
                <MenuItems
                  className={filter === data.type ? 'active-item' : ''}
                  onClick={() => {
                    setFilter(data.type);
                  }}
                >
                  {data.type}
                </MenuItems>
              </Col-md-12>
            ))}
          </Row>
        </Col>
        <Row>
          {!loading &&
            filteredCourses &&
            filteredCourses.imagesPath.map(image => (
              <Col xs={6} sm={6} md={4}>
                <div
                  style={{
                    border: '1px solid orange',
                    alignItems: 'center',
                    marginTop: 20,

                    borderRadius: 2,
                  }}
                >
                  <Image
                    fluid
                    // className="my-2"
                    style={{
                      width: '100%',
                      height: '200px',
                      resize: 'cover',
                      borderRadius: 2,
                    }}
                    src={image}
                    // src="https://picsum.photos/800/500"
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

{
  /* <div className="container">
        <div className="row justify-content-center align-items-center text-center quick-links">
          {gallery.map(res => (
            <div key={res.key}>
              <div>
                <div
                  className={`btn btn-md btn-primary space ${
                    activeId === res.id ? 'active-link' : null
                  }`}
                  onClick={() => {
                    setProfile(res);
                    setActiveId(res.id);
                  }}
                >
                  {res.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <h3 className="text-center">{profile.title}</h3>
        <p className="text-left">{profile.desc}</p>
      </div> */
}

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import banner from 'images/banner.jpg';
import { get } from 'services/RestService';
import Carousel from 'react-elastic-carousel';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { defineValue } from 'store';

import { useTranslation } from 'react-i18next';
// Images
export default function SpecialOfferDetails() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [specialOffers, setSpecialOffers] = useState([]);
  const langRedux = useSelector(state => state.language);

  const { studentId } = useSelector(state => state.studentLogin);

  const getSpecialOffers = () => {
    setLoading(true);
    get('/student/getSpecialOffers')
      .then(data => {
        setSpecialOffers(data);
        setLoading(false);
        console.log(data);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const carouselRef = useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(specialOffers.length);
    }
  };
  const customArrow = ({ type, onClick }) => {
    const pointer = type === 'PREV' ? `left` : 'right';
    return (
      <button onClick={onClick} className="customArrow">
        <i className={`fas fa-long-arrow-alt-${pointer} fa-2x`}></i>
      </button>
    );
  };

  const showNumberOfItems = () => {
    if (window.innerWidth <= 1000) return 1;
    else if (window.innerWidth >= 1200) return 3;
    else if (window.innerWidth >= 1100) return 2;
  };
  useEffect(() => {
    getSpecialOffers();
  }, []);

  return (
    <>
      <div>
        <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
          <div className="overlay">
            <h1 className="text-white mb-2">{t('special_offers')}</h1>

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" className="font-weight-bold">
                    {t('home')}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-capitalize"
                  aria-current="page"
                >
                  {t('special_offers')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <Container>
        <Row>
          {loading ? (
            <Col xs={12} sm={6} className="text-center mb-4 mt-4">
              <h1>Loading....</h1>
            </Col>
          ) : specialOffers.length === 0 ? ( 
            <Col xs={12} sm={6} className="text-center mb-4 mt-4">
              <h1>No Special Offers</h1>
            </Col>
          ) : (
            <Carousel
              style={{ marginTop: 150, marginBottom: 20 }}
              ref={carouselRef}
              itemsToShow={showNumberOfItems()}
              pagination={false}
              renderArrow={customArrow}
              onNextStart={onNextStart}
              onPrevStart={onPrevStart}
              // enableAutoPlay={true}
              // autoPlaySpeed={3000}
            >
              {specialOffers?.reverse().map((course, i) => {
                return (
                  <div
                    key={i}
                    className="course"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      src={course?.imagePath}
                      alt={
                        course?.[
                          langRedux.language === 'en'
                            ? 'nameEnglish'
                            : 'nameArabic'
                        ]
                      }
                      title={
                        course?.[
                          langRedux.language === 'en'
                            ? 'nameEnglish'
                            : 'nameArabic'
                        ]
                      }
                      className="mb-3 img-fluid"
                      style={{ width: 280, height: 220, resize: 'contain' }}
                    />
                    <p>
                      <b>{t('course_name')}:</b>
                      {defineValue(
                        course?.[
                          langRedux.language === 'en'
                            ? 'nameEnglish'
                            : 'nameArabic'
                        ],
                      )}
                    </p>
                    <p>
                      <b>{t('price_before_discount')}:</b> $
                      {defineValue(course?.priceBefore)}
                    </p>
                    <p>
                      <b>{t('price_after_discount')}:</b> $
                      {defineValue(course?.priceAfter)}
                    </p>
                    <p>
                      <b>{t('discount')}:</b> {course?.discount}%
                    </p>
                    <p>
                      <b>{t('discount_validity_starts')}:</b>{' '}
                      {defineValue(
                        moment(course?.startDate).format('DD MMM YY'),
                      )}
                    </p>
                    <p>
                      <b>{t('discount_validity_ends')}:</b>
                      {moment(course?.endDate).format('DD MMM YY')}
                    </p>
                    {studentId ? (
                      <Link
                        className="btn btn-lg btn-outline-dark"
                        to={{
                          pathname: `/bookCourse/${course._id}?special-offer`,
                          state: {
                            special: true,
                          },
                        }}
                        style={{ width: '100%' }}
                      >
                        {t('book_now')}
                      </Link>
                    ) : (
                      <Link
                        className="btn btn-lg btn-outline-dark"
                        to={'/login'}
                        style={{ width: '100%' }}
                      >
                        {t('book_now')}
                      </Link>
                    )}
                  </div>
                );
              })}
            </Carousel>
          )}
        </Row>
      </Container>
    </>
  );
}

// const [courses, setCourses] = useState([
// {
//   image: course1,
//   title: '',
//   id: 'a',
//   priceBefore: '10',
//   priceAfter: '5',
//   discount: '50',
//   validityStartDate: '04 Nov 2020',
//   validityEndDate: '31 Nov 2021',
//   provider: 'Usha Academy',
// },
// {
//   image: course2,
//   title: '',
//   id: 's',
//   priceBefore: '10',
//   priceAfter: '5',
//   discount: '50',
//   validityStartDate: '04 Nov 2020',
//   validityEndDate: '31 Nov 2021',
//   provider: 'Usha Academy',
// },
// {
//   image: course3,
//   title: '',
//   id: 'd',
//   priceBefore: '10',
//   priceAfter: '5',
//   discount: '50',
//   validityStartDate: '04 Nov 2020',
//   validityEndDate: '31 Nov 2021',
//   provider: 'Usha Academy',
// },
// {
//   image: course4,
//   title: '',
//   id: 'f',
//   priceBefore: '10',
//   priceAfter: '5',
//   discount: '50',
//   validityStartDate: '04 Nov 2020',
//   validityEndDate: '31 Nov 2021',
//   provider: 'Usha Academy',
// },
// {
//   image: course5,
//   title: '',
//   id: 'g',
//   priceBefore: '10',
//   priceAfter: '5',
//   discount: '50',
//   validityStartDate: '04 Nov 2020',
//   validityEndDate: '31 Nov 2021',
//   provider: 'Usha Academy',
// },
// {
//   image: course6,
//   title: '',
//   id: 'h',
//   priceBefore: '10',
//   priceAfter: '5',
//   discount: '50',
//   validityStartDate: '04 Nov 2020',
//   validityEndDate: '31 Nov 2021',
//   provider: 'Usha Academy',
// },
// ]);

// useEffect(() => {
//   firebase
//     .firestore()
//     .collection('specialOffers')
//     .orderBy('timestamp', 'asc')
//     .onSnapshot(snapshot => setCourses(snapshot.docs.map(doc => doc.data())));
//   setLoading(false);
// }, []);

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { axiosClient } from 'helper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_API_URL_V2 } from '../../../../../store';
import { jsPDF } from 'jspdf';

import axios from 'axios';
const LeftCard = styled(Card)`
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6 !important;
    padding: 24px;
  }

  .body {
    padding: 24px;
  }

  border: 0px;
`;

const RightCard = styled(Card)`
  height: 100%;
  padding: 24px 80px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f6f8;
  border: 0px;
`;

const RightScore = styled.h3`
  margin-left: 10px;
  text-align: center;
  i {
    color: green;
    margin-right: 10px;
  }
`;

const WrongScore = styled.h3`
  margin-left: 10px;
  text-align: center;
  i {
    color: red;
    margin-right: 10px;
  }
`;

export default function ResultCard(props) {
  const {
    isHidden,
    correctScore,
    wrongScore,
    startTime,
    endTime,
    totalQuestions,
    userName,
    passingPer,
    testId,
    courseId,
  } = props;
  const [timeDiff, setTimeDiff] = useState('');
  const { studentId } = useSelector(state => state.studentLogin);
  const [certificate, setCertificate] = useState();
  const doc = new jsPDF();

  const notify = msg => toast(msg);

  async function submitResult(time) {
    let formData = new FormData();
    formData.append('testId', testId);
    formData.append('studentUserId', studentId);
    formData.append('courseId', courseId);
    formData.append(
      'testResult',
      (correctScore / totalQuestions) * 100 >= passingPer ? 'pass' : 'fail',
    );
    formData.append('timeTaken', time);
    formData.append('passingPercentage', passingPer);
    formData.append(
      'obtainedPercentage',
      (correctScore / totalQuestions) * 100,
    );
    formData.append('totalScore', totalQuestions);
    formData.append('obtainedScore', correctScore);

    try {
      const { data } = await axiosClient().post(
        '/student/testResult',
        formData,
      );
      setCertificate(data.message);
    } catch (error) {
      console.error(error);
    }
  }
  function makeid(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async function downloadCerticcate() {
    const dataToBeSent = {
      certificateId: certificate?.certificateId,
    };
    console.log(dataToBeSent);
    try {
      const { data } = await axiosClient().post(
        '/student/createCertificate',
        dataToBeSent,
      );
      console.log('download certificate data', data);

      // const winUrl = URL.createObjectURL(
      //   new Blob([data], { type: 'application/pdf' }),
      //   // new Blob([data]),
      // );
      // var doc = new jsPDF();
      // doc.fromHTML(data, 10, 10, { width: 180 });
      // doc.addPage();
      // doc.save('Test.pdf');
      // doc.autoSave();

      var printWindow = window.open('', '', 'height=540,width=800');
      printWindow.document.write(data);
      printWindow.document.close();
      printWindow.save('Certificate.pdf');
      printWindow.autoSave();
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (startTime && endTime) {
      var ms = moment(endTime, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(startTime, 'DD/MM/YYYY HH:mm:ss'),
      );
      var d = moment.duration(ms);
      console.log(d);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format('m:ss');
      var f = d.asMinutes();
      setTimeDiff(s);
      if (!isHidden) {
        console.log(f);
        submitResult(f);
      }
    }
  }, [startTime, endTime]);

  if (!isHidden) {
    return (
      <Row
        style={{
          marginTop: '60px',
          boxShadow: '0 1rem 3rem rgba(0, 0, 0, 0.175)',
        }}
      >
        <Col xs={6} sm={6} style={{ padding: '0px' }}>
          <LeftCard>
            <div className="header">
              <h4>Score </h4>
              <RightScore>
                <i className="fas fa-check-circle"></i>
                {correctScore}
              </RightScore>
              <WrongScore>
                <i className="fas fa-times-circle"></i>
                {wrongScore}
              </WrongScore>
            </div>
            <div className="body">
              <p>Start Time: {startTime}</p>
              <p className="mb-5">End Time: {endTime}</p>
              <p>Time Taken: {timeDiff}</p>
              <p>
                Your Percentage:{' '}
                {(correctScore / totalQuestions)?.toFixed(3) * 100}%
              </p>
              <p>Passed Percentage: {passingPer}%</p>
            </div>
          </LeftCard>
        </Col>
        <Col xs={6} sm={6} style={{ padding: '0px' }}>
          <RightCard>
            <h5>Dear {userName},</h5>
            <p>Thank You for participating in our Theory Test Quiz.</p>
            <h3 style={{ color: '#28a745' }}>Your Score is : {correctScore}</h3>
            <Button
              style={{ width: '100%' }}
              onClick={() => downloadCerticcate()}
            >
              Download Certificate
            </Button>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Re Attempt
            </Button>
          </RightCard>
        </Col>
        <ToastContainer />
      </Row>
    );
  }
  return null;
}

import React, { useEffect } from 'react';
import Routes from 'components/Routes';
import i18n from 'i18next';

import { useTranslation, initReactI18next } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

function App() {
  const rtlLanguages = ['ar'];
  function setPageDirection(language) {
    const dir = rtlLanguages.includes(language) ? 'rtl' : 'ltr';
    document.documentElement.dir = dir;
  }
  const data = useSelector(state => state.language);
  console.log(data, 'yes');
  // useEffect(() => {
  //   setPageDirection(data.language)
  // }, [data.language])
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: {
          translation: {
            home: 'Home',
            about: 'About',
            thoery: 'Theory Test',
            special_offer: 'Special Offer',
            special_offers: 'Special Offers',
            elearning: 'E-Learning',
            gallary: 'Gallery',
            document: 'Document',
            contact: 'Contact',
            login: 'Login',
            register: 'Register',
            language: 'Language',
            welcome: 'WELCOME TO CIVIl',
            welcome_2: 'THE BEST',
            welcome_3: ' SAFETY ',
            welcome_4: 'MEASURES',
            welcome_5:
              'One stop solution of Affordable courses for Learner’s Driving, Defensive Driving , Fire Fighting Training, First Aid, Occupational Health & Safety and Vocational Training programs',
            country: 'Select Country',
            region: 'Select Region',
            please_type: 'Please First Select Region',
            search: 'Type To Search',
            welcome_about: 'WELCOME TO CIVIL GROUP OF INSTITUTES UAE',
            company_profile:
              'COMPANY PROFILE | DRIVING AND VOCATIONAL TRAINING INSTITUTE IN UAE SINCE 2007',
            company_profile_h1:
              'Civil Academy of Driving & Civil Vocational Training Institute celebrates over decades of successfully learner & advanced driver training and driver road risk reduction in the UAE and the greater GCC region. It has been in operation since 2007 and is the one of successful driving institute in UAE and the Middle East. It is a part of the renowned Civil Institutes Group. Civil Institute provides driving license and defensive driving training courses using its modern fleet of vehicles in different vehicle categories like Car, Heavy Bus, Light Bus, Heavy Truck, Shovel, Forklift and Motorcycles. over 250,000 students/candidates have benefitted from Civil Institutes since its inception. Civil Institute has qualified male and female Instructors of different nationalities who can instruct the students in their native language making it easier for them to take their license training or advanced driving training qualifications. The driver training standards are set at International levels and the driving tuition methods are continuously updated to make use of the latest driving techniques.',
            company_profile_h2:
              'CIVIL ACADEMY / INSTITUTE and its professionals are approved training provider by RTA for Advanced & occupational drivers training program, Dubai Civil Defence, Ministry Of Interior, Civil Institute Achieved accreditations & memberships from various international organizations such as International Association for Driver Education IVV-UK, Advanced Motorist-UK, National Safety Council-USA, Medic First Aid-USA, Highfield – UK, National Fire Fighting Association NFPA-USA). To know more about our services or for any information contact us at :',
            our_features: 'Our Features',
            enjoy_features:
              'Enjoy with us wide range of fleet and flexible various courses Learning Environment',
            quick_license: 'Quick License',
            quick_license_c: 'Join us for quick driving license.',
            unlimited_car_support: 'Unlimited Car Support',
            unlimited_car_support_c: 'Wide range of modern fleet.',
            video_classes: 'Video Classes',
            video_classes_c: 'Video Training classes.',
            experience_instructors: 'Experience Instructors',
            experience_instructors_c:
              'Highly experienced male & female trainers in multi lingual.',
            any_time_any_place: 'Any Time Any Place',
            any_time_any_place_c:
              'We offer pick and drop facility by same training vehicles.',
            learning_roads: 'Learning Roads',
            learning_roads_c:
              'Join our Defensive Driving & UAE Roads familiarization training course.',
            graduated_here: 'GRADUTED FROM HERE',
            teacher_number: 'TEACHERS NUMBER',
            years_on_market: 'YEARS ON MARKET',
            present_students: 'PRESENT STUDENTS',

            course_categories: 'Course Categories',
            course_categories_c:
              'Join us to avail wide range of international standards and customized training programs',
            our_clients: 'Our Clients',
            try_test: 'TRY TO GET OUR AMAZING FREE TEST',
            get_test: 'Get Test',
            get_in_Touch: 'Get In Touch',
            get_in_Touch_shortly:
              'We will be happy to help you. Fill out the form and we will get back to you shortly.',
            full_name_imp: 'Full Name *',
            email_imp: 'Email *',
            subject_imp: 'Subject *',
            Your_Question: 'Your Question / Query / Message *',
            send_message: 'Send Message',
            message_send: 'Your message has been sent. Thank you!',
            err_name: 'Please enter your Full Name',
            err_email: 'Please enter your valid email address.',
            err_subject: 'Please type a valid Subject',
            err_question: 'Please enter your Question.',
            quick_contact: 'QUICK CONTACT',
            dubai: 'DUBAI',
            sharjah: 'SHARJAH',
            ajman: 'AJMAN',
            umm_al_quawin: 'UMM AL QUWAIN',
            add_1: 'Umm Ramool-Behind Dubai Duty Free Wherhouse',
            add_2: 'Rolla, Mubark Center, Ist Floor',
            add_3: 'ALNakhil-Karama Area',
            add_4: 'Near LULU Center',
            email: 'Email',
            phone: 'Phone',
            theory_test_form: 'Theory Test',
            select_course_category: 'SELECT COURSE CATEGORY',
            select_course: 'SELECT COURSE',
            select_level: 'SELECT LEVEL',
            start: 'Start',
            course_name: 'Course Name',
            price_before_discount: 'Price Before Discount',
            price_after_discount: 'Price After Discount',
            discount: 'Discount',
            discount_validity_starts: 'Discount Validity Starts',
            discount_validity_ends: 'Discount Validity Ends',
            promo_code: 'Promo Code',
            book_now: 'Book Now',
            all: 'All',
            cars: 'Cars',
            classroom: 'Classroom',
            exams: 'Exams',
            students: 'Students',

            our_doc: 'Our Documents',
            download: 'Download',
            our_address: 'Our Address',
            our_location: 'Our Location',
            contact_details: 'Contact Details',
            view : 'View',
            view_courses: 'View Courses',
            courses: 'Courses',
            categorise: 'CAtegories',
            all_courses: 'All Courses',
            offerd_by: 'Offered By',
            instructed_by: 'Instructed By',
            view_detail: 'View Detail',
            password: 'Password',
            dont_account: "Don't have an account?",
            forget_password: 'Forgot passwrod?',
            reg_trainer: 'Register as Trainer',
            log_trainer: 'Login as Trainer',
            reg_student: 'Register as Student',
            log_student: 'Login as Student',
            click_here: 'Click Here',
            student_registration: 'Student Registration',
            profile_pic: 'Profile Picture',
            first_name: 'First Name',
            last_name: 'Last Name',
            confirm_pass: 'Confirm Password',
            gender: 'Gender',
            date_of_birth: 'Date of Birth',
            nationality: 'Nationality',
            select_nationality: 'SELECT NATIONALITY',
            test_date: 'Test Date',
            test_type: 'Test type',
            course_learning: 'Course Learning',
            residence_country: 'Residence Country',
            create_acc: 'Create Account',
            verify_it: 'Verify It',
            otp: 'OTP',
            select_residence: 'Select Residence',
            trainer_registration: 'Trainer Registration',
            save_continue: 'Save & Continue',
            personal_detail: 'Personal Details',
            personal: 'Personal',
            company: 'Company',
            documents: 'Documents',
            Account: 'Account',
            update: 'Update',
            back: 'Back',
            submit: 'Submit',
            company_name: 'Company Name',
            year_of_exp: 'Years of Experience',
            trade_licence: 'Trade License',
            certificate: 'Certificate',
            emirate_id: 'Emirates Id',
            compony_logo: 'Company Logo',
            trn_certicate: 'TRN Certificate',
            hello: 'Hello',
            dashboard: 'Dashboard',
            profile_update: 'Profile Update',
            transaction_history: 'Transaction History',
            chats: 'Chats',
            test_history: 'Test History',
            ratings: 'Ratings',
            my_bookings: 'My Bookings',
            terms_condition: 'Term and Conditions',
            logout: 'Logout',
            course_details: 'Course Details',
            doc_req: 'Document Required',
            course_info: 'Course Information',
            fees: 'Fees',
            reg_pro: 'Registration Procedure',
            lecture_details: 'Lecture Details',
            test_detail: 'Test Detail',
            instructor_name: 'Instructor Name',
            training_fees: 'Training Fees',
            course_fee_detail: 'Course Fee Detail',
            registration_Steps: 'Registration Steps',
            welcome_to_your_dashboard: 'Welcome To Civil Academy',
            course: 'Course',
            offer: 'Offer',
            test: 'Test',
            about_trainer: 'About Trainer',
            chat: 'Chat',
            from: 'From',
            member_since: 'Member Since',
            update_account: 'Update Account',
            amount: 'Amount',
            paid_on: 'Paid on',
            pay_name: "Payer's Name",
            pay_email: "Payer's Email",
            about_booking: 'About Booking',
            transaction_ID: 'Transaction ID',
            transaction_amt: 'Transaction Amt.',
            institute: 'Institute',
            trans_date: 'Transaction Date',
            level: 'Level',
            status: 'Status',
            about_test: 'About Test',
            passing_percentage: 'Passing Percentage',
            obtained_percentage: 'Obtained Percentage',
            total_questions: 'Total Questions',
            correct_questions: 'Correct Questions',
            time_taken: 'Time Taken',
            test_id: 'Test ID',
            no_test_yet: 'No Tests Yet',
            no_trans_made: 'No Transaction Made',
            no_review_yet: 'No Reviews Yet',
            preview_hist_rating: 'Preview the history of ratings',
            add_review: 'Add Your Review',
            feedback: 'Feedback',
            reviewed_on: 'Reviewed On',
            your_rating: 'Your Rating',
            your_review: 'Your Review',
            booked: 'Booked',
            completed: 'Completed',
            cancelled: 'Cancelled',
            cancel: 'Cancel',
            booked_on: 'Booked On',
            reschedule: 'Reschedule',
            joining_date: 'Joining Date',
            time: 'Time',
            rate_trainer: 'Rate Your Trainer',
            how_was: 'How was',
            book_form: 'Book Form',
            our_headq: 'Our Headquarter',
            at_what_time: 'At What Time',
            payment_method: 'Payment Method',
            upload_images: 'Upload Images',
            upload_video: 'Upload Video',
            upload_voice: 'Upload Voice',
            or: 'Or',
            text_note: 'Text Note',
            book_course: 'Book Course',
            proceed_to_pay: 'Proceed to Payment',
            remarks: 'Remarks'
          },
        },
        ar: {
          translation: {
            home: 'الأساسية',
            about: 'حول',
            thoery: 'الاختبار النظري',
            special_offer: 'عرض خاص',
            special_offers: 'عروض خاصة',
            elearning: 'التعلم الإلكتروني',
            gallary: 'الصور',
            document: 'وثيقة',
            contact: 'اتصل',
            login: 'تسجيل الدخول',
            register: 'تسجيل',
            language: 'لغة',
            welcome: 'مرحبا بكم في مدني',
            welcome_2: 'الأفضل',
            welcome_3: ' سلامة ',
            welcome_4: 'الإجراءات',
            welcome_5:
              'حل شامل للدورات التدريبية بأسعار معقولة لقيادة المتعلم ، والقيادة الدفاعية ، والتدريب على مكافحة الحرائق ، والإسعافات الأولية ، والصحة والسلامة المهنية ، وبرامج التدريب المهني',
            country: 'حدد الدولة',
            region: 'اختر المنطقة',
            please_type: 'الرجاء تحديد المنطقة أولاً',
            search: 'اكتب للبحث',
            welcome_about:
              'مرحبًا بكم في مجموعة المعاهد المدنية بدولة الإمارات العربية المتحدة',
            company_profile:
              'لمحة عن الشركة | معهد القيادة والتدريب المهني في الإمارات العربية المتحدة منذ عام 2007',
            company_profile_h1:
              'الأكاديمية المدنية للقيادة ومعهد التدريب المهني المدني يحتفل بأكثر من عقود من المتعلم الناجح والتدريب المتقدم للسائقين والحد من مخاطر الطريق للسائقين في دولة الإمارات العربية المتحدة ومنطقة دول مجلس التعاون الخليجي الكبرى. يعمل منذ عام 2007 وهو أحد معاهد القيادة الناجحة في الإمارات العربية المتحدة والشرق الأوسط. إنها جزء من مجموعة المعاهد المدنية الشهيرة. يوفر المعهد المدني رخصة قيادة ودورات تدريبية على القيادة الدفاعية باستخدام أسطوله الحديث من المركبات في فئات مختلفة من المركبات مثل السيارات والحافلات الثقيلة والحافلات الخفيفة والشاحنات الثقيلة والمجرفة والرافعة الشوكية والدراجات النارية. استفاد أكثر من 250.000 طالب / مرشح من المعاهد المدنية منذ إنشائها. المعهد المدني لديه مدربين مؤهلين من الذكور والإناث من جنسيات مختلفة يمكنهم توجيه الطلاب بلغتهم الأم مما يسهل عليهم الحصول على رخصة التدريب أو مؤهلات التدريب المتقدمة على القيادة. يتم تحديد معايير تدريب السائقين على المستويات الدولية ويتم تحديث طرق تعليم القيادة باستمرار للاستفادة من أحدث تقنيات القيادة.',
            company_profile_h2:
              'الأكاديمية / المعهد المدني ومهنيوه معتمدون من قبل هيئة الطرق والمواصلات لبرنامج تدريب السائقين المتقدمين والمهنيين ، الدفاع المدني بدبي ، وزارة الداخلية ، المعهد المدني حصلوا على اعتمادات وعضوية من منظمات دولية مختلفة مثل الرابطة الدولية لتعليم السائقين IVV-UK ، Advanced Motorist-UK، National Safety Council-USA، Medic Aid-USA، Highfield - UK، National Fire Fighting Association NFPA-USA). لمعرفة المزيد عن خدماتنا أو للحصول على أي معلومات اتصل بنا على',
            our_features: 'ميزاتنا',
            enjoy_features:
              'استمتع معنا بمجموعة واسعة من الأسطول ودورات تعليمية متنوعة مرنة',
            quick_license: 'ترخيص سريع',
            quick_license_c: 'انضم إلينا للحصول على رخصة قيادة سريعة',
            unlimited_car_support: 'دعم غير محدود للسيارات',
            unlimited_car_support_c: 'مجموعة واسعة من الأسطول الحديث',
            video_classes: 'دروس الفيديو',
            video_classes_c: 'دروس تدريب بالفيديو',

            experience_instructors: 'مدربين خبرة',
            experience_instructors_c:
              'مدربين ذكور وإناث ذوي خبرة عالية في لغات متعددة',
            any_time_any_place: 'أي زمان أي مكان',
            any_time_any_place_c:
              'نحن نقدم خدمة الاختيار والإفلات بنفس مركبات التدريب',
            learning_roads: 'طرق التعلم',
            learning_roads_c:
              'انضم إلى الدورة التدريبية للتعريف بالقيادة الدفاعية وطرق الإمارات العربية المتحدة',

            graduated_here: 'متدرج من هنا',
            teacher_number: 'عدد المعلمين',
            years_on_market: 'سنوات في السوق',
            present_students: 'الطالب الحالي',
            course_categories: 'فئات الدورة',
            course_categories_c:
              'انضم إلينا للاستفادة من مجموعة واسعة من المعايير الدولية وبرامج التدريب المخصصة',
            our_clients: 'عملائنا',
            try_test: 'حاول الحصول على اختبارنا المجاني المذهل',
            get_test: 'احصل على الاختبار',
            get_in_Touch: 'ابقى على تواصل',
            get_in_Touch_shortly:
              'ونحن سوف نكون سعداء لمساعدتك. املأ النموذج وسنعاود الاتصال بك قريبًا.',
            full_name_imp: 'الاسم بالكامل *',
            email_imp: 'بريد الالكتروني *',
            subject_imp: 'موضوعات *',
            Your_Question: 'سؤالك / استفسارك / رسالتك *',
            send_message: 'أرسل رسالة',
            message_send: 'تم ارسال رسالتك. شكرا لك!',
            err_name: 'من فضلك ادخل اسمك الكامل',
            err_email: 'يرجى إدخال عنوان البريد الإلكتروني الخاص بك صالح',
            err_subject: 'الرجاء كتابة موضوع صالح',
            err_question: 'الرجاء إدخال سؤالك',
            quick_contact: 'اتصال سريع',
            dubai: 'دبي',
            sharjah: 'الشارقة',
            ajman: 'عجمان',
            umm_al_quawin: 'م القيوين',
            add_1: 'أم رمول - خلف السوق الحرة بدبي ويرهاوس',
            add_2: 'الرولة ، مركز مبارك ، الطابق الأول',
            add_3: 'منطقة النخيل - الكرامة',
            add_4: 'بالقرب من مركز لولو',
            email: 'بريد الالكتروني',
            phone: 'هاتف',
            theory_test_form: 'نموذج الاختبار النظري',
            select_course_category: 'اختر فئة الدورة',
            select_course: 'اختر الدورة',
            select_level: 'اختار مستوى',
            start: 'يبدأ',
            course_name: 'اسم الدورة التدريبية',
            price_before_discount: 'السعر قبل الخصم',
            price_after_discount: 'السعر بعد الخصم',
            discount: 'خصم',
            discount_validity_starts: 'تبدأ صلاحية الخصم',
            discount_validity_ends: 'انتهاء صلاحية الخصم',
            promo_code: 'رمز ترويجي',
            book_now: 'احجز الآن',
            all: 'الجميع',
            cars: 'سيارات',
            classroom: 'قاعة الدراسة',
            exams: 'الامتحانات',
            students: 'تلاميذ',
            our_doc: 'وثائقنا',
            download: 'تحميل',
            our_address: 'عنواننا',
            our_location: 'موقعنا',
            contact_details: 'بيانات المتصل',
            view : 'عرض ',
            view_courses: 'عرض الدورات',
            courses: 'الدورات',
            categorise: 'فئات',
            all_courses: 'جميع الدورات',
            remarks: 'ملاحظات',
            offerd_by: 'مقدمة من',
            instructed_by: 'تعليمات',
            view_detail: 'عرض التفاصيل',
            password: 'كلمه السر',
            dont_account: 'ليس لديك حساب؟',
            forget_password: 'نسيت كلمة المرور؟',
            reg_trainer: 'سجل كمدرب',
            log_trainer: 'تسجيل الدخول كمدرب',
            reg_student: 'سجل كطالب',
            log_student: 'تسجيل الدخول كطالب',
            click_here: 'انقر هنا',
            student_registration: 'تسجيل الطالب',
            profile_pic: 'الصوره الشخصيه',
            first_name: 'الاسم الأول',
            last_name: 'الكنية',
            confirm_pass: 'تأكيد كلمة المرور',
            gender: 'جنس تذكير أو تأنيث',
            date_of_birth: 'تاريخ الولادة',
            nationality: 'جنسية',
            select_nationality: 'حدد الجنسية',
            test_date: 'تاريخ الاختبار',
            test_type: 'نوع الاختبار',
            course_learning: 'التعلم بالطبع',
            residence_country: 'بلد الإقامة',
            select_residence: 'حدد الإقامة',
            create_acc: 'إنشاء حساب',
            verify_it: 'تحقق من ذلك',
            otp: 'OTP',
            trainer_registration: 'تسجيل المدرب',
            save_continue: 'حفظ ومتابعة',
            personal_detail: 'تفاصيل شخصية',
            personal: 'شخصي',
            company: 'شركة',
            documents: 'وثائق',
            Account: 'حساب',
            update: 'تحديث',

            back: 'خلف',
            submit: 'يقدم',
            company_name: 'اسم الشركة',
            year_of_exp: 'سنوات من الخبرة',
            trade_licence: 'الرخصة التجارية',
            certificate: 'شهادة',
            emirate_id: 'هويه الإمارات',
            compony_logo: 'شعار الشركة',
            trn_certicate: 'شهادة TRN',
            hello: 'أهلا',
            dashboard: 'لوحة القيادة',
            profile_update: 'تحديث الملف الشخصي',
            transaction_history: 'تاريخ المعاملات',
            chats: 'الدردشات',
            test_history: 'تاريخ الاختبار',
            ratings: 'التقييمات',
            my_bookings: 'حجوزاتي',
            terms_condition: 'شروط وأحكام',
            logout: 'تسجيل خروج',
            course_details: 'تفاصيل الدورة',
            doc_req: 'المستند مطلوب',
            course_info: 'دورة معلومات',
            fees: 'مصاريف',
            reg_pro: 'إجراءات التسجيل',
            lecture_details: 'تفاصيل المحاضرة',
            test_detail: 'تفاصيل الاختبار',
            instructor_name: 'اسم المدرب',
            training_fees: 'رسوم التدريب',
            course_fee_detail: 'تفاصيل رسوم الدورة',
            registration_Steps: 'خطوات التسجيل',
            welcome_to_your_dashboard: 'مرحبًا بك في لوحة التحكم',
            course: 'مسار',
            offer: 'يعرض',
            test: 'اختبار',
            about_trainer: 'عن المدرب',
            chat: 'دردشة',
            from: 'من عند',
            member_since: 'عضو منذ',
            update_account: 'تحديث الحساب',
            amount: 'كمية',
            paid_on: 'المدفوعة على',
            pay_name: 'اسم الدافع',
            pay_email: 'البريد الإلكتروني للدفع',
            about_booking: 'حول الحجز',
            transaction_ID: 'رقم المعاملة',
            transaction_amt: 'قيمة التحويل',
            institute: 'معهد',
            trans_date: 'تاريخ الصفقة',
            level: 'مستوى',
            status: 'حالة',
            about_test: 'حول الاختبار',
            passing_percentage: 'نسبة النجاح',
            obtained_percentage: 'النسبة المئوية التي تم الحصول عليها',
            total_questions: 'مجموع الأسئلة',
            correct_questions: 'الأسئلة الصحيحة',
            time_taken: 'الوقت المستغرق',
            test_id: 'معرف الاختبار',
            no_test_yet: 'لا توجد اختبارات حتى الآن',
            no_trans_made: 'لم تتم أي معاملة',
            no_review_yet: 'لا توجد تعليقات حتى الآن',
            preview_hist_rating: 'معاينة تاريخ التصنيفات',
            add_review: 'إضافة تقييمك',
            feedback: 'استجابة',
            reviewed_on: 'تمت المراجعة في',
            your_rating: 'تقييمك',
            your_review: 'مراجعتك',
            booked: 'حجز',
            completed: 'مكتمل',
            cancelled: 'ألغيت',
            cancel: 'يلغي',
            reschedule: 'إعادة الجدولة',
            booked_on: 'تم الحجز',
            joining_date: 'تاريخ الانضمام',
            time: 'زمن',
            rate_trainer: 'قيم مدربك',
            how_was: 'كيف كان',
            book_form: 'شكل كتاب',
            our_headq: 'مقرنا',
            at_what_time: 'في أي وقت',
            payment_method: 'طريقة الدفع او السداد',
            upload_images: 'تحميل الصور',
            upload_video: 'رفع فيديو',
            upload_voice: 'تحميل الصوت',
            or: 'أو',
            text_note: 'ملاحظة نصية',
            book_course: 'دورة كتاب',
            proceed_to_pay: 'الشروع في دفع',
          },
        },
      },
      lng: data ? data.language : 'en', // if you're using a language detector, do not define the lng option
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });

  return <Routes />;
}

export default App;

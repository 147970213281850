export const STUDENT_LOGIN_REQUEST = 'STUDENT_LOGIN_REQUEST';
export const STUDENT_LOGIN_SUCCESS = 'STUDENT_LOGIN_SUCCESS';
export const STUDENT_LOGIN_FAIL = 'STUDENT_LOGIN_FAIL';

export const STUDENT_LOGOUT = 'STUDENT_LOGOUT';

export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAIL = 'GET_COURSES_FAIL';
export const GET_COURSES_RESET = 'GET_COURSES_RESET';

export const GET_COURSE_DETAILS_REQUEST = 'GET_COURSE_DETAILS_REQUEST';
export const GET_COURSE_DETAILS_SUCCESS = 'GET_COURSE_DETAILS_SUCCESS';
export const GET_COURSE_DETAILS_FAIL = 'GET_COURSE_DETAILS_FAIL';
export const GET_COURSE_DETAILS_RESET = 'GET_COURSE_DETAILS_RESET';

export const GET_BOOKED_COURSES_REQUEST = 'GET_BOOKED_COURSES_REQUEST';
export const GET_BOOKED_COURSES_SUCCESS = 'GET_BOOKED_COURSES_SUCCESS';
export const GET_BOOKED_COURSES_FAIL = 'GET_BOOKED_COURSES_FAIL';

export const GET_STUDENT_DETAILS_REQUEST = 'GET_STUDENT_DETAILS_REQUEST';
export const GET_STUDENT_DETAILS_SUCCESS = 'GET_STUDENT_DETAILS_SUCCESS';
export const GET_STUDENT_DETAILS_FAIL = 'GET_STUDENT_DETAILS_FAIL';

export const STUDENT_DETAILS_RESET = 'STUDENT_DETAILS_RESET';
export const CHANGE_LANG = 'CHANGE_LANG';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import Cookie from 'cookie-universal';
import {
  studentLoginReducer,
  getCoursesReducer,
  studentDetailsReducer,
  getCourseDetailsReducer,
  getBookedCoursesReducer,
  getLang,
} from './stateManagement/reducers/studentReducer';
import moment from 'moment';
const cookies = Cookie();

//export const BASE_API_URL = 'http://api.localhost:8000/api';
//export const BASE_API_URL_V2 = 'http://api.localhost:8000/api';
//export const BASE_URL_V2 = 'http://api.localhost:8000/api';

export const BASE_API_URL = 'https://api.civil.ae/api';
export const BASE_API_URL_V2 = 'https://api.civil.ae/api';
export const BASE_URL_V2 = 'https://api.civil.ae/';

export const defineValue = value => {
  if (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    value !== 0 &&
    value !== 'null' &&
    value !== '0'
  ) {
    return value;
  } else return ' - ';
};

export const defineDate = value => {
  if (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    value !== 0 &&
    value !== 'null' &&
    value !== '0'
  ) {
    return moment(value).format('YYYY-MM-DD');
  } else return ' - ';
};

export const imageChecker = image => {
  if (image?.includes('file:/')) {
    return { uri: image };
  } else if (image === undefined || image === '' || image === null) {
    return 'https://placeimg.com/200/200/people';
  } else if (
    !image?.includes('file:/') &&
    image !== undefined &&
    !image?.includes('https://platform')
  ) {
    let img = image.split(' ').join('%20');
    if (img.endsWith('df')) {
      return 'https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w';
    } else {
      return BASE_URL_V2 + img;
    }
  } else if (image?.includes('https://platform') && image !== undefined) {
    return { uri: image };
  } else return 'https://placeimg.com/200/200/people';
};

const accessToken = cookies.get('accessToken');

const initialState = {
  // student login and authentication details
  studentLogin: {
    accessToken: accessToken ? accessToken : null,
    loading: false,
    error: null,
    studentId: null,
    userId: null,
  },
  // student details
  studentDetails: {
    loading: true,
    error: null,
    firstName: null,
    lastName: null,
    email: null,
    dob: null,
    nationality: null,
    residenceCountry: null,
    phone: null,
    grade: null,
    learningMethod: null,
    image: null,
    userId: null,
  },
  // for all courses
  getCourses: {
    loading: true,
    error: null,
    courses: [],
  },
  // for single course
  getCourseDetails: {
    loading: true,
    error: null,
    courseDetails: null,
  },
  getBookedCourses: {
    loading: true,
    error: null,
    bookedCourses: [],
  },
  language: { language: 'en' },
};
// message: '',
// userId: '',
// username: '',
// email: '',
// role: '',
// sidebarShow: 'responsive',

const middleware = [thunk];

const reducer = combineReducers({
  studentLogin: studentLoginReducer,
  studentDetails: studentDetailsReducer,
  getCourses: getCoursesReducer,
  getCourseDetails: getCourseDetailsReducer,
  getBookedCourses: getBookedCoursesReducer,
  language: getLang,
});

// REDUX PERSIST SETUP

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export let store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export let persistor = persistStore(store);
// export const resourcesLanguage = {
//   en: {
//     translation: require('./lang/en.json'),
//   },
//   ar: {
//     translation: require('./lang/ar.json'),
//   }
// }

import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { get } from 'services/RestService';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import googleplayimg from './../../images/googleplay.png';
import appstoreimg from './../../images/apple.png';
import { useHistory } from 'react-router-dom';

export default function FooterMenu() {
  const [footerData, setFooterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const mountedRef = useRef(true);
  const langRedux = useSelector(state => state.language);
  const { t } = useTranslation();
  const history = useHistory();
  // function for getting footer data.
  async function getFooterData() {
    setLoading(true);
    get('/student/getFooter')
      .then(data => {
        console.log(data);

        if (!mountedRef.current) return null;
        setFooterData(data);
        setLoading(false);
        return data;
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }

  // fetching footer data from api.

  useEffect(() => {
    getFooterData();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const date = new Date();
  const year = date.getFullYear();
  window.toast = toast;
  return (
    !loading && (
      <>
        <footer className="footerMenu">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3 RA-13-col-style">
                  <h5>
                    {
                      footerData?.[
                        langRedux.language === 'en'
                          ? 'headingEnglish'
                          : 'headingArabic'
                      ]
                    }
                  </h5>
                  <hr />
                  <p className="mb-5 text-justify RA-13-about-fnt-mrgn">
                    {
                      footerData?.[
                        langRedux.language === 'en'
                          ? 'contentEnglish'
                          : 'contentArabic'
                      ]
                    }
                  </p>

                  <hr />
                  <div className="socialIcons">
                    <div className="iconBox">
                      <a
                        href={footerData?.facebookUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div className="iconBox">
                      <a
                        href={footerData?.twitterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                    <div className="iconBox">
                      <a
                        href={footerData?.linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3 RA-13-col-style-rest">
                  <h5>{t('Quick Links')}</h5>
                  <hr />
                  <p style={{ cursor: 'pointer'}} onClick={()=> history.push('/')} className="mt-3 mb-1 RA-13-mrgn-lft">Home</p>
                  <p style={{ cursor: 'pointer'}} onClick={()=> history.push('/about')} className="mt-3 mb-1 RA-13-mrgn-lft">About Us</p>
                  <p style={{ cursor: 'pointer'}} onClick={()=> history.push('/theoryTestForm')} className="mt-3 mb-1 RA-13-mrgn-lft">Theory Test</p>
                  <p style={{ cursor: 'pointer'}} onClick={()=> history.push('/special-offer')} className="mt-3 mb-1 RA-13-mrgn-lft">Special Offer</p>
                  <p style={{ cursor: 'pointer'}} onClick={()=> history.push('/contact')} className="mt-3 mb-1 RA-13-mrgn-lft">Contact Us</p>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3 RA-13-col-style-rest">
                  <h5>{t('Download App')}</h5>
                  <hr />
                  <img
                    className="RA-13-footer-img"
                    src={googleplayimg}
                    alt="Civil Academy"
                  />
                  <img
                    className="RA-13-footer-img"
                    src={appstoreimg}
                    alt="Civil Academy"
                  />
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3 RA-13-col-style-rest">
                  <h5>{t('quick_contact')}</h5>
                  <hr />
                  <p className="mt-3 mb-1">
                    <i className="fas fa-phone-alt RA-13-icon-mrgn"></i>{' '}
                    {footerData?.phone}
                  </p>

                  {footerData?.phone2 && <p className="mt-3 mb-1">
                    <i className="fas fa-phone-alt RA-13-icon-mrgn"></i>{' '}
                    {footerData?.phone2}
                  </p>}

                  <div className="mt-3 mb-1">
                    <i className="fas fa-envelope RA-13-icon-mrgn"></i>{' '}
                    {footerData?.email}
                  </div>
                </div>
              </div>

              <div className="container">
                <hr className="hr" />
              </div>

              <div className="row footerText">
                <div className="col">
                  <p className="m-0 RA-13-txt-reserved text-center">
                    &copy; {year} {window.appName} All Rights Reserved -
                    Designed &amp; Developed by{' '}
                    <a
                      href="http://gmitstech.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="RA-13-txt-comp"
                    >
                      GMITS
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <ToastContainer newestOnTop={true} />
      </>
    )
  );
}

import firebase from 'firebase/app';
require('firebase/firestore');
require('firebase/auth');
require('firebase/functions');
// require("firebase/messaging");
require('firebase/storage');

// Firebase Config
const firebaseConfig = {
  apiKey: 'AIzaSyBae_v2L_WHBHG1dq4AyZUR6HjRmmEwJsM',
  authDomain: 'civil-academy-286d0.firebaseapp.com',
  databaseURL: 'https://civil-academy-286d0-default-rtdb.firebaseio.com',
  projectId: 'civil-academy-286d0',
  storageBucket: 'civil-academy-286d0.appspot.com',
  messagingSenderId: '933785959543',
  appId: '1:933785959543:web:1d31776ad6b2363b119a72',
  measurementId: 'G-3W143GXQMV',
};

/* Init Firebase */
firebase.initializeApp(firebaseConfig);

/* Firebase Auth */
const firestore = firebase.firestore();
// const storage = firebase.storage()
/* Firebase Auth */
const auth = firebase.auth();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();

/* Firebase Messaging */
// const messaging = firebase.messaging();
// messaging.onMessage(function (payload) {
//     console.log('Message received. ', payload);
//     window.notify(payload.notification.title ,'success')
// });

/* Firebase Functions */
const functions = firebase.functions();
if (process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR)
  functions.useFunctionsEmulator(
    process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR,
  );

/* Firebase Storage */
// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = firebase.storage();
const storageRef = storage.ref(); // Create a storage reference from our storage service
const imagesRef = storageRef.child('images'); // Create a child reference; imagesRef now points to 'images'
const PADRef = storageRef.child('PAD'); // Create a child reference; imagesRef now points to 'images'

// Child references can also take paths delimited by '/'
// var spaceRef = storageRef.child('images/space.jpg');
// spaceRef now points to "images/space.jpg"
// imagesRef still points to "images"

// Uncomment the desired reference from all aspects for usage
// export {storageRef, imagesRef, firestore, auth, functions, facebookProvider, googleProvider }
export {
  firebase,
  firestore,
  functions,
  storageRef,
  imagesRef,
  PADRef,
  auth,
  facebookProvider,
  googleProvider,
  storage,
};

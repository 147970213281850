import React from 'react';
import {
  ProfileUpdate,
  TransactionHistory,
  Notification,
  RatingsHistory,
  CourseHistory,
  TestHistory,
  TestDetails,
  MyBookings,
  Dashboard,
  TermsConditions,
  TransactionDetails,
  ChatHistory,
  Chat,
  PrivacyPolicy
} from './studentDashboardSections';
import { Switch, Route } from 'react-router-dom';
import ScheduleTest from './ScheduleTest';
import Remarks from './studentDashboardSections/Remarks';

export default function StudentDashboardRoutes() {
  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/remarks" component={Remarks} />
      <Route path="/update-profile" component={ProfileUpdate} />
      <Route path="/transaction-history" component={TransactionHistory} />
      <Route path="/notifications" component={Notification} />
      <Route path="/ratings" component={RatingsHistory} />
      <Route path="/schedule-test" component={ScheduleTest} />
      <Route path="/course-history" component={CourseHistory} />
      <Route path="/test-history" component={TestHistory} />
      <Route path="/test-details/:id" component={TestDetails} />
      <Route path="/transaction-details/:id" component={TransactionDetails} />
      <Route path="/my-bookings" component={MyBookings} uid="heloo" />
      <Route path="/terms-conditions" component={TermsConditions} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/chat" component={Chat} />

      {/* <Route path="/chat-history" render={() => <ChatHistory user1={data} />} /> */}
    </Switch>
  );
}

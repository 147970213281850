import React from 'react';
import TheoryTestComponent from './theoryTestSections/TheoryTest';
import Footer from 'components/Footer';

export default function TheoryTest(props) {
  return (
    <div>
      <TheoryTestComponent {...props} />
      <Footer />
    </div>
  );
}

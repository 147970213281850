import React, { useState } from 'react';
import nations from '../../../../common/nationalities.json';
import { Form, Col, InputGroup ,Row,Button} from 'react-bootstrap';
import countryDialCodes from '../../../../common/countryDialCodes.json';
import { Formik } from 'formik';
import * as yup from 'yup';
import placeholderPic from 'images/person.png';
import ImageCropper from 'common/ImageCropper';
import { useTranslation } from 'react-i18next';
const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  dateOfBirth: yup.string().required(),
  nationality: yup.string().required(),
  residence: yup.string().required(),
  phone: yup.string().required(),
  gender: yup.string().required(),
});

export default function PersonalRegistration(props) {
  const [phoneDialCode, setDialCode] = useState('');

  const { t } = useTranslation();
  const uploadimage = async e => {
    setHidden(true);
  };
  const { setStep, initialValues } = props;
  const [fileName, setFileName] = useState(t('profile_pic'));

  const [blob, setBlob] = useState(null);
  const [userimage, setUserImage] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [inputImg, setInputImg] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const setPhoneDialCode = e => {
    const dialCode = countryDialCodes.find(
      cdc => cdc.name === e.target.value,
    );
    setDialCode(dialCode.dialCode);
  };

  const getBlob = blob => {
    console.log(blob);
    setBlob(blob);
  };

  const getBase64 = file =>
    new Promise(function(resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject('Error: ', error);
    });

  const onFileChange = e => {
    // setFileUrl(window.URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    setImgFile(file);
    const reader = new FileReader();
    setHidden(false);

    getBase64(e.target.files[0])
      .then(result => {
        console.log(result,file)
        setUserImage(file);
      })
      .catch(e => console.log(e));

    reader.addEventListener(
      'load',
      () => {
        setInputImg(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
    setFileName(file.name);
  };
  return (
    <Col sm={12} xs={12}>
      <h5 className="card-title">{t('personal_detail')}</h5>
      <Col xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          {blob ? (
            <img src={URL.createObjectURL(blob)} />
          ) : (
            <img className="img-fluid" src={placeholderPic} alt="profile" />
          )}
        </div>
      </Col>
      <Col xs={12} sm={12}>
        {inputImg && !hidden && (
          <Row className="mt-5">
            <Col className="mb-3" xs={12} sm={12} style={{ height: '500px' }}>
              <ImageCropper getBlob={getBlob} inputImg={inputImg} />
            </Col>
            <Col xs={12} sm={12}>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={uploadimage}>{t('update')}</Button>
              </div>
            </Col>
          </Row>
        )}
      </Col>
      <Formik
        validationSchema={schema}
        onSubmit={function(values) {
          props.change({'profileImage':imgFile,...values});
          setStep(1);
        }}
        initialValues={{
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          nationality: '',
          residence: '',
          phone: '',
          gender: '',
          profilePicture:''
        }}
        validator={() => ({})}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate id="reg-form" onSubmit={handleSubmit}>
             <Form.Group>
                <Form.Label>{t('profile_pic')}</Form.Label>
                <Form.File
                  name="profilePicture"
                  label={fileName}
                  id="profilePicture"
                  onChange={e => onFileChange(e)}
                  accept="image/*"
                  custom
                />
              </Form.Group>
            <Form.Group>
              
              <Form.Label>{t('first_name')}</Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                value={values.firstName}
                onChange={handleChange}
                isInvalid={!!errors.firstName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('last_name')}</Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                value={values.lastName}
                onChange={handleChange}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('gender')}</Form.Label>
              <Form.Check
                type="radio"
                label="Male"
                name="gender"
                value="Male"
                onChange={handleChange}
                isInvalid={!!errors.gender}
                required
              />
              <Form.Check
                type="radio"
                label="Female"
                name="gender"
                value="Female"
                onChange={handleChange}
                isInvalid={!!errors.gender}
              />
              <Form.Control.Feedback type="invalid">
                {errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('date_of_birth')}</Form.Label>
              <Form.Control
                name="dateOfBirth"
                type="date"
                value={values.dateOfBirth}
                onChange={handleChange}
                isInvalid={!!errors.dateOfBirth}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dateOfBirth}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('nationality')}</Form.Label>
              <Form.Control
                name="nationality"
                as="select"
                value={values.nationality}
                onChange={handleChange}
                isInvalid={!!errors.nationality}
                custom
              >
                <option value="">-- {t('select_nationality')} --</option>
                {nations.map(nation => (
                  <option key={nation.num_code} value={nation.nationality}>
                    {nation.nationality}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.nationality}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('residence_country')}</Form.Label>
              <Form.Control
                name="residence"
                as="select"
                value={values.residence}
                onChange={e => {
                  setPhoneDialCode(e);
                  handleChange(e);
                }}
                isInvalid={!!errors.residence}
                custom
              >
                <option value="">-- {t('select_residence')} --</option>
                {countryDialCodes.map(dialCode => (
                  <option key={dialCode.isoCode} value={dialCode.name}>
                    {dialCode.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.residence}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('phone')}</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend style={{maxWidth:100}}>
                  <Form.Control
                    name="phoneCode"
                    as="select"
                    value={phoneDialCode || values.phoneCode}
                    onChange={handleChange}
                    custom
                  >
                    {countryDialCodes.map(dialCode => (
                      <option key={dialCode.isoCode} value={dialCode.dialCode}>
                        {`${dialCode.isoCode} ${dialCode.dialCode}`}
                      </option>
                    ))}
                  </Form.Control>
                </InputGroup.Prepend>
                <Form.Control
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

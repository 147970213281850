import React, { useState, useEffect } from 'react'
import { get, post } from 'services/RestService'
import { useSelector } from 'react-redux'
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap'
import { options } from 'helper'
import moment from 'moment'
import { useHistory } from 'react-router';

const ScheduleTest = () => {
  const [testTypes, setTestType] = useState([])
  const { accessToken } = useSelector(state => state.studentLogin);
  const [timeValue, setTimeValue] = useState('10:10 am');
  const [dateValue, setDateValue] = useState(null);
  const [types, setTypes] = useState()
  const notify = message => toast(message);
  const history = useHistory()

  const getTestTypes = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    get( `/student/drivingTestTypes`, config).then((res) => {
      setTestType(res)
    }).catch((err) => {

    })
  }

  const scheduleTest = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const testData = new FormData();
    testData.append("date", moment(`${dateValue} ${timeValue}`, "YYYY-MM-DD HH:mm:ss").format());
    testData.append("testTypeId", types);
    post('/student/scheduleTest', testData, config).then((res)=> {
      notify(res.message)
      history.push('/')
    }).catch((err)=> {
      notify(err.error)
    })
  }
  
  useEffect(() => {
    getTestTypes()
  }, [])
  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: 10}}>Schedule a Test </h1>
      <center>
      <div style={{ width: '50%', marginTop: 30}}>
          <p style={{ marginBottom: 5, marginTop: 5, textAlign: 'left'}}>Select Test Type</p>
          <Form.Control
                name="types"
                as="select"
                value={types}
                onChange={(e)=> setTypes(e.target.value)}
                custom
              >
                <option value="">Select Test Type</option>
                {testTypes.map(types => (
                  <option key={types._id} value={types._id}>
                    {types.testName}
                  </option>
                ))}
              </Form.Control>

                <p style={{ marginBottom: 5, marginTop: 5,  textAlign: 'left'}}>Select Date</p>
                <Form.Control
                  required
                  type="date"
                  name="date"
                  value={dateValue}
                  onChange={date => setDateValue(date.target.value)}
                />
               <p style={{ marginBottom: 5, marginTop: 5,  textAlign: 'left'}} >Select Time</p>
              <Form.Control
                  required
                  type="time"
                  name="time"
                  value={timeValue}
                  onChange={date => setTimeValue(date.target.value)}
                />   
              <button onClick={()=> scheduleTest()} style={{ marginTop: 30}} type="button" class="btn btn-primary">Submit</button>                   
      </div>
      </center>
      <ToastContainer newestOnTop={true} />
    </div>
  )
}

export default ScheduleTest

import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { get } from 'services/RestService'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next';
export default function PrivacyPolicy() {
  const {t} = useTranslation()
  const [terms, setTerms] = useState([])
  const langRedux = useSelector(state => state.language);
  useEffect(() => {
    get('/admin/getOnePolicy').then((res)=> {
      setTerms(res)
    }).catch((err) => {
      console.log(err)
    })
  }, [])
  console.log(terms);
  return (
    <Container style={{ marginTop: '5%' }}>
      <Row>
        <Col xs={12} sm={12} className="mb-5">
          <h1 style={{ textAlign: 'center' }}>
          {
            terms?.[
              langRedux.language === 'en'
                ? 'title'
                : 'titleArabic'
            ]
          }
          </h1>
        </Col>
        <Col xs={12} sm={12}>

          <div className="mb-5">  
            <p style={{ textAlign: 'center'}}>
            {
            terms?.[
              langRedux.language === 'en'
                ? 'description'
                : 'descriptionArabic'
            ]
          }
            </p>
          </div>
          
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import Pattern from '../../../../assets/images/car.jpg';
import Logo from '../../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import StudentRegForm from './StudentRegistration';
import styled from 'styled-components';
import { firebase, auth } from '../../../../config/firebase';

import { useTranslation } from 'react-i18next';
const BackButton = styled(Link)`
  color: #fc8200;
`;

export default function Index() {
  const { t } = useTranslation();
  return (
    <div className="row register" style={{ height: '100vh', width: '100%' }}>
      <div
        className="col-xs-0 col-sm-0 col-lg-5 "
        style={{
          backgroundImage: `url(${Pattern})`,
          objectFit: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
        }}
      ></div>
      <div
        className="col-xs-12 col-sm-12 col-lg-7"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <div className="row" style={{ height: '25vh' }}>
          <div className="col-12 text-center">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="col-12 text-center">
            <h3 className="title">{t('student_registration')}</h3>
          </div>
        </div>
        <div className="row ml-3 justify-content-center" style={{ height: 'auto' }}>
          <div className="col-8 mb-3">
            <BackButton to="/">
              <i className="fas fa-home mr-2"></i>{t('home')}
            </BackButton>
          </div>
          <div className="card col-12 col-md-8 mx-2">
            <div className="card-body">
              <div className="row">
                <StudentRegForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

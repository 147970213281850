import React, { useEffect, useState } from 'react';
import CourseDetail from './CourseDetailsSections/CourseDetails';
import Faq from './CourseDetailsSections/Faq';
import Footer from 'components/Footer';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourseDetails } from 'stateManagement/actions/studentActions';
import { BASE_API_URL_V2 } from 'store';
import { axiosClient } from 'helper';
import {
  GET_COURSE_DETAILS_SUCCESS,
  GET_COURSE_DETAILS_FAIL,
} from '../../../stateManagement/constants/studentConstants';
import axios from 'axios';
export default function Courses() {
  const location = useLocation();
  const { special } = location.state;
  const { id } = useParams();

  const dispatch = useDispatch();

  const [courseDetails, setCourseDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // function for fetching the courseDetails and setting them in local component state.
  async function getCourseDetails(courseId) {
    console.log('course details fired', courseId);
    setLoading(true);
    if (special) {
      try {
        console.log('special ');
        const { data, config } = await axios.get(
          `${BASE_API_URL_V2}/student/getSpecialOffer`,
          {
            params: {
              id: courseId,
            },
          },
        );
        data[0].course.price = parseFloat(data[0].priceAfter)
        console.log(data, config, 'special');
        setCourseDetails(data[0].course);
        dispatch({
          type: GET_COURSE_DETAILS_SUCCESS,
          payload: data[0].course,
        });
        setLoading(false);
        // const location = await JSON.parse(data.locations);
        // console.log(location);
      } catch (error) {
       
        console.log(error, 'special error');
        setLoading(false);
      }
    } else {
      try {
        const { data, config } = await axiosClient().get(
          `${BASE_API_URL_V2}/student/getCourse`,
          {
            params: {
              id: courseId,
            },
          },
        );
        setCourseDetails(data);
        dispatch({
          type: GET_COURSE_DETAILS_SUCCESS,
          payload: data,
        });
        setLoading(false);
        console.log(data, config);
        // const location = await JSON.parse(data.locations);
        // console.log(location);
      } catch (error) {
        
        console.log(error, 'special error');
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getCourseDetails(id);
  }, [id]);

  // const { loading, error, courseDetails } = useSelector(
  //   state => state.getCourseDetails,
  // );

  return (
    <>
      {error ? (
        <h1>{error}</h1>
      ) : !loading ? (
        <div>
          <CourseDetail course={courseDetails} />
          <Faq course={courseDetails} />
          <Footer />
        </div>
      ) : null}
    </>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Card,
  Alert,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import * as yup from 'yup';
import { Formik } from 'formik';
import TimeKeeper from 'react-timekeeper';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';
import { axiosClient } from 'helper';
import { useHistory } from 'react-router-dom';
import Map from '../contactUsSections/Map';
import { Recorder } from 'react-voice-recorder';
import 'react-voice-recorder/dist/index.css';
import { ToastContainer, toast } from 'react-toastify';
import StripeCheckout from 'react-stripe-checkout';

import banner from 'images/banner.jpg';
import { Link } from 'react-router-dom';
import TimePicker from 'react-bootstrap-time-picker';

const schema = yup.object({
  date: yup.string().required(),
  time: yup.string().required(),
  paymentMethod: yup.string().required(),
  textNote: yup.string().required(),
});

const CustomCard = styled(Card)`
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  padding: 25px;
  ._1ceqH {
    margin-top: -20px;
  }
  ._1Yplu {
    display: none;
  }
  .ol-map {
    margin: 0px;
  }
`;

export default function BookCourseForm(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  const { t } = useTranslation();
  const [filename, setFilename] = useState(t('upload_images'));
  const [filenameVid, setFilenameVid] = useState(t('upload_video'));
  const [filenameVoice, setFilenameVoice] = useState(t('upload_voice'));
  const [timeValue, setTimeValue] = useState('10:10 am');
  console.log({ timeValue });
  const [dateValue, setDateValue] = useState(null);
  const [note, setNote] = useState('');
  const [imageFiles, setImageFiles] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  window.toast = toast;
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPaypalButton, setShowPaypalButton] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [promotionId, setPromotionId] = useState(false);
  const [offerId, setOfferId] = useState(false);
  const [trasactionType, setTrasctionType] = useState(false);
  const history = useHistory();
  // getting the student and course details from state.
  const { studentId, accessToken, userId } = useSelector(
    state => state.studentLogin,
  );
  const { courseDetails } = useSelector(state => state.getCourseDetails);
  console.log('detail', courseDetails);
  const trainerId = courseDetails.trainer._id;
  const price = courseDetails.price;
  const courseId = courseDetails._id;

  // Creating a new formdata object

  // Validates the data and appends to the formdata object and shows the paypal button.
  // Executed when the book course button is clicked.
  const createData = values => {
    // displaying the paypal button
    setShowPaypalButton(true);
    console.log(values);
    // iterating through imageFiles and appending to images
  };

  const onToken = token => {
    saveTransaction(token.id);
    console.log(token);
    console.log(token.id);
  };

  // Function for booking course with backend.
  async function bookCourse(id) {
    var bookCourseData = new FormData();

    // appending all the data to the formdata object.
    bookCourseData.append('transactionId', id);
    bookCourseData.append('trainerId', trainerId);
    bookCourseData.append('studentId', studentId);
    bookCourseData.append('courseId', courseId);
    bookCourseData.append('date', dateValue);
    bookCourseData.append('time', timeValue);
    bookCourseData.append('image', imageFiles);
    bookCourseData.append('document', videoFile);
    bookCourseData.append('audio', audioFile);
    console.log(imageFiles, 'data');
    setLoading(true);
    try {
      const c = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const { data, config } = await axios.post(
        `${BASE_API_URL_V2}/student/bookCourse`,
        bookCourseData,
        c,
      );

      // if the status is true which means booking created successfully.
      if (data.status) {
        setLoading(false);
        setBookingStatus(true);
        setShowAlert(true);
        setTimeout(() => {
          history.push(`/my-bookings`);
        }, 2000);
      }
      // if the status is false which means booking unsuccessful.
      else if (data.status === false) {
        console.log('2', data, config);
        setLoading(false);
        setBookingStatus(false);
        setErrorMessage(data.error);
        setShowAlert(true);
      } else {
        setLoading(false);
        console.log(data);
        window.alert(data.error, 'hi');
      }
    } catch (e) {
      console.log(e);
    }
  }

  // function for saving transaction to backend.
  async function saveTransaction(tokenId) {
    const formData = new FormData();
    formData.append('type', 'Course');
    formData.append('source', tokenId);
    formData.append('courseId', courseId);

    const formDataPromotions = new FormData();
    formDataPromotions.append('type', 'Course');
    formDataPromotions.append('source', tokenId);
    formDataPromotions.append('promotionId', promotionId);

    const formDataSpecialOffer = new FormData();
    formDataSpecialOffer.append('type', 'Course');
    formDataSpecialOffer.append('source', tokenId);
    formDataSpecialOffer.append('offerId', offerId);

    if (trasactionType === 'promotion') {
      try {
        const { data, config } = await axiosClient().post(
          `/student/courseTransactionFromPromotion`,
          formDataPromotions,
        );
        console.log(data, config);
        if (data.status) {
          bookCourse(data.transactionId);
        } else {
          setErrorMessage(data.error);
        }
      } catch (error) {
        let err = '';

        if (error && error.response && error.response.data)
          err = error.response.data.msg || error.response.data;
        else err = 'please check internet connection';

        err = err || 'plaease try again later';
        setErrorMessage(err.error);
        setShowAlert(true);
        console.log(err.error);
      }
    }

    if (trasactionType === 'special-offer') {
      try {
        const { data, config } = await axiosClient().post(
          `/student/courseTransactionFromOffer`,
          formDataSpecialOffer,
        );
        console.log(data, config);
        if (data.status) {
          bookCourse(data.transactionId);
        } else {
          setErrorMessage(data.error);
        }
      } catch (error) {
        let err = '';

        if (error && error.response && error.response.data)
          err = error.response.data.msg || error.response.data;
        else err = 'please check internet connection';

        err = err || 'plaease try again later';
        setErrorMessage(err.error);
        setShowAlert(true);
        console.log(err.error);
      }
    } else {
      try {
        const { data, config } = await axiosClient().post(
          `/student/createCourseTransaction`,
          formData,
        );
        console.log(data, config);
        if (data.status) {
          bookCourse(data.transactionId);
        } else {
          setErrorMessage(data.error);
        }
      } catch (error) {
        let err = '';

        if (error && error.response && error.response.data)
          err = error.response.data.msg || error.response.data;
        else err = 'please check internet connection';

        err = err || 'plaease try again later';
        setErrorMessage(err.error);
        setShowAlert(true);
        console.log(err.error);
      }
    }
  }

  const [audio, setAudio] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });
  const handleAudioStop = async data => {
    console.log(data);

    setAudio(data.url);
    var file = new File([data.blob], 'recorded', {
      lastModified: new Date().getTime(),
      type: 'audio/mp3',
    });
    setFilenameVoice(file.name);
    setAudioFile(file);
  };
  const handleAudioUpload = file => {
    console.log(file);
  };
  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudio(reset);
  };
  // handling change in files.
  const handleFile = e => {
    if (e.target.name === 'images') {
      if (e.target.files[0]?.size < 5000000) {
        setImageFiles(e.target.files[0]);
        setFilename(e.target.files[0].name.slice(0, 10) + ' ...');
      } else {
        window.toast.error('Size Limit Exceed - 5 mb');
      }
    } else if (e.target.name === 'videos') {
      if (e.target.files[0]?.size < 10000000) {
        setVideoFile(e.target.files[0]);
        console.log(e.target.files[0].size);
        setFilenameVid(e.target.files[0].name.slice(0, 10) + ' ...');
      } else {
        window.toast.error('Size Limit Exceeds - 10 mb');
      }
    } else if (e.target.name === 'audios') {
      if (e.target.files[0]?.size < 5000000) {
        setFilenameVoice(e.target.files[0].name.slice(0, 10) + ' ...');
        setAudioFile(e.target.files[0]);
      } else {
        window.toast.error('Size Limit Exceeds - 5 mb');
      }
    }
  };

  //todo: Need to handle the different loading states for payment and booking functions.

  useEffect(() => {
    let transactionType = window.location.href.split('?')[1];
    let promotionId = window.location.href.split('/bookCourse/')[1];
    let finalPromotionId = promotionId.split('?')[0];
    // set Promotion id here
    setPromotionId(finalPromotionId);
    setOfferId(finalPromotionId);
    setTrasctionType(transactionType);
    console.log(`Fetch trasction type from Url: ${transactionType}`);
    console.log(`Fetch  id from Url: ${finalPromotionId}`);
  }, []);
  return (
    <>
      <ToastContainer newestOnTop={true} />
      <div
        className="banner"
        style={{ backgroundImage: `url(${banner})`, marginBottom: 20 }}
      >
        <div className="overlay">
          <h1 className="text-white mb-2">{t('book_course')}</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="font-weight-bold">
                  {t('home')}
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                {t('book_course')}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container>
        {showAlert ? (
          <Alert
            style={{
              position: 'fixed',
              top: '15%',
              left: '10%',
              zIndex: 5000,
              width: '80%',
              alignContent: 'center',
            }}
            variant={bookingStatus === true ? 'success' : 'danger'}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            {bookingStatus === true ? (
              <>
                <Alert.Heading>Course Booked Successfully</Alert.Heading>
                <p>Your course has been booked.</p>
              </>
            ) : (
              <>
                <Alert.Heading>Course Booking Failed</Alert.Heading>
                <p> {errorMessage} </p>
              </>
            )}
          </Alert>
        ) : null}
        <Row className="justify-content-center" style={{ margin: '60px 0px' }}>
          <Col xs={12} sm={12}>
            <CustomCard>
              <h1 className="text-center">{t('book_form')}</h1>

              <Formik
                enableReinitialize
                validationSchema={schema}
                initialValues={{
                  date: dateValue,
                  time: '',
                  paymentMethod: '',
                  textNote: note,
                }}
              >
                {({ handleChange, handleBlur, values, touched, errors }) => (
                  <Form
                    onSubmit={e => {
                      createData(values);
                      e.preventDefault();
                    }}
                  >
                    <Form.Group>
                      <Form.Label>{t('our_headq')}</Form.Label>
                      <div style={{ height: 280, overflow: 'hidden' }}>
                        <Map />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('joining_date')}*</Form.Label>
                      <Form.Control
                        required
                        type="date"
                        name="date"
                        value={dateValue}
                        onChange={date => setDateValue(date.target.value)}
                        onBlur={handleBlur}
                        isInvalid={!!errors.date && (touched.date || dateValue)}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errors.date}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('at_what_time')}*</Form.Label>
                      <Form.Control
                        required
                        type="time"
                        name="time"
                        value={timeValue}
                        onChange={t => setTimeValue(t.target.value)}
                        onBlur={handleBlur}
                        // isInvalid={!!errors.time && (touched.time || timeValue)}
                      />
                      {/* <div>
                        <TimeKeeper
                          className="form-control"
                          time={timeValue}
                          name="time"
                          onChange={data => {
                            setTimeValue(data.formatted12);
                          }}
                        />
                      </div> */}
                      <Form.Control.Feedback type="invalid">
                        {timeValue === ''}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group style={{ zIndex: 1 }}>
                      <Form.File
                        required
                        name="images"
                        label={filename}
                        accept="image/*"
                        required={false}
                        custom
                        onChange={handleFile}
                        multiple
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.File
                        name="document"
                        label={filenameVid}
                        accept="application/*"
                        custom
                        onChange={handleFile}
                        required={false}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.File
                        name="audios"
                        label={filenameVoice}
                        accept="audio/*"
                        custom
                        required={false}
                        onChange={handleFile}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 10,
                        }}
                      >
                        <hr style={{ width: '48%' }} />
                        <h4>{t('or')}</h4>
                        <hr style={{ width: '48%' }} />
                      </div>
                      <Recorder
                        uploadButtonDisabled
                        hideHeader
                        hideAudioUploadButton
                        record={true}
                        title={'New recording'}
                        audioURL={audio.url}
                        showUIAudio
                        handleAudioStop={data => handleAudioStop(data)}
                        handleAudioUpload={data => handleAudioUpload(data)}
                        handleReset={() => handleReset()}
                        mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('text_note')}</Form.Label>
                      <Form.Control
                        name="textNote"
                        as="textarea"
                        type="text"
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        onBlur={handleBlur}
                        rows={3}
                        // isInvalid={!!errors.textNote && touched.textNote}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errors.textNote}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                    <h3
                      style={{ textAlign: 'center', margin: ' 1rem 0 2rem 0 ' }}
                    >
                      {t('amount')} : {price?.toFixed(2)}AED{' '}
                    </h3>
                    {!showPaypalButton ? (
                      <Button type="submit" style={{ width: '100%' }}>
                        {!loading ? (
                          <span>{t('proceed_to_pay')}</span>
                        ) : (
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                          ></span>
                        )}
                      </Button>
                    ) : null}
                  </Form>
                )}
              </Formik>
              {showPaypalButton ? (
                <>
                  <div>
                    <StripeCheckout
                      token={onToken}
                      stripeKey="pk_test_51Ji2MCIYCWBvhfEbFspz1lEeUwLswDCrfz4CazhoJpZWRBPywQvzziPzlZp3d775nikP0QlldXwUmcduXUYFzGQ400ZfsfC0TH"
                    />
                  </div>
                  <h2
                    style={{ display: `${paymentLoading ? 'block' : 'none'}` }}
                  >
                    Loading
                  </h2>
                </>
              ) : null}
            </CustomCard>
          </Col>
        </Row>
      </Container>
    </>
  );
}

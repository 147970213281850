import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
export default function ContactDetails(props) {
  const [loading, setLoading] = useState(true);
  const [contactData, setContactData] = useState(null);
  const { t } = useTranslation();
  // function for getting contact data.
  const langRedux = useSelector(state => state.language);

  // fetching contact data from api.
  useEffect(() => {
    if (props.data) {
      setLoading(false);
    }
  }, [props.data]);

  return (
    !loading && (
      <div className="contactDetails section">
        <div className="container">
          <div className="row mb-5">
            <div className="col">
              <h1 className="text-center">{t('our_address')}</h1>
              <div className="divider"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-12">
              <div className="card">
                <i className="fas fa-map-signs"></i>
                <h5 className="font-weight-strong">
                  {
                    props.data[
                      langRedux.language === 'en'
                        ? 'addressHeadingEnglish'
                        : 'addressHeadingArabic'
                    ]
                  }
                </h5>
                <p className="mb-0">
                  {
                    props.data[
                      langRedux.language === 'en'
                        ? 'addressContentEnglish'
                        : 'addressContentArabic'
                    ]
                  }
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4"></div>
          </div>
        </div>
      </div>
    )
  );
}

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import RoutesRegisterTrainer from './RegisterAsTrainer/index';
import RoutesRegisterStudent from './RegisterAsStudent/index';
import VerifyOTP from './VerifyOTP/index';

function RoutesRegister(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/trainer`}
          component={RoutesRegisterTrainer}
        />
        <Route exact path={`${match.path}/verifyotp`} component={VerifyOTP} />
        <Route
          exact
          path={`${match.path}/student`}
          component={RoutesRegisterStudent}
        />
      </Switch>
    </>
  );
}

export default RoutesRegister;

import { axiosClient } from 'helper';
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  defineValue,
 
} from 'store';

const TransactionCard = styled(Card)`
  h3,
  p {
    text-align: left;
  }
  // height: 200px;
  &:hover {
    cursor: pointer;
  }
`;

const MenuItems = styled.h4`
  color: #fc8200;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: #2c2e94;
  }
  &.active-item {
    color: #2c2e94;
  }
`;

export default function TestHistory() {
  const { t } = useTranslation();
  const { studentId, userId } = useSelector(state => state.studentLogin);

  const [activeItem, setActiveItem] = useState('all');
  const [loading, setLoading] = useState(true);

  const [courses, setCourses] = useState([]);
  const [coursesArr, setCoursesArr] = useState([]);
  const [coursesArrFiltered, setCoursesArrFiltered] = useState([]);
  const [hidden, setHidden] = useState(true);
  const [data, setData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const filterCourses = status => {
    if (status === 'all') {
      setCoursesArrFiltered(courses);
    } else {
      const filteredArr = coursesArr.filter(course => course.result === status);
      setCoursesArrFiltered(filteredArr);
    }
  };

  const filterCoursesByLevel = selectedLevel => {
    const filteredArr = courses.filter(course => course.type === selectedLevel);
    setCoursesArr(filteredArr);
    setHidden(false);
  };

  // function for getting all the tests given by user.
  async function getTests(userId) {
    setLoading(true);
    try {
      const { data } = await axiosClient().get(
        '/student/getStudentTestHistory',
        {
          params: {
            studentId: userId,
          },
        },
      );
      setCourses(data);
      setCoursesArrFiltered(data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  // fetching tests on page load
  useEffect(() => {
    getTests(userId);
  }, [userId]);

  useEffect(() => {
    filterCourses('all');
    filterCoursesByLevel('basic');
  }, []);

  //modal for course detail
  function MyVerticallyCenteredModal(props) {
    const { studentId, accessToken } = useSelector(state => state.studentLogin);

    const [loadingComp, setLoadingComp] = useState(false);
    const [loadingCanc, setLoadingCanc] = useState(false);
    const { t } = useTranslation();
    const langRedux = useSelector(state => state.language);

    const ratings = rating => {
      const Star = <i className="fas fa-star stars"></i>;
      const stars = [];
      for (let i = 0; i < rating; i++) {
        stars.push(Star);
      }
      return stars;
    };
    const checkIt = val => {
      if (val === 'completed' || val === 'cancelled') return true;
      else return false;
    };
    const changeColor = val => {
      if (val === 'pass') {
        return 'green';
      } else {
        return 'red';
      }
    };
    function minTommss(minutes) {
      var sign = minutes < 0 ? '-' : '';
      var min = Math.floor(Math.abs(minutes));
      var sec = Math.floor((Math.abs(minutes) * 60) % 60);
      return (
        sign + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
      );
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('about_test')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'myBook-card'}>
          <div className="col-sm-12">
            <div className="card">
              <div
                className="card-image"
                style={{
                  backgroundImage: `url(${props.data?.test.course.imagesPath[0]})`,
                }}
              ></div>
              <div>
                {/* <i className="fas fa-ambulance"></i> */}

                <p className="font-weight-strong category">
                  {
                    props.data?.test.course?.[
                      langRedux.language === 'en' ? 'nameEnglish' : 'nameArabic'
                    ]
                  }
                </p>
              </div>

              <hr />

              <div className="description">
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-database"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {' '}
                      {t('status')}
                    </p>
                  </div>
                  <div>
                    <p
                      className="value"
                      style={{
                        marginBottom: 0,
                        fontSize: 20,
                        textTransform: 'uppercase',
                        color: changeColor(props.data?.status),
                      }}
                    >
                      {defineValue(props.data?.status)}
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-table"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('passing_percentage')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.passingPercentage} %
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fa fa-thumbs-up"
                      aria-hidden="true"
                      style={{ marginTop: 1, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('obtained_percentage')}
                    </p>
                  </div>
                  <div>
                    <p
                      className="value"
                      style={{
                        marginBottom: 0,
                        color: changeColor(props.data?.status),
                      }}
                    >
                      {props.data?.obtainedPercentage} %
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fa fa-question-circle"
                      aria-hidden="true"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('total_questions')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.totalScore}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      aria-hidden="true"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('correct_questions')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.obtainedScore}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      class="fa fa-clock"
                      aria-hidden="true"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('time_taken')}
                    </p>
                  </div>
                  <div>
                    <p
                      className="value"
                      style={{ marginBottom: 0, color: 'purple' }}
                    >
                      {minTommss(props.data?.timeTaken)}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-id-badge"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {' '}
                      {t('test_id')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?._id}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  const langRedux = useSelector(state => state.language);

  return (
    !loading && (
      <Container style={{ marginTop: '5%' }}>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={data}
        />
        <Row className="justify-content-center">
          <Col sm={12} xs={12} className="mb-5">
            <h1 style={{ textAlign: 'center' }}>{t('test_history')}</h1>
          </Col>
          {/* <Col sm={12} xs={12}>
            <Form.Group>
              <Form.Control
                as="select"
                onChange={e => filterCoursesByLevel(e.target.value)}
              >
                <option value="">Select Level</option>
                <option value="basic">Basic</option>
                <option value="advance">Advance</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={8} className="mt-5">
            <Row className="justify-content-center">
              <Col>
                <MenuItems
                  className={activeItem === 'all' ? 'active-item' : ''}
                  onClick={() => {
                    filterCourses('all');
                    setActiveItem('all');
                  }}
                >
                  All
                </MenuItems>
              </Col>
              <Col>
                <MenuItems
                  className={activeItem === 'passed' ? 'active-item' : ''}
                  onClick={() => {
                    filterCourses('passed');
                    setActiveItem('passed');
                  }}
                >
                  Passed
                </MenuItems>
              </Col>
              <Col>
                <MenuItems
                  className={activeItem === 'failed' ? 'active-item' : ''}
                  onClick={() => {
                    filterCourses('failed');
                    setActiveItem('failed');
                  }}
                >
                  Failed
                </MenuItems>
              </Col>
            </Row>
          </Col> */}
          <Col xs={8} sm={8} className="mt-5">
            {!hidden && coursesArrFiltered.length > 0 ? (
              coursesArrFiltered.map(course => (
                <a
                  onClick={() => {
                    setData(course);
                    setModalShow(true);
                  }}
                  key={course._id}
                  style={{ textDecoration: 'none', color: '#000000' }}
                >
                  <TransactionCard className="mb-3">
                    <Card.Body>
                      <Row
                        className="align-items-center justify-content-center"
                        style={{ height: '100%' }}
                      >
                        <Col xs={5} sm={5}>
                          <Row>
                            <Col xs={12} sm={12}>
                              <p>{t('course')}</p>
                            </Col>
                            <Col xs={12} sm={12}>
                              <p>{t('level')}</p>
                            </Col>
                            <Col xs={12} sm={12}>
                              <p style={{ margin: '0px' }}>{t('status')}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={2} sm={2}>
                          <Row>
                            <Col xs={12} sm={12}>
                              <p>:</p>
                            </Col>
                            <Col xs={12} sm={12}>
                              <p>:</p>
                            </Col>
                            <Col xs={12} sm={12}>
                              <p style={{ margin: '0px' }}>:</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={5} sm={5}>
                          <Row>
                            <Col xs={12} sm={12}>
                              <p>
                                {
                                  course.test.course?.[
                                    langRedux.language === 'en'
                                      ? 'nameEnglish'
                                      : 'nameArabic'
                                  ]
                                }
                              </p>
                            </Col>
                            <Col xs={12} sm={12}>
                              <p>{course.test.testType}</p>
                            </Col>
                            <Col xs={12} sm={12}>
                              <b
                                style={{
                                  margin: '0px',
                                  color:
                                    course.status === 'pass' ? 'green' : 'red',
                                }}
                              >
                                {course.status === 'pass' ? 'Passed' : 'Failed'}
                              </b>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </TransactionCard>
                </a>
              ))
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <h2
                  style={{ textAlign: 'center', marginTop: 10, width: '80%' }}
                >
                  {t('no_test_yet')}
                </h2>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  );
}

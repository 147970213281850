import React from 'react';
import FooterMenu from './FooterMenu';

export default function index() {
  return (
    <>
      <FooterMenu />
      
    </>
  );
}

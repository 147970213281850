import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'services/RestService';
import { BASE_URL_V2 } from 'store';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';

export default function TopBar() {
  const [loading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState(null);
  const mountedRef = useRef(true);
  const langRedux = useSelector(state => state.language);
  // function for getting Header data.
  const getHeaderData = () => {
    setLoading(true);
    get('/student/getHeader')
      .then(data => {
        if (!mountedRef.current) return null;
        console.log(data);
        setHeaderData(data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getHeaderData();
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return (
    !loading && (
      <nav className="topBar  ">
        <div style={{ marginTop: '-20px' }} className="container-fluid">
          <div className="row">
            <div className="col logoCol">
              <Link to="/">
                {/* <img src={logo} alt={window.appName} className="img-fluid" /> */}
                <img
                  src={headerData?.logoFilePath}
                  // src={logo}
                  alt={window.appName}
                  className="img-fluid"
                  style={{
                    width: '7rem',
                  }}
                />
              </Link>
              <div className="d-md-none mt-2 text-center">
                <h6 className="heading font-weight-bold">
                  {headerData?.nameEnglish}
                  <span className="d-inline-block">
                    {headerData?.nameArabic}
                  </span>
                </h6>
                <p className="mb-0 small">{headerData?.contentEnglish}</p>
              </div>
            </div>
            <div className="col-6 headingCol">
              <h1 className="heading font-weight-bold">
                {headerData?.nameEnglish}
                <span className="d-inline-block">{headerData?.nameArabic}</span>
              </h1>
              <p className="mb-0">
                {
                  headerData?.[
                    langRedux.language === 'en'
                      ? 'contentEnglish'
                      : 'contentArabic'
                  ]
                }
              </p>
            </div>

            <div className="col iconsCol">
              <p className="mb-0">
                <i className="fas fa-envelope text-theme-primary mr-2"></i>

                <a href={`mailto:${headerData?.email}`} className="text-muted">
                  {headerData?.email}
                </a>
              </p>
              <p className="mb-0">
                <i className="fas fa-phone-alt text-theme-primary mr-2"></i>
                <a
                  href={`tel:${headerData?.telephoneNo}`}
                  className="text-muted"
                >
                  {headerData?.telephoneNo}
                </a>
              </p>
              {headerData?.telephoneNo2 && (
                <p className="mb-0">
                  <i className="fas fa-phone-alt text-theme-primary mr-2"></i>
                  <a
                    href={`tel:${headerData?.telephoneNo}`}
                    className="text-muted"
                  >
                    {headerData?.telephoneNo2}
                  </a>
                </p>
              )}
              <p className="mb-0">
                <i className="fas fa-mobile-alt text-theme-primary mr-2"></i>
                <a href={`tel:${headerData?.mobileNo}`} className="text-muted">
                  {headerData?.mobileNo}
                </a>
              </p>
            </div>
          </div>
        </div>
      </nav>
    )
  );
}

import React, { useState, useEffect } from 'react';
import { get } from 'services/RestService';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function CourseCategories() {
  const [courses, setCourses] = useState([]);
  const langRedux = useSelector(state => state.language);
  const { t } = useTranslation();
  // for getting the categories.
  const getCategories = () => {
    //setCategoryLoading(true);
    get('/student/getCategories')
      .then(data => {
        console.log(data);
        setCourses(data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="courseCategories section">
      <div className="container">
        <div className="row mb-5">
          <div className="col">
            <h1 className="text-center">{t('course_categories')}</h1>
            <div className="divider"></div>
            <p className="text-center">{t('course_categories_c')}</p>
          </div>
        </div>

        <div className="row">
          {courses?.length
            ? courses.map(course => (
                <div
                  className="col-12 col-md-6 col-lg-4"
                  key={course._id}
                  style={{ marginBottom: 20 }}
                >
                  <div className="card">
                    <div
                      className="card-image"
                      style={{
                        backgroundImage: `url(${course?.imagePath})`,
                      }}
                    ></div>
                    {/* <i className="fas fa-ambulance"></i> */}
                    <h6 className="font-weight-strong">
                      {
                        course[
                          langRedux.language === 'en'
                            ? 'nameEnglish'
                            : 'nameArabic'
                        ]
                      }
                    </h6>
                    {/* <p className="mb-0">{course.descriptionEnglish}</p> */}
                    <Link
                      className="align-middle text-center"
                      to={{
                        pathname: `/courses`,
                        state: {
                          id: course._id,
                        },
                      }}
                    >
                      {t('view_courses')}{' '}
                    </Link>
                  </div>
                </div>
              ))
            : null}
        </div>
        <center>
          <div style={{ marginTop: 30}}>
            <Link
                to={`/courses?view=all`}
                className="btn btn-lg btn-primary"
              >
                View All Courses
            </Link>
          </div>
      </center>
      </div>
    </div>
  );
}

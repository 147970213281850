import React, { useState, useEffect } from 'react';
import { Form, Col, InputGroup, Button, Row, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import placeholderPic from 'images/person.png';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../../../stateManagement/actions/studentActions';
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
// Registration form schema
const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(8, 'Should be minimum 8 characters long')
    .required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
  dob: yup.date().required('Date of Birth is required field'),
  nationality: yup.string().required(),
  //courses: yup.string().required(),
  //testDate: yup.date().required(),
  otp: yup.string().required(),
  grade: yup.string().required(),
  //driving: yup.string().required(),
  country: yup.string().required(),
  phoneCode: yup.string().required(),
  phone: yup.string().required(),
  gender: yup.string().required(),
});

export default function VerifyOTP() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const notify = message => toast(message);

  // getting available courses from redux state
  const { loading, error, courses } = useSelector(state => state.getCourses);
  const history = useHistory();

  // keeping track of registration status
  const [registerStatus, setRegisterStatus] = useState(false);

  const [showOtp, setShowOtp] = useState(true);
  // console.log('emailForOtp', emailForOtp);

  // loading for when waiting for response from server.
  const [submitLoading, setSubmitLoading] = useState(false);
  console.log('location.email', location?.email);
  // Redirecting if registration is successful

  const langRedux = useSelector(state => state.language);

  const [fileName, setFileName] = useState(t('profile_pic'));
  // const [url, setURL] = useState('');
  // const [fileParts, setFileParts] = useState(fileNamesArray);
  const [sendOtp, setSendOtp] = useState('Send OTP');
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  console.log({ otp });
  const [successMess, setSuccessMedd] = useState({
    text1: '',
    text2: '',
    state: false,
  });

  const [sendVerify, setSendVerify] = useState({
    text: 'Resend',
    color: 'black',
  });

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setEmail(location.email ? location.email : '');

    if (!email || email !== '') {
      return <Redirect to="/register/student" />;
    }
  }, []);

  useEffect(() => {
    //Getting the course list from api.
    dispatch(getCourses());

    // redirecting if registered status is true
    if (registerStatus) {
      setTimeout(() => {
        history.push('/login');
      }, 2000);
    }
  }, [registerStatus]);

  // onSubmit function for register form.
  const dataSubmit = async res => {
    // setting the loading to true
    setSubmitLoading(true);

    // creating form data object to be sent.
    var registerAccountData = new FormData();

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/studentSignUp`,
      registerAccountData,
      config,
    );
    if (data.status) {
      submitEmail(res.values.email);
      setShowOtp(true);
      setSubmitLoading(false);

      setSuccessMedd({
        text1: 'Otp Send',
        text2: 'Enter Otp sent to you email address.',
        state: true,
      });
      setShowAlert(true);
    } else if (data.status === false) {
      setSubmitLoading(false);
      setRegisterStatus(false);
      setErrorMessage(data.error);
      setShowAlert(true);
    } else {
      setSubmitLoading(false);
      window.alert(data.error);
    }
    setSubmitLoading(false);
    console.log(data);
  };

  const submitEmail = async val => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    console.log(val);
    var form = new FormData();
    form.append('email', val);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/sendStudentOtpEmail`,
      form,
      config,
    );
    if (data.statusText === 'Success') {
      setSendOtp('Sent');
      console.log(data);
    } else {
      window.alert(data.message);
      console.log(data);
    }
  };

  const submitOtp = async val => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    var form = new FormData();
    form.append('email', email);
    form.append('otpCode', otp);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/emailVerified`,
      form,
      config,
    );
    if (data.statusText === 'Success') {
      setSendVerify({
        text: 'Verified!',
        color: 'green',
      });

      setSubmitLoading(false);
      setRegisterStatus(true);
      setSuccessMedd({
        text1: 'Account Created Successfully',
        text2: 'Your new account has been registered.',
        state: true,
      });
      setShowAlert(true);
      console.log(data);
    } else {
      notify(data.message);
      console.log(data);
    }
  };

  const resendOTP = async () => {
    console.log('Email for agian otp', email);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    var form = new FormData();
    form.append('email', email);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/sendStudentOtpEmail`,
      form,
      config,
    );
    console.log({ data });
    if (data.statusText === 'Success') {
      setSendOtp('Sent');
      notify(`OTP Sent. ${data.message}`);
      console.log(data);
    } else {
      notify(data.message);
      setOtp('');
      console.log(data);
    }
  };

  return (
    <>
      {showAlert ? (
        <Alert
          style={{
            position: 'fixed',
            top: '5%',
            left: '10%',
            zIndex: 500,
            width: '80%',
            alignContent: 'center',
          }}
          variant={successMess.state === true ? 'success' : 'danger'}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {successMess.state === true ? (
            <>
              <Alert.Heading>{successMess.text1}</Alert.Heading>
              <p> {successMess.text2}</p>
            </>
          ) : (
            <>
              <Alert.Heading>Account Creation Failed</Alert.Heading>
              <p> {errorMessage} </p>
            </>
          )}
        </Alert>
      ) : null}

      <Col sm={12} xs={12}>
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={{
            otp: '',
          }}
        >
          {({ values }) => (
            <Form noValidate>
              <>
                <Form.Group>
                  {/* <Form.Label>{t('otp')}</Form.Label> */}
                  {/* <InputGroup>
                    <Form.Control
                      name="otp"
                      type="otp"
                      value={values.otp}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={!!errors.otp && touched.otp}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.otp}
                    </Form.Control.Feedback>
                  </InputGroup> */}
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    inputStyle={{ color: 'green' }}
                    containerStyle={{
                      // backgroundColor: 'red',
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                    inputStyle={{
                      height: 50,
                      width: 50,

                      // backgroundColor: 'gray',
                    }}
                  />
                </Form.Group>

                <div className="col-12 text-right">
                  <p
                    style={{
                      cursor: 'pointer',
                      color: 'blue',
                      display: 'inline-block',
                    }}
                    onClick={() => resendOTP()}
                    className="mb-10 RA-13-fnt-size "
                  >
                    resend OTP?
                  </p>
                </div>
                <Button
                  className="btn btn-primary btn-md col-12"
                  onClick={() => {
                    submitOtp(values);
                  }}
                >
                  {!submitLoading ? (
                    <span>{t('verify_it')}</span>
                  ) : (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                    ></span>
                  )}
                </Button>
              </>

              <ToastContainer />
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
}

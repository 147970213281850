import { axiosClient } from 'helper';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userId } = useSelector(state => state.studentLogin);

  // Getting the reviews and setting them in local component state.
  async function getNotifications(userId) {
    setLoading(true);
    try {
      const { data } = await axiosClient().get('/student/getNotifications', {
        params: {
          userId,
        },
      });
      setNotifications(data);
      setLoading(false);
      console.log(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // Deleting Notification
  async function deleteNotification(notificationId) {
    setLoading(true);
    try {
      const { data } = await axiosClient().delete(
        '/student/deleteNotification',
        {
          data: {
            notificationId: notificationId,
          },
        },
      );
      if (data.status) {
        getNotifications(userId);
      } else {
        window.alert('unable to delete notification');
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getNotifications(userId);
  }, [userId]);

  return (
    <Container style={{ marginTop: '5%' }}>
      {!loading && notifications ? (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={12} sm={8}>
            <h2 style={{ textAlign: 'center' }}>Notifications</h2>
          </Col>
          <Col xs={12} sm={8}>
            <p style={{ textAlign: 'center', marginBottom: '3rem' }}>
              <b>You got following pending Notifications</b>
            </p>
          </Col>
          {notifications.map(notification => (
            <Col xs={12} sm={8} key={notification._id} className="mb-5">
              <Card>
                <Card.Body>
                  <Row className="justify-content-between align-items-center">
                    <Col>
                      <p style={{ paddingLeft: '1rem', margin: '0px' }}>
                        <b>Message: </b> {notification.title}
                        <br />
                        <b> Date: </b>
                        {new Date(notification.createdAt).toLocaleString(
                          'en-US',
                          {
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                          },
                        )}
                        <br />
                        <b> Time: </b>{' '}
                        {new Date(notification.createdAt).toLocaleDateString(
                          'en-GB',
                        )}
                      </p>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        textAlign: 'right',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <button
                        style={{
                          margin: '0px',
                          fontSize: '1.25rem',
                          color: 'red',
                          background: 'none',
                          border: 'none',
                        }}
                        onClick={() => {
                          deleteNotification(notification._id);
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <h2 style={{ textAlign: 'center' }}>
          Loading Notifications{' '}
          <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
        </h2>
      )}
    </Container>
  );
}

// const [notifications, setNotifications] = useState([
//   // {
//   //   id: '1',
//   //   type: 'chat',
//   //   text: 'Saleem sent you a message',
//   //   viewDetailsLink: '',
//   // },
//   // {
//   //   id: '2',
//   //   type: 'Special Offer',
//   //   text: 'There is promotion available',
//   //   viewDetailsLink: '',
//   // },
// ]);

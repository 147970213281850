import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { get } from 'services/RestService';
import { useTranslation } from 'react-i18next';

export default function Counters() {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState(null);

  const { t } = useTranslation();
  // function for getting About data.
  const getAboutData = () => {
    setLoading(true);
    get('/admin/getHome')
      .then(data => {
        console.log(data);
        setAboutData(data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  // fetching About data from api.
  // useEffect(() => {
  //   getAboutData();
  // }, []);

  useEffect(() => {
    getAboutData();
    return () => {
      getAboutData();
      console.log('cleaned up');
    };
  }, []);
  const counters = [
    {
      icon: 'fas fa-graduation-cap',
      count: aboutData?.graduatedNo,
      isMore: aboutData?.graduatedNo.charAt(aboutData?.graduatedNo.length-1) === '+',
      title: t('graduated_here'),
    },
    {
      icon: 'fas fa-user-tie',
      count: aboutData?.teachersNo,
      isMore: aboutData?.teachersNo.charAt(aboutData?.teachersNo.length-1) === '+' ,
      title: t('teacher_number'),
    },
    {
      icon: 'fas fa-history',
      count: aboutData?.yearsNo,
      isMore: aboutData?.yearsNo.charAt(aboutData?.yearsNo.length-1) === '+',
      title: t('years_on_market'),
    },
    {
      icon: 'fas fa-user-graduate',
      count: aboutData?.studentsNo,
      isMore: aboutData?.studentsNo.charAt(aboutData?.studentsNo.length-1) === '+',
      title: t('present_students'),
    },
  ];

  return (
    <div className="counters">
      <div className="overlay">
        <div className="container">
          <div className="row">
            {!loading &&
              counters?.map((counter, i) => {
                return (
                  <div key={i} className="col-12 col-md-6 col-lg-3">
                    <div className="content text-center text-white">
                      <i className={`${counter.icon} fa-3x mb-3`}></i>
                      <h1 className="text-theme-primary">
                        <CountUp
                          end={parseInt(counter.count)}
                          separator=","
                          duration={3.5}
                          start={viewPortEntered ? null : 0}
                        >
                          {({ countUpRef }) => {
                            return (
                              <VisibilitySensor
                                active={!viewPortEntered}
                                onChange={isVisible => {
                                  if (isVisible) {
                                    setViewPortEntered(true);
                                  }
                                }}
                                delayedCall
                              >
                                <span>
                                  <span
                                    ref={countUpRef}
                                    className="d-inline-block"
                                  />
                                  {counter.isMore && <span>+</span>}
                                </span>
                              </VisibilitySensor>
                            );
                          }}
                        </CountUp>
                      </h1>
                      <div className="divider"></div>
                      <p className="m-0 font-weight-bold">{counter.title}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import CoursePromotions from './homeSections/CoursePromotions';
import { Link } from 'react-router-dom';
import banner from 'images/banner.jpg';
import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer';
 
const AllPromotions = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
          <div className="overlay">
            <h1 className="text-white mb-2">Promotions</h1>

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" className="font-weight-bold">
                    {t('home')}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-capitalize"
                  aria-current="page"
                >
                  Promotions
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <CoursePromotions />
      <Footer />
    </div>
  )
}

export default AllPromotions

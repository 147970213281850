import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { get } from 'services/RestService';

export default function CoursePromotions({home}) {
  const [promotions, setPromotions] = useState([]);
  const getPromotions = () => {
    get(`/student/getPromotions`)
      .then(data => {
        console.log('Here is my Promotions data: ', data);
        setPromotions(data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Carousel Custom Settings - Start https://github.com/sag1v/react-elastic-carousel/pull/17

  const carouselRef = useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(promotions.length);
    }
  };

  const customArrow = ({ type, onClick }) => {
    const pointer = type === 'PREV' ? `left` : 'right';
    return (
      <button onClick={onClick} className="customArrow">
        <i className={`fas fa-long-arrow-alt-${pointer} fa-2x`}></i>
      </button>
    );
  };

  const showNumberOfItems = () => {
    if (window.innerWidth <= 1000) return 1;
    else if (window.innerWidth >= 1200) return 3;
    else if (window.innerWidth >= 1100) return 2;
  };

  // Carousel Custom Settings - End

  useEffect(() => {
    getPromotions();
  }, []);

  return (
    <div className="coursePromotions section">
      <div className="container">
        <div className="row mb-5">
          <div className="col">
            <h1 className="text-center">Course Promotions</h1>
            <div className="divider"></div>
          </div>
        </div>

        <Carousel
          ref={carouselRef}
          itemsToShow={showNumberOfItems()}
          pagination={false}
          renderArrow={customArrow}
          onNextStart={onNextStart}
          onPrevStart={onPrevStart}
          // enableAutoPlay={true}
          // autoPlaySpeed={3000}
        >
          {promotions.map((promotion, i) => {
            return (
              <div
                key={i}
                className="course"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <img
                  src={promotion.promotionImage}
                  alt={promotion.title}
                  title={promotion.title}
                  className="mb-3 img-fluid"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
                <p>
                  <b>Price Before Discount:</b> ${promotion.previousPrice}
                </p>
                <p>
                  <b>Price After Discount:</b> ${promotion.afterDiscountPrice}
                </p>
                <p>
                  <b>Discount:</b> {promotion.discount}%
                </p>
                <p>
                  <b>Discount Validity Starts:</b>{' '}
                  {promotion.startDate.split('T')[0]}
                </p>
                <p>
                  <b>Discount Validity Ends:</b>{' '}
                  {promotion.endDate.split('T')[0]}
                </p>
                {/* <p>
                  <b>Provider:</b> {course.provider}
                </p> */}
                <Link
                  to={`/bookCourse/${promotion._id}?promotion`}
                  className="btn btn-lg btn-outline-dark"
                >
                  Book Now
                </Link>
              </div>
            );
          })}
        </Carousel>
        {home && <center>
          <div style={{ marginTop: 30}}>
            <Link
                to={`/promotions`}
                className="btn btn-lg btn-primary"
              >
                View All
            </Link>
          </div>
      </center>}
      </div>
    </div>
  );
}

import React from 'react';
import AboutDetails from './aboutSections/AboutDetails';
import ContactForm from '../../../common/ContactForm';
import Footer from 'components/Footer';

export default function ContactUs() {
  return (
    <>
      <AboutDetails />
      <ContactForm />
      <Footer />
    </>
  );
}

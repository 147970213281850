import React, { useState, useEffect, useRef } from 'react';
import banner from 'images/banner.jpg';
import { Link, useLocation } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCourseRating,
  getCourses,
} from 'stateManagement/actions/studentActions';
import { axiosClient, useQueryParams } from 'helper';
import image1 from '../../../../images/allcourse.jpg';
import { BASE_API_URL_V2, BASE_URL_V2, imageChecker, defineValue } from 'store';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
const CustomCard = styled(Card)`
  padding: 20px;

  border-radius: 5px;
  box-shadow: 5px #888888;
  h5 {
    color: #fc8200;
  }
  .rating {
    display: flex;
    justify-content: space-between;
    width: 40%;
  }
  .stars {
    color: #fc8200;
  }
`;

export default function Courses(props) {
  const location = useLocation();
  const state = location.state;
  
  const [categoryId, setCategoryId] = useState(state?.id ? state?.id : null);
  const [categories, setCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [rateLoad, setRateLoad] = useState(true);
  const [rate, setRate] = useState(null);
  const langRedux = useSelector(state => state.language);
  const query = useQueryParams()
  const allCourse = query.get('view')
  // filtering rating
  const getRating = id => {
    const rate = getCourseRating(id);
    setRate(rate.rating);
    setRateLoad(false);
    console.log(rate);
    // return rate.rating
  };

  const ratings = rating => {
    const Star = <i className="fas fa-star stars"></i>;
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(Star);
    }
    return stars;
  };

  const { t } = useTranslation();
  // for getting the categories.
  async function getCategories() {
    setCategoryLoading(true);
    try {
      const { data, config } = await axios.get(
        `${BASE_API_URL_V2}/student/getCategories`,
      );

      if (data.length > 0) {
        console.log(data);
        setCategories(data);
      }
      setCategoryLoading(false);
    } catch (error) {
      console.error(error);
      setCategoryLoading(false);
    }
  }

  const dispatch = useDispatch();

  // getting courses from redux state
  const { loading, error, courses } = useSelector(state => state.getCourses);
  console.log(courses);
  const { studentId } = useSelector(state => state.studentLogin);

  useEffect(() => {
    // dispatching to get courses and categories.
    dispatch(getCourses());
    getCategories();
  }, [studentId]);

  // filtering courses by category.
  function filterCourses(course) {
    // courses.map(e=>{getRating(e._id)})
    console.log(course.category?._id, categoryId, 'hi');
    if (categoryId !== null && course?.category?._id !== null) {
      return course?.category?._id === categoryId;
    } else if (categoryId === null) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if(allCourse) {
      setCategoryId(null)
    }
  }, [])

  return (
    <div className="courses">
      <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
        <div className="overlay">
          <h1 className="text-white mb-2">{t('courses')}</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="font-weight-bold">
                  {t('home')}
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                {t('courses')}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container pt-100 pb-100">
        <div className="row justify-content-center">
          <Row lg={7} sm={12} className="col-lg-8 col-12 order-1 order-lg-2">
            {loading
              ? null
              : error
              ? window.alert(error)
              : courses
                  .filter(course => filterCourses(course))
                  .map(course => (
                    // <Link
                    //   key={course._id}
                    //   to={`/courses/${course._id}`}
                    //   style={{ textDecoration: 'none', color: 'inherit' }}
                    // >
                    <div
                      className="col-sm-12 col-lg-6"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="card">
                        <div
                          className="card-image"
                          style={{
                            backgroundImage: `url(${course?.imagesPath[0]})`,
                          }}
                        ></div>
                        <p className="font-weight-strong price">
                          <span style={{ color: '#81B662' }}>AED </span>{' '}
                          {defineValue(course.price)}
                        </p>
                        <div className="description">
                          {/* <i className="fas fa-ambulance"></i> */}
                          <h6 className="font-weight-strong">
                            {
                              course?.[
                                langRedux.language === 'en'
                                  ? 'nameEnglish'
                                  : 'nameArabic'
                              ]
                            }
                          </h6>
                          <p className="font-weight-strong category">
                            {defineValue(
                              course.category?.[
                                langRedux.language === 'en'
                                  ? 'nameEnglish'
                                  : 'nameArabic'
                              ],
                            )}
                          </p>
                          <p className="font-weight-strong small-detail">
                            <i className="fas fa-map-marker"></i>{' '}
                            {t('offerd_by')}: {defineValue(course.institute)}
                          </p>
                          <p className="font-weight-strong small-detail">
                            <i className="fas fa-book"></i> {t('instructed_by')}
                            :{' '}
                            {defineValue(
                              course?.trainer?.firstName +
                                ' ' +
                                course?.trainer?.lastName,
                            )}
                          </p>

                          <div className="rating">
                            <div className="stars">
                              {ratings(
                                course.ratings ? Math.round(course.ratings) : 1,
                              ).map((star, index) => (
                                <i key={index}>{star}</i>
                              ))}
                            </div>
                          </div>
                        </div>
                        <Link
                          className="align-middle text-center"
                          to={{
                            pathname: `/courses/${course._id}`,
                            state: {
                              id: course._id,
                            },
                          }}
                        >
                          {t('view_detail')}
                        </Link>
                        {/* <a onClick={()=>{getRating(course._id)}}>Hi</a> */}
                      </div>
                    </div>
                    // </Link>
                  ))}
          </Row>
          <div
            className="col-lg-4 col-12 order-2 order-lg-1 left-card"
            style={{ height: '100%' }}
          >
            <div className="title-underlined">
              <h5
                className="card-title mb-0"
                style={{
                  padding: '20px',
                  textAlign: 'center',
                  fontFamily: 'initial',
                }}
              >
                {t('categorise').toUpperCase()}
              </h5>
            </div>
            <div className="card-body">
              <div
                className="d-flex align-items-center pb-2 pt-2 item-underlined"
                key="default-category"
              >
                <img
                  src={image1}
                  // src="https://dummyimage.com/120X100/477a13/ffffff.jpg&text=Dummy+Category"
                  width="100px"
                  height="70px"
                  className="pr-2"
                  alt={'Civil'}
                  style={{ resize: 'cover' }}
                />
                <div
                  style={{
                    color: categoryId === null ? '#fc8200' : 'initial',
                  }}
                  className="btn btn-link card-text category-title text-left"
                  onClick={() => setCategoryId(null)}
                >
                  {t('all_courses')}
                </div>
              </div>
              {categories.map(category => (
                <div
                  className="d-flex align-items-center pb-2 pt-2 item-underlined"
                  key={category._id}
                >
                  <img
                    src={`${category.imagePath}`}
                    // src="https://dummyimage.com/120X100/477a13/ffffff.jpg&text=Dummy+Category"
                    width="100px"
                    height="70px"
                    className="pr-2"
                    alt={
                      category?.[
                        langRedux.language === 'en'
                          ? 'nameEnglish'
                          : 'nameArabic'
                      ]
                    }
                    style={{ resize: 'cover', borderRadius: 5 }}
                  />
                  <div
                    style={{
                      color:
                        categoryId === category._id ? '#fc8200' : 'initial',
                    }}
                    className="btn btn-link card-text category-title text-left"
                    onClick={() => setCategoryId(category?._id)}
                  >
                    {
                      category?.[
                        langRedux.language === 'en'
                          ? 'nameEnglish'
                          : 'nameArabic'
                      ]
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import banner from 'images/banner.jpg';
import { Link } from 'react-router-dom';
import BasicTest from './components/BasicTest';
import ResultCard from './components/ResultCard';

export default function TheoryTest(props) {
  const { quizType ,testId} = props.match.params;
  console.log('f',testId)
  const [userName, setUserName] = useState('');
  const [hidden, setHidden] = useState(false);
  const [hideResultCard, setHideResultCard] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [score, setScore] = useState([]);

  return (
    <div>
      <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
        <div className="overlay">
          <h1 className="text-white mb-2">Theory Test</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="font-weight-bold">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                Theory Test
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container style={{ padding: '60px 0px' }}>
        <Row className="align-items-center justify-content-end">
          <Col xs={12} sm={12}>
            <BasicTest
              hidden={hidden}
              quizType={quizType}
              setHideResultCard={setHideResultCard}
              setSelfHidden={setHidden}
              setScore={setScore}
              setStartTime={setStartTime}
              setCourseId={setCourseId}
              setEndTime={setEndTime}
              setTotalQuestions={setTotalQuestions}
              setUserName={setUserName}
              startTime={startTime}
              testId={testId}
            />
          </Col>
          <Col xs={12} sm={12}>
            <ResultCard
              isHidden={hideResultCard}
              correctScore={score[0]}
              wrongScore={score[1]}
              startTime={startTime}
              endTime={endTime}
              totalQuestions={totalQuestions}
              userName={userName}
              passingPer={props?.location?.state?.detail}
              testId={testId}
              courseId={props?.location?.state?.course}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// const resetTheoryTest = () => {
//   setDate(Date.now() + 60000);
//   setQuestionIndex(0);
//   setEndTime('');
//   setCorrectScore(0);
//   setWrongScore(0);
// };
// // const {testid} = useParams();
{
  /* <Col xs={12} sm={12}>
            <InitialForm
              setHidden={setHidden}
              setUserName={setUserName}
              setQuizType={setQuizType}
              setHideResultCard={setHideResultCard}
              resetTheoryTest={resetTheoryTest}
            />
          </Col> */
}

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'services/RestService';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './styles.css'
import {
  MainContainer,
  Message,
  MessageInput,
  Sidebar,
  Avatar,
  ConversationList,
  Conversation,
  ChatContainer,
  ConversationHeader,
  MessageList,
} from '@chatscope/chat-ui-kit-react';
import { io } from 'socket.io-client';
import moment from 'moment';

const Chat = () => {
  //const  student  = useSelector(state => state.studentDetails);
  const [rooms, setRooms] = useState([]);
  const [roomId, setRoomId] = useState();
  const [header, setHeaderData] = useState();
  const [loading, setloading] = useState(false);
  const inputRef = useRef();
  const [msgInputValue, setMsgInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setmessagesLoading] = useState(false);
  const [socket, setSocket] = useState();
  const user = useSelector(state => state.studentDetails);
  const { userId, accessToken } = useSelector(state => state.studentLogin);

  useEffect(() => {
    const socket = io('https://api.civil.ae/', {
      transports: ['websocket'],
    });
    setSocket(socket);
  }, []);

  useEffect(() => {
    socket &&
      socket.on('connect', () => {
        console.log(socket.id);
      });

    return () => {
      socket && socket.emit('disconnect');
    };
  }, []);

  useEffect(() => {
    if (roomId) {
      socket.emit('join', { user, roomId }, res => {
        console.log('Joined: ', res, user);
      });
    }
  }, [roomId, userId]);

  const handleSend = message => {
    const text = message;
    console.log({ msg: text, roomId });
    socket.emit('sendMessage', text, roomId, res => {
      console.log(res);
      getMessages(roomId);
    });
    setMsgInputValue('');
    inputRef.current.focus();
  };

  useEffect(() => {
    if (socket) {
      console.log('danial');
      socket.on('message', res => {
        console.log('get mesage');
        getMessages(roomId);
      });
    }
  }, [socket, roomId]);

  const getRooms = () => {
    setloading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    get(`/chat/student/getMyRooms`, config)
      .then(res => {
        setRooms(res);
        setloading(false);
        console.log(res);
      })
      .catch(err => {
        setloading(false);
        console.log(err);
      });
  };

  const getMessages = id => {
    setmessagesLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    get(`/chat/getMessages/${id}`, config)
      .then(res => {
        setMessages(res);
        setmessagesLoading(false);
        console.log(res);
      })
      .catch(err => {
        setmessagesLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getRooms();
  }, []);

  const setHeader = data => {
    setRoomId(data._id);
    setHeaderData({
      image: data?.trainer?.imagePath,
      title: data?.trainer?.firstName + ' ' + data?.trainer?.lastName,
    });
  };

  return (
    <div style={{ height: '700px' }}>
      <MainContainer responsive>
        <Sidebar position="left" scrollable={false}>
          {/* <Search placeholder="Search..." /> */}
          <ConversationList loading={loading}>
            {rooms.length > 0 &&
              rooms?.map(room => (
                <Conversation
                  onClick={() => {
                    setHeader(room);
                    getMessages(room._id);
                  }}
                  name={`${room?.trainer?.firstName} ${room?.trainer?.lastName}`}
                  lastActivityTime={moment(room?.chat?.reverse()[0]?.updatedAt)
                    .startOf('hour')
                    .fromNow()}
                  info={room?.chat?.reverse()[0]?.message}
                >
                  <Avatar
                    src={room?.trainer?.imagePath}
                    name={`${room?.trainer?.firstName} ${room?.trainer?.lastName}`}
                  />
                </Conversation>
              ))}
          </ConversationList>
        </Sidebar>
        {
          <ChatContainer>
            {header && (
              <ConversationHeader>
                <Avatar src={header.image} name={header.title} />
                <ConversationHeader.Content userName={header.title} />
              </ConversationHeader>
            )}
            <MessageList scrollBehavior="smooth">
              {messages.length > 0 &&
                messages.map((m, i) => (
                  <Message
                    key={i}
                    model={{
                      message: m.message,

                      direction: `${
                        userId === (m?.from?._id || m?.to?._id)
                          ? 'outgoing'
                          : 'incoming'
                      }`,
                    }}
                  >
                    <Message.Footer sentTime={moment(m.createdAt).format("MMM Do YY")} />
                  </Message>
                ))}
            </MessageList>
            <MessageInput
              attachButton={false}
              placeholder="Type message here"
              onSend={handleSend}
              onChange={setMsgInputValue}
              value={msgInputValue}
              ref={inputRef}
            />
          </ChatContainer>
        }
      </MainContainer>
    </div>
  );
};

export default Chat;

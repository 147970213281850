import React from 'react';
import BookCourseForm from './BookCourseSections/BookCourseForm';

export default function BookCourse() {
  return (
    <div>
      <BookCourseForm />
    </div>
  );
}

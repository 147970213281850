import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { studentLogout } from '../stateManagement/actions/studentActions';
import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';

/* This defines the actual bar going down the screen */
const StyledSideNav = styled.div`
  position: relative; /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  height: calc(100vh - 250px);
  background-color: #2e3192;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: scroll;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
  @media only screen and (max-width: 1014px) {
    height: calc(100vh - 250px);
  }
`;

const NavItem = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  a,
  p {
    color: #ffffff;
    text-decoration: none;
  }
  a,
  p:hover {
    color: #0d017c;
    text-decoration: none;
  }

  i {
    color: #ffffff;
    padding-right: 10px;
  }

  &:hover {
    background-color: #ec8e2b;
    cursor: pointer;
  }

  &.active {
    background-color: #ec8e2b;
  }
`;

const LinkButton = styled(Button)`
  &.btn-link {
    padding: 0px;
    text-decoration: none;
  }
`;
//munib style
const st = { width: '100%', display: 'flex', alignItems: 'center', outline: 0 };

export default function SideNavBar({ showSiteNavTop, isUser }) {
  const { loading, firstName, lastName, image, error } = useSelector(
    state => state.studentDetails,
  );
  const { t } = useTranslation();
  // const [isUser, setUser] = useState('');
  // useEffect(() => {
  //   auth().onAuthStateChanged(user => {
  //     // this.props.navigation.navigate(user ? 'Home' : 'Auth')
  //     // alert(user? 'true': 'false')
  //     // alert(user)
  //     setUser(user);
  //     console.log(user);
  //   });
  // }, []);
  const [activeItem, setActiveItem] = useState(22);
  const siteMenuItems = [
    { path: '/', name: t('home'), key: 22, icon: 'fa-home' },
    {
      path: '/about',
      name: t('about'),
      key: 11,
      icon: 'fa-exclamation-circle',
    },
    {
      path: '/theoryTestForm',
      name: t('thoery'),
      key: 12,
      icon: 'fa-file-alt',
    },
    {
      path: '/special-offer',
      name: t('special_offer'),
      key: 20,
      icon: 'fa-file-alt',
    },

    { path: '/gallery', name: t('gallary'), key: 13, icon: 'fa-images' },
    { path: '/document', name: t('document'), key: 19, icon: 'fa-file' },
    { path: '/contact', name: t('contact'), key: 14, icon: 'fa-phone' },
    {
      path: '/login',
      name: t('login').toUpperCase(),
      key: 15,
      icon: 'fa-sign-in-alt',
    },
    {
      path: '/register/student',
      name: t('reg_student').toUpperCase(),
      key: 16,
      icon: 'fa-user-graduate',
    },
    {
      path: '/register/trainer',
      name: t('reg_trainer').toUpperCase(),
      key: 17,
      icon: 'fa-chalkboard-teacher',
    },
    {
      path: '/',
      name: t('language').toUpperCase(),
      key: 18,
      icon: 'fa-language',
    },
  ];
  const siteMenuItemsOnline = [
    { path: '/', name: t('home'), key: 22, icon: 'fa-home' },
    {
      path: '/about',
      name: t('about'),
      key: 11,
      icon: 'fa-exclamation-circle',
    },
    {
      path: '/theoryTestForm',
      name: t('theory_test_form'),
      key: 12,
      icon: 'fa-file-alt',
    },
    {
      path: '/special-offer',
      name: t('special_offer'),
      key: 20,
      icon: 'fa-file-alt',
    },

    { path: '/gallery', name: t('gallary'), key: 13, icon: 'fa-images' },
    { path: '/document', name: t('document'), key: 19, icon: 'fa-file' },
    { path: '/contact', name: t('contact'), key: 14, icon: 'fa-phone' },

    {
      path: '/',
      name: t('language'),
      key: 188,
      icon: 'fa-language',
    },
  ];
  const menuItems = [
    {
      path: '/dashboard',
      name: t('dashboard'),
      key: 0,
      icon: 'fa-tachometer-alt',
    },
    {
      path: '/update-profile',
      name: t('profile_update'),
      icon: 'fa-user-edit',
      key: 1,
    },
    {
      path: '/remarks',
      name: t('remarks'),
      icon: 'fa-file',
      key: 19,
    },

    // {
    //   path: '/document',
    //   name: 'Document',
    //   icon: 'fa-file',
    //   key: 2,
    // },
    {
      path: '/transaction-history',
      name: t('transaction_history'),
      icon: 'fa-history',
      key: 3,
    },
    {
      path: '/chat',
      name: t('chats'),
      icon: 'fa-comment-dots',
      key: 18,
    },
    // {
    //   path: '/course-history',
    //   name: 'Course History',
    //   icon: 'fa-file-alt',
    //   key: 3,
    // },
    { path: '/test-history', name: t('test_history'), icon: 'fa-edit', key: 4 },
    // { path: '/notifications', name: 'Notification', icon: 'fa-bell', key: 5 },
    {
      path: '/schedule-test',
      name: 'Schedule Test',
      icon: 'fa-file-alt',
      key: 99,
    },
    {
      path: '/ratings',
      name: t('ratings'),
      icon: 'fa-star-half-alt',
      key: 9,
    },
    {
      path: '/my-bookings',
      name: t('my_bookings'),
      icon: 'fa-bookmark',
      key: 7,
    },
    {
      path: '/terms-conditions',
      name: t('terms_condition'),
      icon: 'fa-comment-dots',
      key: 10,
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy',
      icon: 'fa-comment-dots',
      key: 11,
    },
    { path: '/logout', name: t('logout'), icon: 'fa-sign-out-alt', key: 8 },
  ];

  const history = useHistory();
  const { accessToken } = useSelector(state => state.studentLogin);
  const dispatch = useDispatch();
  return (
    <StyledSideNav>
      {!showSiteNavTop && !accessToken
        ? siteMenuItems.map(item => (
            <NavItem
              key={item.key}
              className={activeItem === item.key ? 'active' : ''}
            >
              <LinkButton
                variant="link"
                href={item.path}
                onClick={() => setActiveItem(item.key)}
                style={st}
              >
                <i className={`fas ${item.icon}`}></i>
                <p style={{ marginBottom: -1 }}>{item.name}</p>
              </LinkButton>
            </NavItem>
          ))
        : !showSiteNavTop && accessToken
        ? siteMenuItemsOnline.map(item => (
            <NavItem
              key={item.key}
              className={activeItem === item.key ? 'active' : ''}
            >
              <LinkButton
                variant="link"
                href={item.path}
                onClick={() => setActiveItem(item.key)}
                style={st}
              >
                <i className={`fas ${item.icon}`}></i>
                <p style={{ marginBottom: -1 }}>{item.name}</p>
              </LinkButton>
            </NavItem>
          ))
        : null}
      {accessToken
        ? menuItems.map(item => (
            <NavItem
              key={item.key}
              className={activeItem === item.key ? 'active' : ''}
            >
              <LinkButton
                style={st}
                variant={'link'}
                href={item.name === 'Logout' ? '' : item.path}
                onClick={
                  item.name === 'Logout'
                    ? () => {
                        dispatch(studentLogout());
                        history.push('/login');
                      }
                    : () => setActiveItem(item.key)
                }
              >
                <i className={`fas ${item.icon}`}></i>
                <p style={{ marginBottom: -1 }}>{item.name}</p>
              </LinkButton>
            </NavItem>
          ))
        : null}
    </StyledSideNav>
  );
}

import React, { useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { BASE_URL_V2, imageChecker } from '../../../../store';
import { Link, useLocation } from 'react-router-dom';
import CourseCategories from '../homeSections/CourseCategories';
import { useTranslation } from 'react-i18next';
const StyledButtons = styled(Button)`
  width: 300px;
  height: 65px;
  font-size: 1.6rem;
  font-weight: 700;
  margin-top:10px
`;

const StyledCategoryCard = styled('div')`
  margin-top: 2rem;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  color: #fff;
  background-color: #2c2e94;
  height: 200px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  transition: all 0.15s ease-in-out;

  :hover {
    background-color: #fc8200;
  }
`;

const categoryCardStyles = {
  marginTop: '2rem',
  textAlign: 'center',
  padding: '1rem',
  cursor: 'pointer',
  color: '#fff',
  height: '200px',
  width: '220px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10%',
};

export default function Dashboard() {
  const {t} = useTranslation()
  const courses = [
    {
      title: 'Driving Course',
      description: 'Driving happens',
      icon: 'fas fa-car-side',
    },

    {
      title: 'Learning Driving',
      description: 'Driving Happens',
      icon: 'fas fa-car',
    },
    {
      title: 'Learning Course',
      description: 'Automatic Learning',
      icon: 'fas fa-car-side',
    },
    {
      title: 'Learning Driving',
      description: 'Driving Happens',
      icon: 'fas fa-truck',
    },
    {
      title: 'Driving Course',
      description: 'Driving happens',
      icon: 'fas fa-car-side',
    },

    {
      title: 'Learning Driving',
      description: 'Driving Happens',
      icon: 'fas fa-car',
    },
    {
      title: 'Learning Course',
      description: 'Automatic Learning',
      icon: 'fas fa-car-side',
    },
    {
      title: 'Learning Driving',
      description: 'Driving Happens',
      icon: 'fas fa-truck',
    },
  ];

  const { loading, firstName, lastName, image, error } = useSelector(
    state => state.studentDetails,
  );

  const { studentId } = useSelector(state => state.studentLogin);

  // useEffect(() => {
  //   if (studentId) {
  //     dispatch(getStudentDetails(studentId));
  //   }
  // }, [studentId]);

  return (
    <Container style={{ minHeight: '80vh' }}>
      {!loading && (
        <Row style={{ margin: '4rem 0 1rem 0 ' }}>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1 style={{ textTransform: 'capitalize', margin: '0 2rem' }}>
              {`${firstName} ${lastName}`}
            </h1>
            <img
              style={{ height: '100px', borderRadius: '50%' }}
              src={(image)}
              alt="Student"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} sm={12}>
          <h1 style={{ margin: '5% 0 10% 0 ', textAlign: 'center' }}>
            {t('welcome_to_your_dashboard')}
          </h1>
        </Col>
        <Col xs={12} sm={12} style={{ margin: '2.5rem 0' }}>
          <Row>
            <Col style={{ textAlign: 'center' }}>
            <StyledButtons
                // onClick={() => (window.location = '/theoryTestForm')}
              >
              <Link
                className="align-middle text-center"
                to={{
                  pathname: `/courses`,
                  state: {
                    id: null,
                  },
                }}
                style={{padding:'10px 90px',color:'white',textDecoration:'none'}}
              >
                {t('course')}
              </Link>
             </StyledButtons>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <StyledButtons
                onClick={() => (window.location = '/theoryTestForm')}
              >
                {t('test')}
              </StyledButtons>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <StyledButtons
                onClick={() => (window.location = '/special-offer')}
              >
                   {t('offer')}
              </StyledButtons>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} >
          <CourseCategories />
        </Col>
      </Row>
    </Container>
  );
}

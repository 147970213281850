import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getBookedCourses } from 'stateManagement/actions/studentActions';
import { imageChecker } from 'store';
import { defineValue } from 'store';

const TransactionCard = styled(Card)`
  h3,
  p {
    text-align: left;
  }
  height: 200px;
  &:hover {
    cursor: pointer;
  }
`;

const MenuItems = styled.h4`
  color: #fc8200;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: #2c2e94;
  }
  &.active-item {
    color: #2c2e94;
  }
`;

export default function CourseHistory() {
  const [filter, setFilter] = useState(null);

  const [filteredCourses, setFilteredCourses] = useState([]);

  const dispatch = useDispatch();

  const { studentId } = useSelector(state => state.studentLogin);
  const { loading, error, bookedCourses } = useSelector(
    state => state.getBookedCourses,
  );

  // function to filter the courses by status.
  function filterCourses(status) {
    
    if (bookedCourses) {
      
      status === null
        ? setFilteredCourses(bookedCourses)
        : setFilteredCourses(
            bookedCourses.filter(course => course.status === status),
          );
    }
  }

  // getting courses on page load and everytime user is changed.
  useEffect(() => {
    dispatch(getBookedCourses(studentId));
  }, [studentId]);

  // for filtering the courses everytime the filter is changed.
  useEffect(() => {
    filterCourses(filter);
  }, [filter]);

  // setting filtered courses to bookedCourses after it has loaded. for first intitial display with no filter selected. as filteredCourses is the array being rendered.
  useEffect(() => {
    if (!loading && bookedCourses && bookedCourses.length > 0) {
      const courses = [];

      setFilteredCourses(bookedCourses);
    }
  }, [bookedCourses]);

  return (
    <Container style={{ marginTop: '5%' }}>
      <Row className="justify-content-center">
        <Col sm={12} xs={12}>
          <h2 style={{ textAlign: 'center' }}>Courses History</h2>
        </Col>
        <Col sm={8} className="mt-5">
          <Row className="justify-content-center">
            <Col>
              <MenuItems
                className={filter === null ? 'active-item' : ''}
                onClick={() => {
                  setFilter(null);
                }}
              >
                All
              </MenuItems>
            </Col>
            <Col>
              <MenuItems
                className={filter === 'done' ? 'active-item' : ''}
                onClick={() => {
                  setFilter('done');
                }}
              >
                Done
              </MenuItems>
            </Col>
            <Col>
              <MenuItems
                className={filter === 'dropped' ? 'active-item' : ''}
                onClick={() => {
                  setFilter('dropped');
                }}
              >
                Drop
              </MenuItems>
            </Col>
          </Row>
        </Col>
        <Col xs={8} sm={8} className="mt-5">
          {filteredCourses &&
            filteredCourses.map(course => (
              <a
                href={`/courses/${course.id}`}
                key={course.id}
                style={{ textDecoration: 'none', color: '#000000' }}
              >
                <TransactionCard className="mb-3">
                  <Card.Body>
                    <Row style={{ height: '100%' }}>
                      <Col xs={6} sm={4}>
                        <img
                          src={(course.course.categoryDetail.imagePath)}
                          alt="course"
                          width="150px"
                          height="150px"
                        />
                      </Col>
                      <Col xs={6} sm={8}>
                        <div>
                          <h4 className="mb-4">{course.course.nameEnglish}</h4>
                          <p style={{ margin: '0.25rem 0' }}>
                            <strong>Instructor:</strong>{' '}
                            {defineValue(course.course.trainerDetail?.firstName +
                              ' ' +
                              course.course.trainerDetail?.lastName)}
                          </p>
                          <p style={{ margin: '0.25rem 0' }}>
                            <strong>Status: </strong> {course.status}
                          </p>
                          <p>
                            <strong>Booked On: </strong>{' '}
                            {new Date(course.createdAt).toLocaleDateString(
                              'en-GB',
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </TransactionCard>
              </a>
            ))}
        </Col>
      </Row>
    </Container>
  );
}

// const [courses, setCourses] = useState([
// {
//   id: '1',
//   title: 'Car Driving',
//   img: course1,
//   location: 'A-1 street 3',
//   status: 'pending',
//   instructor: 'Mr. Shafiq',
// },
// {
//   id: '2',
//   title: 'Golden Driving',
//   img: course1,
//   location: 'A-2 street 3',
//   status: 'dropped',
//   instructor: 'Mr. Asad',
// },
// {
//   id: '3',
//   title: 'Golden Driving',
//   img: course1,
//   location: 'A-2 street 3',
//   status: 'done',
//   instructor: 'Mr. Shabir',
// },
// ]);
// const [coursesArr, setCoursesArr] = useState([
//   {
//     id: '1',
//     title: 'Car Driving',
//     img:
//       'https://dummyimage.com/300X300/f77a13/ffffff.jpg&text=Dummy+Course+Image',
//     location: 'A-1 street 3',
//     status: 'pending',
//     instructor: 'Mr. Shafiq',
//   },
//   {
//     id: '2',
//     title: 'Golden Driving',
//     img:
//       'https://dummyimage.com/300X300/f77a13/ffffff.jpg&text=Dummy+Course+Image',
//     location: 'A-2 street 3',
//     status: 'dropped',
//     instructor: 'Mr. Asad',
//   },
//   {
//     id: '3',
//     title: 'Golden Driving',
//     img:
//       'https://dummyimage.com/300X300/f77a13/ffffff.jpg&text=Dummy+Course+Image',
//     location: 'A-2 street 3',
//     status: 'done',
//     instructor: 'Mr. Shabir',
//   },
// ]);

{
  /* {new Date(course.createdAt)
                              .toDateString()
                              .split(' ')
                              .slice(1)
                              .join(' ')} */
}

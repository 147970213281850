import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { useDispatch, useSelector } from 'react-redux';
import { studentLogin } from '../../../stateManagement/actions/studentActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useTranslation } from 'react-i18next';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { BASE_API_URL_V2 } from 'store';
import axios from 'axios';
export default function Login() {
  const history = useHistory();
  const [otp, setOtp] = useState(null);
  const [state, setState] = useState({
    email: '',
    password: '',
  });
  const { t } = useTranslation();
  const [passwordHidden, setPasswordHidden] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const navigateToVerify = () => {
    // pathname: '/register/student',
    // showOnlyOTP: true,
    // email: state?.email,
    history.push({ pathname: 'register/verifyotp', email: state?.email });
  };

  const dispatch = useDispatch();
  const { loading, accessToken, error } = useSelector(
    state => state.studentLogin,
  );

  const notify = message => toast(message);

  // redirecting if student is already logged in
  useEffect(() => {
    if (accessToken) {
      history.push('/');
    }
    if (error) {
      console.log(error);
      notify('Login Falied.');
    }
  }, [history, accessToken, error]);

  // function to handle state change in form fields.
  let handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleOtpChange = e => {
    setOtp(e.target.value);
  };
  console.log({ otp });

  // dispatching login action
  let loginWithEmail = e => {
    e.preventDefault();
    dispatch(studentLogin(state.email, state.password, navigateToVerify));
  };

  const verifyOTP = async () => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    let otpData = new FormData();
    otpData.append('email', state.email);
    otpData.append('otpCode', otp);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/emailVerified`,
      otpData,
      config,
    );
    console.log('otp response data', data);
    if (data.statusText === 'Success') {
      setModalShow(false);
      console.log(data);
    } else if (data.message.includes('Invalid')) {
      notify('Invalid OTP or Email');
      console.log(data.message);
    } else {
      notify(data.message);
    }
  };

  return (
    <div className="RA-13-login-overflow">
      <div className="container RA-13-login-cont">
        <div className="row">
          <div className="col-md-6 RA-13-login-bg RA-13-login-col6">
            <div className="RA-13-side-login">
              <h1>Welcome To</h1>
              <p>Civil Academy</p>
              <hr className="RA-13-hr" />
              <p>Login To Access the Dashboard</p>
            </div>
          </div>
          <div className="col-md-6 RA-13-login-col6 RA-13-login-pg">
            <Fade>
              <div className="card" style={{ border: 'none' }}>
                <div className="row mb-4">
                  <div className="col">
                    <h1 className="text-center text-theme-secondary RA-13-fnt-1">
                      {t('login')}
                    </h1>
                    <div className="divider"></div>
                  </div>
                </div>

                <form>
                  <div className="form-row">
                    <div className="col form-group">
                      <input
                        required
                        type="email"
                        name="email"
                        placeholder={t('email')}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-row" style={{ marginBottom: 30 }}>
                    <div className="col form-group">
                      {/* <p style={{marginBottom:2,fontWeight:600}}>New Password</p> */}
                      <input
                        required
                        type={!passwordHidden ? 'password' : 'text'}
                        name="password"
                        placeholder={t('password')}
                        className="form-control"
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          alignSelf: 'flex-end',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: -25,
                          marginLeft: -10,
                          height: 1,
                        }}
                      >
                        <i
                          className={`fas ${
                            passwordHidden ? 'fa-eye' : 'fa-eye-slash'
                          }`}
                          style={{ color: 'black' }}
                          onClick={() => {
                            setPasswordHidden(!passwordHidden);
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="form-row mt-2">
                    <div className="col text-center">
                      <button
                        className="btn w-100 mb-3 RA-13-lgn-btn"
                        onClick={loginWithEmail}
                      >
                        {!loading ? (
                          <span>{t('login')}</span>
                        ) : (
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                          ></span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>

                <div className="row mt-2">
                  <div className="col-7">
                    <p className="mb-0 RA-13-fnt-size">{t('dont_account')}</p>
                  </div>
                  <div className="col-5 text-right">
                    <p className="mb-0 RA-13-fnt-size">
                      {t('forget_password')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-7">
                    <Link className="RA-13-fnt-size" to="/register/trainer">
                      {t('reg_trainer')}
                    </Link>
                  </div>
                  <div className="col-5 text-right">
                    <Link className="RA-13-fnt-size" to="/login/reset-password">
                      {t('click_here')}
                    </Link>
                  </div>
                  <div className="col-7">
                    <Link className="RA-13-fnt-size" to="/register/student">
                      {t('reg_student')}
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {modalShow ? (
        <>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Varify OTP
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <div className="col form-group">
                  <input
                    required
                    value={state.email}
                    name="email"
                    placeholder="email"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
                <Form.Label>OTP</Form.Label>
                <div className="col form-group">
                  <input
                    required
                    value={otp}
                    name="otp"
                    placeholder="OTP"
                    className="form-control"
                    onChange={handleOtpChange}
                  />
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setModalShow(false)}>Close</Button>
              <Button onClick={() => verifyOTP()}>Verify</Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import ContactDetails from './contactUsSections/ContactDetails';
import Map from './contactUsSections/Map';
import ContactForm from '../../../common/ContactForm';
import AddressDetails from './contactUsSections/AddressDetails';
import Phone from './contactUsSections/Phone';
import Footer from 'components/Footer';
import { get } from 'services/RestService'
export default function ContactUs() {
 
  const [loading, setLoading] = useState(true);
  const [contactData, setContactData] = useState(null);

  // function for getting contact data.
  async function getContactData() {
    setLoading(true);
    get('/student/getContact').then((data) => {
      setContactData(data);
      console.log(data)
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.log(err)
    })
  }

  // fetching contact data from api.
  useEffect(() => {
    getContactData();
  }, []);
  return (
    <>
    
      <ContactDetails data={contactData} />
      <AddressDetails data={contactData} />
      <Phone data={contactData} />
      <Map />
      <ContactForm />
      <Footer />
    </>
  );
}

import React, { useState, useEffect } from 'react'
import { get } from '../../../../services/RestService'
import { useSelector } from 'react-redux'

const Remarks = () => {
  const [remarks, setRemarks] = useState([])
  const { studentId } = useSelector(state => state.studentLogin)
  const [loading, setLoading] = useState(false)

  const getData = () => {
    setLoading(true)
    get(`/student/getStudent?id=${studentId}`).then(data => {
      setRemarks(data?.remarks)
      setLoading(false)
    }).catch(error => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    loading ? <i style={{ marginLeft: '1.5rem' }} className="fa fa-spin fa-spinner" aria-hidden="true"></i> :
    <div style={{ padding: 30}}>
      <h1 style={{ marginBottom: 50}}>Admin Remarks</h1>
        <div style={{ padding: 20}}>
          {remarks && remarks.length === 0 && <h5>No Remarks Yet</h5>}
          {remarks.map((x, i)=> (
            <div style={{ marginBottom: 10}}>
              <h5>{x.remark}</h5>
              <p>On: {x.date}</p>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Remarks

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { axiosClient } from 'helper';
import { BASE_API_URL_V2, BASE_URL_V2, defineValue, defineDate } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import ReactStars from 'react-rating-stars-component';
import axios from 'axios';
import { getBookedCourses } from '../../../../stateManagement/actions/studentActions';
import { Form, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
function MyVerticallyCenteredModal(props) {
  const langRedux = useSelector(state => state.language);

  const { bookedCourses } = useSelector(state => state.getBookedCourses);
  const { t } = useTranslation();
  const { studentId, accessToken, userId } = useSelector(
    state => state.studentLogin,
  );
  const [rate, setRate] = useState(0);
  const [loading, setLoading] = useState(false);
  const FormGroup = styled(Form.Group)`
    margin-bottom: 10px;
  `;
  console.log(bookedCourses);
  const ratingChanged = newRating => {
    setRate(newRating);
  };
  const schema = yup.object({
    // fullName: yup.string().required(),
    category: yup.string().required(),
    course: yup.string().required(),
    Feedback: yup.string().required(),
  });

  const onSubmit = values => {
    var data = new FormData();
    if (rate && rate >= 1) {
      data.append('courseId', values.course);
      data.append('studentId', userId);
      data.append('feedback', values.Feedback);
      data.append('ratings', rate);

      // console.log(data);
      postDate(data);
    } else
      props.letErrorMessage({
        alert: true,
        book: false,
        error: 'Fill the star',
      });
  };
  // Getting the reviews and setting them in local component state.
  async function postDate(value) {
    const c = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    setLoading(true);
    try {
      const { data, config } = await axios.post(
        `${BASE_API_URL_V2}/student/createReview`,
        value,
        c,
      );

      if (data.status) {
        console.log('r');
        setLoading(false);
        props.letErrorMessage({ alert: true, book: true, error: 'hi' });
        // setShowAlert(true);
      }
      // if the status is false which means booking unsuccessful.
      else if (data.status === false) {
        console.log('2', data, config);
        setLoading(false);
        props.letErrorMessage({ alert: true, book: false, error: data.error });
      } else {
        setLoading(false);
        console.log(data);
        props.letErrorMessage({ alert: true, book: false, error: data.error });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('add_review')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={schema}
          // handles submit for the initial form, resets the form and redirects to the test.
          // onSubmit={(values, { resetForm }) => {
          //   resetForm({});
          //   history.push(`/theoryTest/${values.course}`);
          // }}
          initialValues={{
            // fullName: '',
            category: '',
            // this course value should be the test Id.
            course: '',
            Feedback: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                onSubmit(values);
              }}
            >
              <Container>
                <Row
                  className="justify-content-center align-items-top"
                  style={{ padding: '60px 0px' }}
                >
                  <Col xs={12} sm={8}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 20,
                        marginTop: -50,
                      }}
                    >
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={40}
                        activeColor="#ffd700"
                      />
                    </div>

                    <FormGroup>
                      <Form.Control
                        name="course"
                        as="select"
                        value={values.course}
                        onChange={handleChange}
                        isInvalid={!!errors.course}
                        custom
                      >
                        <option value="">{t('select_course')}</option>
                        {bookedCourses !== null &&
                          bookedCourses.map(course => (
                            <option
                              key={course._id}
                              value={defineValue(course.course._id)}
                            >
                              {defineValue(
                                course.course?.[
                                  langRedux.language === 'en'
                                    ? 'nameEnglish'
                                    : 'nameArabic'
                                ],
                              )}
                            </option>
                          ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.course}
                      </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup>
                      <Form.Control
                        name="Feedback"
                        as="textarea"
                        rows={3}
                        placeholder={t('feedback')}
                        value={values.Feedback}
                        onChange={handleChange}
                        isInvalid={!!errors.Feedback}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.Feedback}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <Button type="submit" style={{ width: '100%' }}>
                      {t('submit')}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const CustomBody = styled(Card.Body)`
  p {
    margin: 0px;
  }
`;

export default function RatingsHistory() {
  const langRedux = useSelector(state => state.language);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { studentId, userId } = useSelector(state => state.studentLogin);
  const { bookedCourses } = useSelector(state => state.getBookedCourses);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // getCourses();
    dispatch(getBookedCourses(userId));
    setLoading(false);
  }, [studentId]);

  const [modalShow, setModalShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [bookingStatus, setBookingStatus] = useState(false);
  const [ratings, setRatings] = useState(null);
  const [reviewed, setReviewed] = useState(false);

  // Getting the reviews and setting them in local component state.
  async function getRatings(studentId) {
    setLoading(true);
    try {
      const { data } = await axiosClient().get(
        `${BASE_API_URL_V2}/student/getReviews`,
        {
          params: {
            userId: studentId,
          },
        },
      );
      console.log(data, 'review');
      if (data.status === false) {
        setRatings([]);
        setLoading(false);
      } else {
        setRatings(data);
        setLoading(false);
      }
      // const location = await JSON.parse(data[0].course.locations);
      // console.log(location);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // function for showing number of stars passed as parameter.
  const ratingStars = rating => {
    const Star = <i className="fas fa-star stars"></i>;
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(Star);
    }
    return stars;
  };
  const letErrorMessage = data => {
    if (data.alert === true) {
      if (data.book === false) {
        setBookingStatus(false);
      } else if (data.book === true) {
        setBookingStatus(true);
        setModalShow(false);
        setReviewed(true);
      }
      setErrorMessage(data.error);
      setShowAlert(true);
    }
  };

  // fetching the ratings asynchronously
  useEffect(() => {
    getRatings(userId);
  }, [userId, reviewed]);

  return (
    <Container style={{ marginTop: '3%' }}>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        letErrorMessage={letErrorMessage}
      />
      {showAlert ? (
        <Alert
          style={{
            position: 'fixed',
            top: '15%',
            left: '10%',
            zIndex: 5000,
            width: '80%',
            alignContent: 'center',
          }}
          variant={bookingStatus === true ? 'success' : 'danger'}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {bookingStatus === true ? (
            <>
              <Alert.Heading>Review Created Successfully</Alert.Heading>
              <p>Your review has been created.</p>
            </>
          ) : (
            <>
              <Alert.Heading>Review Creation Failed</Alert.Heading>
              <p> {errorMessage} </p>
            </>
          )}
        </Alert>
      ) : null}
      <Row className="justify-content-center">
        <Col
          xs={12}
          sm={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>{t('ratings')}</h2>
          {bookedCourses?.length === 0 ? null : (
            <a
              style={{
                background: 'orange',
                borderRadius: 50,
                width: 30,
                height: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: -1,
                cursor: 'pointer',
                marginLeft: 10,
              }}
              onClick={() => setModalShow(true)}
            >
              <i
                className="fa fa-plus"
                style={{ color: 'white', fontSize: 20 }}
              ></i>
            </a>
          )}
        </Col>
        <Col xs={12} sm={12}>
          <p style={{ textAlign: 'center', marginBottom: '5%' }}>
            <b>{t('preview_hist_rating')}</b>
          </p>
        </Col>
      </Row>
      <Row xs={12}>
        {!loading && ratings !== null && ratings?.length >= 1 ? (
          ratings.map(rating => (
            <Col
              className="ratings-card-container"
              xs={12}
              sm={10}
              md={6}
              key={rating._id}
            >
              <Card className="ratings-card">
                <CustomBody>
                  <Row>
                    <Col className="ratings-image" xs={12} sm={4}>
                      <img src={rating?.course?.imagesPath[0]} />
                    </Col>
                    <Col xs={12} sm={8}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h4>
                          {defineValue(
                            rating.course?.[
                              langRedux.language === 'en'
                                ? 'nameEnglish'
                                : 'nameArabic'
                            ],
                          )}
                        </h4>

                        <p>
                          {' '}
                          <strong>{t('your_rating')}: </strong>{' '}
                          {defineDate(rating.createdAt)}
                        </p>
                        <p>
                          <strong>{t('reviewed_on')}: </strong>
                          {ratingStars(rating.ratings).map((star, index) => (
                            <i style={{ color: '#fc8200' }} key={index}>
                              {star}
                            </i>
                          ))}
                        </p>
                        <p>
                          <strong>{t('your_review')}:</strong>{' '}
                          {defineValue(rating.feedback)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CustomBody>
              </Card>
            </Col>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <h2 style={{ textAlign: 'center', marginTop: 10, width: '80%' }}>
              {t('no_review_yet')}
            </h2>
          </div>
        )}
      </Row>
    </Container>
  );
}

import React from 'react';
import HomeMain from './homeSections/HomeMain';
import ContactForm from '../../../common/ContactForm';
import About from './homeSections/About';
import Features from './homeSections/Features';
import Counters from './homeSections/Counters';
import CourseCategories from './homeSections/CourseCategories';
import Clients from './homeSections/Clients';
import FreeTest from './homeSections/FreeTest';
import CoursePromotions from './homeSections/CoursePromotions';
import Footer from 'components/Footer';
import { Link } from 'react-router-dom';
export default function Home() {
  return (
    <>
      <HomeMain />
      <About />
      <Features />
      <Counters />
      <CoursePromotions home={true}/>
      <CourseCategories />
      <Clients />
      <FreeTest />
      <ContactForm />
      <Footer />
    </>
  );
}

import React from 'react';
import { Switch, Route } from 'react-router-dom';

// components
import Login from './Login';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ResetPassword from './ResetPassword';

function RoutesLogin() {
  return (
    <>
      <Header />

      <Switch>
        <Route path="/login/reset-password" component={ResetPassword} />
        <Route path="/login" component={Login} />
      </Switch>
      <Footer />
    </>
  );
}

export default RoutesLogin;

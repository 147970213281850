import React, { useState, useEffect } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import PersonalRegistration from './PersonalRegistration';
import CompanyRegistration from './CompanyRegistration';
import DocumentRegistration from './DocumentsRegistration';
import AccountRegistration from './AccountRegistration';
import Pattern from '../../../../assets/images/car.jpg';
import Logo from '../../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Verification from './Verification';
const BackButton = styled(Link)`
  color: #fc8200;
`;

export default function Index() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const menuItems = [
    { id: 'step1', label: t('personal') },
    { id: 'step2', label: t('company') },
    { id: 'step3', label: t('documents') },
    { id: 'step4', label: t('Account') },
    { id: 'step5', label: t('verification') },
  ];
  const [initData, setInitData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    nationality: '',
    residence: '',
    phone: '',
    gender: '',
    email: '',
    companyName: '',
    yearsExperience: '',

    tradeLicense: undefined,
    certificate: undefined,
    emiratesId: undefined,
    companyLogo: undefined,
    trnCertificate: undefined,
  });

  // useEffect setActiveStep(5)

  useEffect(() => {
    console.log(initData);
  }, [initData]);
  const handleChange = e => {
    setInitData({
      ...initData,
      [e.target.name]: e.target.value,
    });
  };
  const showForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <PersonalRegistration
            change={setInitData}
            data={initData}
            setStep={setActiveStep}
          />
        );
      case 1:
        return (
          <CompanyRegistration
            change={setInitData}
            data={initData}
            setStep={setActiveStep}
          />
        );
      case 2:
        return (
          <DocumentRegistration
            change={setInitData}
            data={initData}
            setStep={setActiveStep}
          />
        );
      case 3:
        return (
          <AccountRegistration
            data={initData}
            setEmail={setEmail}
            setStep={setActiveStep}
          />
        );
      case 4:
        return <Verification data={email} />;
      default:
        return null;
    }
  };

  return (
    <div className="row register" style={{ height: '100vh', width: '100%' }}>
      <div
        className="col-xs-0 col-sm-0 col-lg-5 "
        style={{
          backgroundImage: `url(${Pattern})`,
          objectFit: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
        }}
      ></div>
      <div
        className="col-xs-12 col-sm-12 col-lg-7"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="col-12 text-center">
            <h3 className="title">{t('trainer_registration')}</h3>
          </div>
          <div className="col-12 text-center">
            <Stepper activeStep={activeStep} onChange={showForm}>
              {menuItems.map((item, index) => (
                <Step
                  key={item.id}
                  label={item.label}
                  onClick={() => {
                    setActiveStep(index);
                  }}
                />
              ))}
            </Stepper>
          </div>
        </div>
        <div className="row justify-content-center" style={{ height: 'auto' }}>
          <div className="col-8 mb-3">
            <BackButton to="/">
              <i className="fas fa-home mr-2"></i>
              {t('home')}
            </BackButton>
          </div>
          <div className="card col-10 col-md-8 mb-5">
            <div className="card-body ">
              <div className="row">{showForm()}</div>
              <div className="row">
                {activeStep === 0 ? null : (
                  <div className="col-6 text-left">
                    <button
                      style={{ display: activeStep && 'none' }}
                      className="btn btn-secondary"
                      onClick={() => {
                        let step = activeStep;
                        if (step > 0) setActiveStep(--step);
                      }}
                    >
                      <i className="fas fa-arrow-left"></i> {t('back')}
                    </button>
                  </div>
                )}
                <div
                  className={
                    activeStep === 0 ? 'col-12 text-center' : 'col-6 text-right'
                  }
                >
                  {/* <button
                    className="btn btn-primary"
                    type="submit"
                    form="reg-form"
                  >
                    {activeStep === menuItems.length - 1
                      ? 'Submit '
                      : 'Save & Continue '}
                    <i className="fas fa-arrow-right"></i>
                  </button> */}
                  {activeStep === menuItems.length - 1 ? (
                    <button
                      style={{ display: activeStep && 'none' }}
                      className="btn btn-primary"
                      type="submit"
                      form="reg-form"
                      // onClick={() => alert('Clicked')}
                    >
                      {t('submit')}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      form="reg-form"
                    >
                      {t('save_continue')}{' '}
                      <i className="fas fa-arrow-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

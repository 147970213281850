import axios from 'axios';
import Cookie from 'cookie-universal';
import { BASE_API_URL,BASE_API_URL_V2 } from 'store';
import { useLocation } from "react-router"

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search)
}

const cookies = Cookie();
//export const BASE_URL = "http://api.beelicensed.com/";
// export const BASE_URL = "http://972ec512e31f.ngrok.io";
// export const BASE_URL = "https://mazcoaching.com/new/public/";

export function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_API_URL_V2,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      // accept: 'application/json',
    },
  };

  let instance = axios.create(defaultOptions);

  // Setting the Auth token for every request
  instance.defaults.headers.common['Authorization'] = `Bearer ${cookies.get(
    'accessToken',
  )}`;

  // Set the AUTH token for any request
  // instance.interceptors.request.use(function(config) {
  //   const token = localStorage.getItem('token');
  //   // config.headers.Authorization = cookies.get("accessToken");
  //   config.headers.common = { Authorization: `${cookies.get('accessToken')}` };
  //   return config;
  // });

  return instance;
}

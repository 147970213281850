import { axiosClient } from '../../helper';
import {
  STUDENT_LOGIN_REQUEST,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGIN_FAIL,
  STUDENT_LOGOUT,
  // STUDENT_REGISTER_REQUEST,
  // STUDENT_REGISTER_FAIL,
  // STUDENT_REGISTER_SUCCESS,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  GET_STUDENT_DETAILS_REQUEST,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_STUDENT_DETAILS_FAIL,
  STUDENT_DETAILS_RESET,
  GET_COURSE_DETAILS_REQUEST,
  GET_COURSE_DETAILS_SUCCESS,
  GET_COURSE_DETAILS_FAIL,
  GET_BOOKED_COURSES_REQUEST,
  GET_BOOKED_COURSES_SUCCESS,
  GET_BOOKED_COURSES_FAIL,
  GET_COURSES_RESET,
  GET_COURSE_DETAILS_RESET,
  CHANGE_LANG,
} from '../constants/studentConstants';
import { BASE_API_URL_V2, BASE_URL_V2 } from '../../store';
import Cookie from 'cookie-universal';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const cookies = Cookie();
// Student Login
export const studentLogin = (
  email,
  password,
  navigateToVerify,
) => async dispatch => {
  console.log(email, password);

  try {
    console.log(email, password);
    dispatch({
      type: STUDENT_LOGIN_REQUEST,
    });
    var obj = new FormData();
    obj.append('email', email);
    obj.append('password', password);
    const { data } = await axiosClient().post('/student/studentLogin', obj);
    // setting the accesstoken in cookies
    console.log('llgin response data', data);

    if (data?.error?.includes('Verify')) {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      let otpData = new FormData();
      otpData.append('email', email);
      const { data } = await axios.post(
        `${BASE_API_URL_V2}/student/sendStudentOtpEmail`,
        otpData,
        config,
      );
      console.log('otp response data', data);
      if (data.statusText === 'Success') {
        navigateToVerify();
        console.log(data);
      } else {
        // window.alert(data.message);
        console.log('inside verigy', data.message);
      }
    } else if (data?.success) {
      cookies.set('accessToken', data.student_login_token);

      dispatch({
        type: STUDENT_LOGIN_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: STUDENT_LOGIN_FAIL,
        payload: data.message,
      });
      window.alert(data.error);
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: STUDENT_LOGIN_FAIL,
      payload: error.message,
    });
  }
};

// Get Student Details
export const getStudentDetails = userId => async dispatch => {
  try {
    dispatch({
      type: GET_STUDENT_DETAILS_REQUEST,
    });

    const { data } = await axiosClient().get('/student/getStudent', {
      params: {
        id: userId,
      },
    });

    dispatch({
      type: GET_STUDENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

// // Register New Student
// export const studentRegister = student => async dispatch => {
//   try {
//     dispatch({
//       type: STUDENT_REGISTER_REQUEST,
//     });

//     const { data } = await axios.post('/student/studentSignUp', student);

//     dispatch({
//       type: STUDENT_REGISTER_SUCCESS,
//       payload: data.student_login_token,
//     });
//   } catch (error) {
//     dispatch({
//       type: STUDENT_REGISTER_FAIL,
//       payload: error.message,
//     });
//   }
// };

// Logout of account
export const studentLogout = () => dispatch => {
  cookies.set('accessToken', null);
  dispatch({
    type: STUDENT_LOGOUT,
  });
  dispatch({
    type: STUDENT_DETAILS_RESET,
  });
  dispatch({
    type: GET_COURSES_RESET,
  });
  dispatch({
    type: GET_COURSE_DETAILS_RESET,
  });
};

// Get courses to show on register page.
export const getCourses = () => async dispatch => {
  try {
    dispatch({
      type: GET_COURSES_REQUEST,
    });

    const { data } = await axios.get(`${BASE_API_URL_V2}/student/getCourses`);
    dispatch({
      type: GET_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_COURSES_FAIL,
      payload: error.message,
    });
  }
};

// Get course details for single course by id
export const getCourseDetails = courseId => async dispatch => {
  console.log('yessssss', `/student/getCourse?id=${courseId}`);
  try {
    dispatch({
      type: GET_COURSE_DETAILS_REQUEST,
    });

    const { data } = await axiosClient().get(
      `/student/getCourse?id=${courseId}`,
    );
    console.log(data, 'yessssss', `/student/getCourse/id=${courseId}`);
    dispatch({
      type: GET_COURSE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_COURSE_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

// Get course details for single course by id
export const getBookedCourses = userId => async dispatch => {
  try {
    dispatch({
      type: GET_BOOKED_COURSES_REQUEST,
    });

    const data = await axiosClient().get('/student/getBookCourses', {
      params: {
        id: userId,
      },
    });
    console.log(data, 'resuult');
    dispatch({
      type: GET_BOOKED_COURSES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BOOKED_COURSES_FAIL,
      payload: error.message,
    });
  }
};

// Get course details for single course by id
export const getLanguage = lang => async dispatch => {
  dispatch({
    type: CHANGE_LANG,
    payload: lang,
  });
};

// for getting the categories.
export async function getCourseRating(id) {
  try {
    const { data, config } = await axios.get(
      `${BASE_API_URL_V2}/student/getCourseRating?courseId=${id}`,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
}

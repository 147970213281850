import React, { useState, useEffect } from 'react';
import { get } from 'services/RestService';
import SearchBar from './SearchBar';
import { BASE_API_URL_V2, BASE_URL_V2 } from './../../../../store';

// Images
import image1 from 'images/slider/slider1.jpg';
import image2 from 'images/slider/slider2.jpg';
import image3 from 'images/slider/slider3.jpg';

const data = [
  { image: image1, alt: 'Slider 1', className: 'active' },
  { image: image2, alt: 'Slider 2', className: '' },
  { image: image3, alt: 'Slider 3', className: '' },
];

export default function HomeMain() {
  const [images, setImages] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [promotionId, setPromotionId] = useState([]);
  const getData = () => {
    get('/admin/getHome')
      .then(data => {
        //setData(data)
        const allImages = [];
        data.sliderImagesPath.map((d, index) => {
          allImages.push({
            image: d,
            alt: 'slider',
            className: index === 0 ? 'active' : '',
          });
        });
        setImages(allImages);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getPromotions = () => {
    get(`/student/getPromotions`)
      .then(data => {
        console.log('Here is my Promotions data: ', data);
        const allPromotionImages = [];
        let sliderImage = [];
        data.map(promotionsData => {
          sliderImage.push(promotionsData.promotionImage + promotionsData._id);
          console.log('Here is my sliderImage: ', sliderImage);
        });
        sliderImage.map((d, index) => {
          console.log('Here is my d: ', d.split('.jpg')[1]);
          console.log('Here is my d: ', d.split('.jpg')[0]);

          let splitedId = d.split('.jpg')[1];
          let splitedImage = d.split('.jpg')[0];
          let finalImage = splitedImage + '.jpg';

          allPromotionImages.push({
            // promoteId: promotionId,
            image: finalImage,
            alt: splitedId,
            className: index === 0 ? 'active' : '',
          });
        });
        console.log('Here is my promotions images: ', sliderImage);

        setPromotions(allPromotionImages);
      })
      .catch(err => {
        console.log(err);
      });
  };

  function fetchpromotionId(promotionId) {
    window.location.href = `/bookCourse/${promotionId}?promotion`;
  }

  useEffect(() => {
    getData();
    getPromotions();
  }, []);

  return (
    <div className="homeMain">
      <div
        id="carouselWithIndicators"
        className="carousel slide"
        data-ride="carousel"
        data-interval="4000"
      >
        <div className="carousel-inner">
          {images.map((d, i) => {
            return (
              <>
                <div key={i} className={`carousel-item ${d.className}`}>
                  <div
                    style={{ backgroundImage: `url(${d.image})` }}
                    className="image"
                  ></div>
                </div>
              </>
            );
          })}
          <SearchBar />
        </div>

        {/* <div className="carousel-inner">
          {promotions.map((d, i) => {
            return (
              <>
                <div key={i} className={`carousel-item ${d.className}`}>
                  <div
                    style={{ backgroundImage: `url(${d.image})` }}
                    className="image"
                  ></div>
                  <button
                    className="RA-13-home-main RA-13-promotion-btn"
                    onClick={() => fetchpromotionId(d.alt)}
                  >
                    By Promotion
                  </button>
                </div>
              </>
            );
          })}
        </div> */}
      </div>
    </div>
  );
}

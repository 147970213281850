import React, { useEffect } from 'react';

import { Image, Nav, Button, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import Cookie from 'cookie-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStudentDetails,
  getLanguage,
} from '../stateManagement/actions/studentActions';
import { useTranslation } from 'react-i18next';

const cookies = Cookie();
const StyledNav = styled(Nav.Link)`
  color: #ffffff !important;
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
  }
  @media (max-width:1500px) and (min-width:1200px) {
    font-size: 0.7rem;
  }​
`;
const StyledUser = styled(Nav.Link)`
  color: #ffffff !important;
  display: flex;
  align-items: center;
  cursor:default !important;
  strong {
    margin-left: -1rem;
  }
  @media (max-width:1500px) and (min-width:1200px) {
    font-size: 0.7rem;
  }​
`;
const StyledUserIcon = styled(Image)`
  height: 1.5rem;
`;

const StyledNavDropdown = styled(NavDropdown)`
  display: flex;
  align-items: center;
  #basic-nav-dropdown {
    color: #ffffff !important;
  }
  @media (max-width:1500px) and (min-width:1200px) {
    font-size: 0.7rem;
  }​

`;

const CustomNavBar = styled(Nav)`
  .side-bar-menu {
    align-items: left;
    width: 20%;
  }
  .main-nav-bar {
    display: flex;
    width: 80%;
    justify-content: center;
  }
  .inner {
    display: flex;
    p {
      font-size: 15px;
    }
    @media (max-width:1600px)  {
      p {
        font-size: 13px;
      }
    }​
   
  }
`;

export default function NavBar(props) {
  const { hideSideBar, setHideSideBar, showSiteNavTop } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { loading, firstName, lastName, image, error } = useSelector(
    state => state.studentDetails,
  );

  const { studentId } = useSelector(state => state.studentLogin);
  const redux = useSelector(state => state);
  console.log(redux);
  useEffect(() => {
    if (studentId) {
      dispatch(getStudentDetails(studentId));
    }
  }, [studentId]);
  const changelang = lang => {
    dispatch(getLanguage(lang));
  };
  return (
    <CustomNavBar
      className="bg-theme-primary navBar"
      style={{
        width: '100%',
        position: 'fixed',
        zIndex: '999',
        height: 55,
        marginTop: -10,
        alignItems: showSiteNavTop ? 'center' : null,
        justifyContent: showSiteNavTop ? 'center' : null,
      }}
    >
      {firstName ? (
        <div className="side-bar-nav">
          <Nav.Link>
            <Button variant="link" onClick={() => setHideSideBar(!hideSideBar)}>
              <i className="fas fa-bars" style={{ color: '#ffffff' }}></i>
            </Button>
          </Nav.Link>
        </div>
      ) : !firstName && !showSiteNavTop ? (
        <div className="side-bar-nav">
          <Nav.Link>
            <Button variant="link" onClick={() => setHideSideBar(!hideSideBar)}>
              <i className="fas fa-bars" style={{ color: '#ffffff' }}></i>
            </Button>
          </Nav.Link>
        </div>
      ) : null}
      {showSiteNavTop ? (
        firstName ? (
          <div className="main-nav-bar">
            <div className="inner">
              <StyledNav href="/">
                <p>{t('home')}</p>
              </StyledNav>
              <StyledNav href="/about">
                <p>{t('about')}</p>
              </StyledNav>

              <StyledNav href="/theoryTestForm">
                <p>{t('thoery')}</p>
              </StyledNav>
              <StyledNav href="/special-offer">
                <p>{t('special_offer')}</p>
              </StyledNav>
              <StyledNav href="http://www.aacademy.ae/" target="_blank">
                <p>{t('elearning')}</p>
              </StyledNav>
              <StyledNav href="/gallery">
                <p>{t('gallary')}</p>
              </StyledNav>
              <StyledNav href="/document">
                <p>{t('document')}</p>
              </StyledNav>
              <StyledNav href="/contact">
                <p>{t('contact')}</p>
              </StyledNav>
              <StyledNavDropdown
                title={t('language')}
                style={{ fontSize: 14 }}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={() => changelang('en')}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changelang('ar')}>
                  Arabic
                </NavDropdown.Item>
              </StyledNavDropdown>
              <StyledUser>
                {/* <StyledUserIcon src={`${BASE_URL}${image}`} roundedCircle /> */}
                <StyledUserIcon src={image} roundedCircle />
              </StyledUser>
              <StyledUser>
                <strong>{`${t('hello')}, ${firstName} ${lastName}`}</strong>
              </StyledUser>
            </div>
          </div>
        ) : (
          <div className="main-nav-bar">
            <div className="inner">
              <StyledNav href="/">
                <p>{t('home')}</p>
              </StyledNav>
              <StyledNav href="/about">
                <p>{t('about')}</p>
              </StyledNav>

              <StyledNav href="/theoryTestForm">
                <p>{t('thoery')}</p>
              </StyledNav>
              <StyledNav href="/special-offer">
                <p>{t('special_offer')}</p>
              </StyledNav>
              <StyledNav href="http://www.aacademy.ae/" target="_blank">
                <p>{t('elearning')}</p>
              </StyledNav>
              <StyledNav href="/gallery">
                <p>{t('gallary')}</p>
              </StyledNav>
              <StyledNav href="/document">
                <p>{t('document')}</p>
              </StyledNav>
              <StyledNav href="/contact">
                <p>{t('contact')}</p>
              </StyledNav>
              {/* <StyledNav href="/login">
                <p>{t('login')}</p>
              </StyledNav> */}
              <StyledNavDropdown
                title={t('login')}
                style={{ fontSize: 14 }}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="https://trainer.civil.ae/#/">
                  {t('log_trainer')}
                </NavDropdown.Item>
                <NavDropdown.Item href="/login">
                  {t('log_student')}
                </NavDropdown.Item>
              </StyledNavDropdown>
              
              <StyledNavDropdown
                title={t('register')}
                style={{ fontSize: 14 }}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/register/trainer">
                  {t('reg_trainer')}
                </NavDropdown.Item>
                <NavDropdown.Item href="/register/student">
                  {t('reg_student')}
                </NavDropdown.Item>
              </StyledNavDropdown>
              <StyledNavDropdown
                title={t('language')}
                style={{ fontSize: 14 }}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={() => changelang('en')}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changelang('ar')}>
                  Arabic
                </NavDropdown.Item>
              </StyledNavDropdown>
            </div>
          </div>
        )
      ) : null}
    </CustomNavBar>
  );
}

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';
import OtpInput from 'react-otp-input';
import { Alert } from 'react-bootstrap';
import Countdown from 'react-countdown';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [passSuccess, setPassSuccess] = useState(false);
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [passwordHidden, setPasswordHidden] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMess, setSuccessMedd] = useState({
    text1: '',
    text2: '',
    state: false,
  });

  //Validations form
  //email
  const emailValidation = email => {
    let result;
    var re = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    if (email?.length === 0 || email === undefined) {
      result = 'Email is required';
    } else if (re.test(email) === false) {
      result = 'Invalid Email';
    }

    return result;
  };

  const passwordValidation = pass => {
    let result;
    if (pass === undefined || pass?.length === 0) {
      result = 'Password is required';
    } else if (pass.length < 6 || pass.length > 14) {
      result = 'Password should be 6 to 14 character long';
    }
    return result;
  };
  let resetPassword = e => {
    e.preventDefault();
    var a = emailValidation(email);
    if (a === undefined) {
      submitEmail(email, false);
    } else {
      setShowAlert(true);
      setSuccessMedd({
        text1: 'Error!',
        text2: a,
        state: false,
      });
    }
  };
  let changePassword = e => {
    e.preventDefault();
    var a = passwordValidation(password);
    if (a === undefined) {
      if (otp.length === 6) {
        submitNewPassword(email);
      } else {
        setShowAlert(true);
        setSuccessMedd({
          text1: 'Error!',
          text2: 'Incorrect Otp',
          state: false,
        });
      }
    } else {
      setShowAlert(true);
      setSuccessMedd({
        text1: 'Error!',
        text2: a,
        state: false,
      });
    }
  };

  const submitEmail = async (val, state) => {
    setIsLoading(true);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    console.log(val);
    var form = new FormData();
    form.append('email', val);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/sendStudentOtpEmail`,
      form,
      config,
    );
    if (data.statusText === 'Success') {
      console.log(data);
      setIsLoading(false);
      setShowAlert(true);
      if (!state) {
        setSuccessMedd({
          text1: 'OTP Sent!',
          text2: 'An otp has been sent to your email address.',
          state: true,
        });
      } else {
        setSuccessMedd({
          text1: 'OTP Sent!',
          text2: 'An otp has been resent to your email address.',
          state: true,
        });
      }
      setEmailSuccess(true);
    } else {
      setShowAlert(true);
      setSuccessMedd({
        text1: 'Error!',
        text2: data.message,
        state: false,
      });
      console.log(data);
      setIsLoading(false);
    }
  };
  const history = useHistory();

  const submitNewPassword = async val => {
    setIsLoading(true);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    console.log(val);
    var form = new FormData();
    form.append('email', val);
    form.append('otpCode', otp);
    form.append('newPassword', password);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/changeStudentPassword`,
      form,
      config,
    );
    if (data.statusText === 'Success') {
      console.log(data);
      setIsLoading(false);
      setShowAlert(true);
      setSuccessMedd({
        text1: 'Password Changed!',
        text2: 'Your password has been changed successfully, please login.',
        state: true,
      });
      history.push('/login');
      setEmailSuccess(true);
    } else {
      setShowAlert(true);
      setSuccessMedd({
        text1: 'Error!',
        text2: data.message,
        state: false,
      });
      console.log(data);
      setIsLoading(false);
    }
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  return (
    <div className="resetPassword">
      {showAlert ? (
        <Alert
          style={{
            position: 'fixed',
            top: '5%',
            left: '10%',
            zIndex: 500,
            width: '80%',
            alignContent: 'center',
          }}
          variant={successMess.state === true ? 'success' : 'danger'}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {successMess.state === true ? (
            <>
              <Alert.Heading>{successMess.text1}</Alert.Heading>
              <p> {successMess.text2}</p>
            </>
          ) : (
            <>
              <Alert.Heading>{successMess.text1}</Alert.Heading>
              <p> {successMess.text2} </p>
            </>
          )}
        </Alert>
      ) : null}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <Fade>
              <div className="card">
                <div className="row mb-4">
                  <div className="col">
                    <h1 className="text-center text-theme-secondary">
                      {emailSuccess ? 'Enter OTP' : 'Reset Password'}
                    </h1>
                    <div className="divider"></div>
                  </div>
                </div>
                {emailSuccess === false ? (
                  <form>
                    <div className="form-row">
                      <div className="col form-group">
                        <input
                          required
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-2">
                      <div className="col text-center">
                        <button className="btn w-100" onClick={resetPassword}>
                          {!isLoading ? (
                            <span>Reset Password</span>
                          ) : (
                            <span
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <form>
                    <div className="form-row">
                      <div className="col form-group">
                        <OtpInput
                          shouldAutoFocus
                          containerStyle={{
                            width: '100%',
                            justifyContent: 'space-evenly',
                          }}
                          inputStyle={{ width: 30, height: 35 }}
                          value={otp}
                          onChange={e => setOtp(e)}
                          numInputs={6}
                          separator={<span> </span>}
                        />
                      </div>
                    </div>
                    <div>
                      <p style={{ textAlign: 'center' }}>
                        If you don't receive your OTP within{' '}
                        <Countdown
                          renderer={renderer}
                          date={Date.now() + 180000}
                        />{' '}
                        sec press.{' '}
                        <a
                          style={{ color: 'orange', cursor: 'pointer' }}
                          onClick={() => submitEmail(email, true)}
                        >
                          Resend
                        </a>
                      </p>
                    </div>
                    <div
                      className="form-row"
                      style={{ marginTop: 10, marginBottom: 40 }}
                    >
                      <div className="col form-group">
                        {/* <p style={{marginBottom:2,fontWeight:600}}>New Password</p> */}
                        <input
                          required
                          type={!passwordHidden ? 'password' : 'text'}
                          name="password"
                          placeholder="New Password"
                          className="form-control"
                          onChange={e => {
                            setPassword(e.target.value);
                          }}
                        />
                        <div
                          style={{
                            alignSelf: 'flex-end',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: -25,
                            marginLeft: -10,
                            height: 1,
                          }}
                        >
                          <i
                            className={`fas ${
                              passwordHidden ? 'fa-eye-slash' : 'fa-eye'
                            }`}
                            style={{ color: 'black' }}
                            onClick={() => {
                              setPasswordHidden(!passwordHidden);
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="form-row mt-2">
                      <div className="col text-center">
                        <button className="btn w-100" onClick={changePassword}>
                          {!isLoading ? (
                            <span>Change Password</span>
                          ) : (
                            <span
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                <div className="row">
                  <div className="col">
                    <Link
                      to="/login"
                      className={`btn btn-outline-dark w-100 mt-3 ${
                        isLoading ? 'd-none' : ''
                      }`}
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useHistory } from 'react-router';
import { BASE_API_URL_V2 } from 'store';

import { useTranslation } from 'react-i18next';
const schema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(8, 'Should be minimum 8 characters long')
    .required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
});

export default function AccountRegistration(props) {
  const { data } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  const notify = message => toast(message);

  const submitIt = async values => {
    props.setEmail(values.email);
    var obj = new FormData();
    obj.append('email', values.email);
    obj.append('firstName', data.firstName);
    obj.append('lastName', data.lastName);
    obj.append('password', data.password);
    obj.append('gender', data.gender);
    obj.append('residenceCountry', data.residence);
    obj.append('phoneNo', data.phone);
    obj.append('nationality', data.nationality);
    obj.append('companyName', data.companyName);
    obj.append('yearsOfExperience', data.yearsExperience);
    obj.append('dateOfBirth', data.dateOfBirth);
    obj.append('emiratesIdFile', data.fileD.EmiratesId);
    obj.append('tradeLicenseFile', data.fileD.TradeLicense);
    obj.append('companyLogoFile', data.fileD.CompanyLogo);
    obj.append('trnCertificateFile', data.fileD.TRNCertificate);
    obj.append('certificateFile', data.fileD.Certificate);
    obj.append('userImage', data.profileImage);

    console.log(obj, data, data.fileD.CompanyLogo);
    const response = await axios
      .post(`${BASE_API_URL_V2}/trainer/trainerSignUp`, obj, config)
      .then(res => {
        if (!!!res?.data?.status) {
          notify(res?.data?.error);
        }
        if (res?.data?.status) {
          notify(res?.data?.message);
          notify('Enter the code send to your email');
          props.setStep(4);
        }
      })
      .catch(error => {
        console.log('error', { error });
        notify(error.response.data.error);
      });
  };
  return (
    <Col xs={12} sm={12}>
      <Formik
        validationSchema={schema}
        onSubmit={values => {
          if (values.password === values.confirmPassword) {
            submitIt(values);
          } else {
            notify('Please Enter Correct Password');
          }
        }}
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form onSubmit={handleSubmit} id="reg-form">
            <Form.Group>
              <Form.Label>{t('email')}</Form.Label>
              <Form.Control
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('password')}</Form.Label>
              <Form.Control
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('confirm_pass')}</Form.Label>
              <Form.Control
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Formik>
      <ToastContainer newestOnTop={true} />
    </Col>
  );
}

import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';

const CustomContainer = styled(Container)`
  h3,
  div {
    text-align: center;
  }
  p {
    text-align: center;
  }
`;

export default function TransactionDetails(props) {
  const location = useLocation();
  const { data } = location.state;
  console.log(data, 'lovevvevevevevev');
  const { match } = props;
  const { id } = match.params;
  // console.log(props.data);
  const [transaction, setTransaction] = useState(data);
  console.log(transaction);
  return (
    <CustomContainer fluid style={{ marginTop: '5%' }}>
      <Row className="justify-content-center">
        <Col xs={12} sm={12}>
          <h1>Transaction Details</h1>
        </Col>
        <Col className="mt-2">
          <p>
            <b>Transaction Id: {transaction.transaction}</b>
          </p>
          <p>Transaction Name: {transaction.payingAccountName}</p>

          <p>
            Course Instructor:{' '}
            {transaction.course.trainerDetail.firstName +
              ' ' +
              transaction.course.trainerDetail.lastName}
          </p>

          {/* <p>Course Joining Date: {transaction.joiningDate}</p>
          <p>Course Joining Fee: ${transaction.joiningFee}</p> */}
          <p>Transaction Date: {transaction.createdAt}</p>
          <p>Payment Amount: {transaction.amount}</p>
        </Col>
        <Col sm={12} xs={12}>
          <div>
            <Button
              onClick={() => (window.location = '/transaction-history')}
              className="mt-2"
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
    </CustomContainer>
  );
}

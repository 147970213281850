import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import nations from '../../../../common/nationalities.json';
import states from '../../../../common/countryStates.json';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
const schema = yup.object().shape({
  companyName: yup.string().required(),
  yearsExperience: yup.string().required(),
});

export default function CompanyRegistration(props) {
  const { setStep, data } = props;

  const { t } = useTranslation();
  useEffect(() => {
    const $ = window.$;
    $(document).on('change', 'input[type="file"]', function(e) {
      var fileName = e.target.files[0]?.name || '';
      $(e.target)
        .siblings('.custom-file-label')
        .html(fileName);
    });
  }, []);

  return (
    <Col sm={12} xs={12}>
      <Formik
        validationSchema={schema}
        onSubmit={values => {
          // console.log('values=', values);
          let companyName = values.companyName;
          let yearsExperience = values.yearsExperience;
          let mdb = {
            ...data,
            companyName,
            yearsExperience
          };
          props.change(mdb);
          setStep(2);
        }}
        initialValues={{
          companyName: '',
          yearsExperience: '',
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate id="reg-form" onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{t('company_name')}</Form.Label>
              <Form.Control
                name="companyName"
                type="text"
                value={values.companyName}
                onChange={handleChange}
                isInvalid={!!errors.companyName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.companyName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('year_of_exp')}</Form.Label>
              <Form.Control
                type="number"
                name="yearsExperience"
                value={values.yearsExperience}
                onChange={handleChange}
                isInvalid={!!errors.yearsExperience}
              />
              <Form.Control.Feedback type="invalid">
                {errors.yearsExperience}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

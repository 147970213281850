import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { axiosClient } from 'helper';
import { defineValue, defineDate } from '../../../../store';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TransactionCard = styled(Card)`
  h3,
  p {
    text-align: left;
  }
  min-height: 200px;
  a {
    margin: 10px 20px 20px;
    /* background-color: orange; */
    font-size: 15px;
    /* width: 50%; */
    align-items: center;
    align-self: center;
    border-radius: 4px;
    padding: 8px 15px;
    font-weight: 500;
    color: rgb(95, 95, 95);
    text-decoration: none;
    border: 1px solid;
    transition: 0.2s ease;
    
  }
}
a:hover {
  background-color: orange;
  color: white;
  border-color: white;
  padding: 8px 20px;
  font-size: 16px;
}
`;

const MenuItems = styled.h4`
  color: #fc8200;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: #2c2e94;
  }
  &.active-item {
    color: #2c2e94;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: #000000;
  &:hover {
    color: #fc8c42;
    text-decoration: none;
  }
`;

export default function TransactionHistory() {
  const langRedux = useSelector(state => state.language);

  const { t } = useTranslation();
  // getting userId from state.
  const { userId } = useSelector(state => state.studentLogin);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState(null);

  // Function for getting transaction history from backend.
  async function getTransactions(userId) {
    setLoading(true);
    try {
      const { data } = await axiosClient().get('/student/transactionHistory', {
        params: {
          studentId: userId,
        },
      });
      setTransactions(data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  // Function to format date string.
  function dateStringFormatter(date) {
    if (date) {
      var dateString = new Date(date);
      var day = dateString.getDate();
      var month = dateString.getMonth() + 1; //Months are zero based
      var year = dateString.getFullYear();
      dateString = day + '-' + month + '-' + year;
      // dateString = dateString.toISOString().substr(0, 10);
      return dateString;
    } else {
      return date;
    }
  }

  // fetching transaction history from backend.
  useEffect(() => {
    getTransactions(userId);
  }, [userId]);

  //modal for course detail
  function MyVerticallyCenteredModal(props) {
    const langRedux = useSelector(state => state.language);

    const { t } = useTranslation();
    const { studentId, accessToken } = useSelector(state => state.studentLogin);
    console.log(props);
    const ratings = rating => {
      const Star = <i className="fas fa-star stars"></i>;
      const stars = [];
      for (let i = 0; i < rating; i++) {
        stars.push(Star);
      }
      return stars;
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('about_booking')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'myBook-card'}>
          <div className="col-sm-12">
            <div className="card">
              <div
                className="card-image"
                style={{
                  backgroundImage: `url(${props.data?.item?.imagesPath[0]})`,
                }}
              ></div>
              <div>
                {/* <i className="fas fa-ambulance"></i> */}

                <p className="font-weight-strong category">
                  {
                    props.data?.item[
                      langRedux.language === 'en' ? 'nameEnglish' : 'nameArabic'
                    ]
                  }
                </p>
              </div>
              {/* <p className="font-weight-strong price">
                <div className="rating">
                  <div className="stars">
                    {ratings(5).map((star, index) => (
                      <i key={index} style={{ color: '#F6BA00', fontSize: 16 }}>
                        {star}
                      </i>
                    ))}
                  </div>
                </div>
              </p> */}
              <hr />

              <div className="description">
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-id-badge"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('transaction_ID')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.payingAccountName}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      class="fa fa-usd"
                      aria-hidden="true"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('transaction_amt')}
                    </p>
                  </div>
                  <div>
                    <p
                      className="value"
                      style={{ marginBottom: 0, color: 'green' }}
                    >
                      {props.data?.amount} AED
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-map-marker"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {' '}
                      {t('institute')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.item.institute}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fa fa-graduation-cap"
                      aria-hidden="true"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('instructed_by')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {defineValue(
                        props.data?.trainer?.firstName +
                          ' ' +
                          props.data?.trainer?.lastName,
                      )}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-calendar"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {' '}
                      {t('trans_date')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {defineDate(props.data?.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <Container style={{ marginTop: '5%' }}>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        // onStatusChanged={() => setStatusChanged({ state: true, data: data })}
        data={data}
      />
      <Row className="justify-content-center">
        <Col xs={12} sm={12}>
          <h2 style={{ textAlign: 'center' }}>{t('transaction_history')}</h2>
        </Col>
        <Col xs={12} sm={12} className="mt-5">
          <Row className="justify-content-center">
            {!loading && transactions.length >= 1 ? (
              transactions.map(transaction => (
                <Col xs={10} sm={6} key={transaction._id}>
                  <TransactionCard className="mb-3">
                    <Card.Body>
                      <Col xs={12}>
                        <Col xs={12} sm={12}>
                          <p
                            style={{
                              fontSize: '1.4rem',
                              textTransform: 'capitalize',
                            }}
                          >
                            <strong>{t('course_name')}:</strong>{' '}
                            {defineValue(
                              transaction.course?.[
                                langRedux.language === 'en'
                                  ? 'nameEnglish'
                                  : 'nameArabic'
                              ],
                            )}
                          </p>
                        </Col>
                        <Col xs={12} sm={12}>
                          <p>
                            {' '}
                            <strong>{t('amount')}: </strong>{' '}
                            {defineValue(transaction?.amount)}$
                          </p>
                        </Col>
                        <Col xs={12} sm={12}>
                          <p>
                            <strong>{t('paid_on')}: </strong>
                            {dateStringFormatter(transaction?.createdAt)}
                          </p>
                        </Col>
                        <Col xs={12} sm={12}>
                          <p>
                            <strong>{t('pay_name')}:</strong>{' '}
                            {defineValue(transaction?.student?.firstName)}
                          </p>
                        </Col>
                        <Col xs={12} sm={12}>
                          <p>
                            <strong>{t('pay_email')}:</strong>{' '}
                            {defineValue(transaction?.student?.email)}
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <a
                            onClick={() => {
                              setData(transaction);
                              setModalShow(true);
                            }}
                          >
                            <i className="fas fa-eye"></i>
                          </a>
                        </Col>
                      </Col>
                    </Card.Body>
                  </TransactionCard>
                </Col>
              ))
            ) : (
              <h1 style={{ color: 'gray' }}> {t('no_trans_made')}</h1>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

//  {
//   id: '1',
//   img: course1,
//   course: 'Car Driving',
//   instructor: 'Mr Shafiq',
//   location: 'buildling 23, suite 12',
//   status: 'dropped',
// },
// {
//   id: '2',
//   img: course1,
//   course: 'Car Driving',
//   instructor: 'Mr Habib',
//   location: 'buildling 23, suite 12',
//   status: 'done',
// },
// {
//   id: '3',
//   img: course1,
//   course: 'Car Driving',
//   instructor: 'Ms Insha',
//   location: 'buildling 23, suite 12',
//   status: 'pending',
// },
// {
//   id: '4',
//   img: course1,
//   course: 'Car Driving',
//   instructor: 'Mr Hashir',
//   location: 'buildling 23, suite 12',
//   status: 'dropped',
// },

{
  /* <Col sm={8} className="mt-5">
          <Row className="justify-content-center">
            <Col>
              <MenuItems
                className={activeItem === 'all' ? 'active-item' : ''}
                onClick={() => {
                  filterTransactions('all');
                  setActiveItem('all');
                }}
              >
                All
              </MenuItems>
            </Col>
            <Col>
              <MenuItems
                className={activeItem === 'done' ? 'active-item' : ''}
                onClick={() => {
                  filterTransactions('done');
                  setActiveItem('done');
                }}
              >
                Done
              </MenuItems>
            </Col>
            <Col>
              <MenuItems
                className={activeItem === 'dropped' ? 'active-item' : ''}
                onClick={() => {
                  filterTransactions('dropped');
                  setActiveItem('dropped');
                }}
              >
                Drop
              </MenuItems>
            </Col>
            <Col>
              <MenuItems
                className={activeItem === 'pending' ? 'active-item' : ''}
                onClick={() => {
                  filterTransactions('pending');
                  setActiveItem('pending');
                }}
              >
                Pending
              </MenuItems>
            </Col>
          </Row> 
        </Col>*/
}

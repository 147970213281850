import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import {
  imageChecker,
  defineDate,
  defineValue,
  BASE_API_URL_V2,
} from '../../../../store';
import { useTranslation } from 'react-i18next';
function MyVerticallyCenteredModal(props) {
  const { studentId, accessToken } = useSelector(state => state.studentLogin);
  const [footerData, setFooterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  // function for getting footer data.
  async function getFooterData() {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${BASE_API_URL_V2}/student/getRating?trainerId=${props.data._id}`,
      );
      console.log(data, 'rating');
      setFooterData(data);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  // fetching footer data from api.
  useEffect(() => {
    getFooterData();
  }, [props.data]);

  const ratings = rating => {
    const Star = <i className="fas fa-star stars"></i>;
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(Star);
    }
    return stars;
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('about_trainer')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'trainer-card'}>
        <div className="col-sm-12">
          <div className="card">
            <div
              className="card-image"
              style={{
                backgroundImage: `url(${props.data?.imagePath})`,
              }}
            ></div>
            <div>
              {/* <i className="fas fa-ambulance"></i> */}

              <p className="font-weight-strong category">
                {props.data?.firstName + ' ' + props.data?.lastName}
              </p>
            </div>
            <p className="font-weight-strong price">
              <div className="rating">
                <div className="stars">
                  {ratings(footerData?.rating ? footerData?.rating : 1).map(
                    (star, index) => (
                      <i key={index} style={{ color: '#F6BA00', fontSize: 16 }}>
                        {star}
                      </i>
                    ),
                  )}
                </div>
              </div>
            </p>
            <div className="buttonCont">
              <Link
                className="align-middle text-center"
                to={{
                  pathname: `/chat`,
                  state: {
                    data: props.data,
                    status: true,
                  },
                }}
              >
                <i className="fas fa-sms"> </i> {t('chat')}
              </Link>

              <a
                className="align-middle text-center back"
                onClick={() => props.onHide()}
              >
                {' '}
                {t('back')}
              </a>
            </div>
            <div className="description">
              <div className="item">
                <div className="key">
                  <i
                    className="fas fa-map-marker"
                    style={{ marginTop: 2, width: 20 }}
                  >
                    {' '}
                  </i>
                  <p style={{ marginBottom: 0, marginLeft: 14 }}>{t('from')}</p>
                </div>
                <div>
                  <p className="value" style={{ marginBottom: 0 }}>
                    {defineValue(props.data?.residenceCountry)}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="key">
                  <i
                    className="fas fa-male"
                    style={{ marginTop: 2, width: 20 }}
                  >
                    {' '}
                  </i>
                  <p style={{ marginBottom: 0, marginLeft: 14 }}>
                    {t('gender')}
                  </p>
                </div>
                <div>
                  <p className="value" style={{ marginBottom: 0 }}>
                    {props.data?.gender}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="key">
                  <i
                    className="fas fa-user"
                    style={{ marginTop: 2, width: 20 }}
                  >
                    {' '}
                  </i>
                  <p style={{ marginBottom: 0, marginLeft: 14 }}>
                    {t('member_since')}
                  </p>
                </div>
                <div>
                  <p className="value" style={{ marginBottom: 0 }}>
                    {defineDate(props.data?.registrationTime)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default function Faq({ course }) {
  const langRedux = useSelector(state => state.language);

  const [activeTab, setActiveTab] = useState('Course Information');
  const { t } = useTranslation();
  const { studentId } = useSelector(state => state.studentLogin);
  console.log(course);
  // redirecting user to login page if not logged in and clicks on book now.
  var redirect;
  studentId ? (redirect = `/bookCourse/${course._id}`) : (redirect = '/login');
  const [modalShow, setModalShow] = useState(false);
  const tabs = [
    { tab: 'Course Information', id: 'courseInfo', isActive: false },
    { tab: 'Document Required', id: 'docReq', isActive: false },
    { tab: 'Fees', id: 'fee', isActive: false },
    { tab: 'Registration Procedure', id: 'regProc', isActive: false },
    { tab: 'Lecture Details', id: 'lectDetails', isActive: false },
    { tab: 'Test Details', id: 'testDetails', isActive: false },
    { tab: 'Book Now', id: 'regNow', isActive: false },
  ];

  const showContent = tab => {
    switch (tab) {
      case tabs[0].tab:
        return (
          <div>
            <h3>
              {t('course_name')}:{' '}
              {
                course?.[
                  langRedux.language === 'en' ? 'nameEnglish' : 'nameArabic'
                ]
              }
            </h3>
            <p>
              {t('course_info')}:{' '}
              {
                course?.[
                  langRedux.language === 'en'
                    ? 'courseInformationEnglish'
                    : 'courseInformationArabic'
                ]
              }
            </p>
            <p>
              {t('instructor_name')}:{' '}
              <a
                onClick={() => setModalShow(true)}
                style={{
                  cursor: 'pointer',
                  color: 'orange',
                  textTransform: 'uppercase',
                }}
              >
                {course?.trainer?.firstName + ' ' + course?.trainer?.lastName}
              </a>
            </p>
          </div>
        );
      case tabs[1].tab:
        return (
          <div>
            <h3>
              {
                course?.[
                  langRedux.language === 'en'
                    ? 'documentsRequiredEnglish'
                    : 'documentsRequiredArabic'
                ]
              }
            </h3>
          </div>
        );
      case tabs[2].tab:
        return (
          <div>
            <h3>
              {t('training_fees')}: {course.price} AED{' '}
            </h3>
            <h5>
              {t('course_fee_detail')}:
              {
                course?.[
                  langRedux.language === 'en'
                    ? 'feesDetailsEnglish'
                    : 'feesDetailsArabic'
                ]
              }
            </h5>
          </div>
        );
      case tabs[3].tab:
        return (
          <div>
            <h3>{t('registration_Steps')}</h3>
            <p>
              {
                course?.[
                  langRedux.language === 'en'
                    ? 'RegistrationProcedureEnglish'
                    : 'RegistrationProcedureArabic'
                ]
              }
            </p>
            {/* <ol>
              {course.regProcedure.map(item => (
                <li>{item}</li>
              ))}
            </ol> */}
          </div>
        );
      case tabs[4].tab:
        return (
          <div>
            {
              <h3>
                {' '}
                {
                  course?.[
                    langRedux.language === 'en'
                      ? 'lectureDetailsEnglish'
                      : 'lectureDetailsArabic'
                  ]
                }{' '}
              </h3>
            }
            {/* <ul>
              {course.lecDetails.map(item => (
                <li>{item}</li>
              ))}
            </ul> */}
          </div>
        );
      case tabs[5].tab:
        return (
          <div>
            <h3>
              {
                course?.[
                  langRedux.language === 'en'
                    ? 'testDetailsEnglish'
                    : 'testDetailsArabic'
                ]
              }
            </h3>
            {/* <ul>
              {course.testDetails.map(item => (
                <li>{item}</li>
              ))}
            </ul> */}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="faq pt-5 pb-5">
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={course.trainer}
      />
      <div className="container card" style={{ paddingTop: '0px' }}>
        <ul className="nav nav-tabs" role="tablist">
          <li
            role="presentation"
            className={activeTab === tabs[0].tab ? 'active' : ''}
          >
            <a
              href="#tab-1"
              aria-controls="tab-1"
              role="tab"
              data-toggle="tab"
              onClick={() => setActiveTab(tabs[0].tab)}
            >
              <i className="fas fa-tasks"> </i>
              {t('course_info')}
            </a>
          </li>
          <li
            role="presentation"
            className={activeTab === tabs[1].tab ? 'active' : ''}
          >
            <a
              href="#tab-2"
              aria-controls="tab-2"
              role="tab"
              data-toggle="tab"
              onClick={() => setActiveTab(tabs[1].tab)}
            >
              {' '}
              <i className="fas fa-file"> </i> {t('doc_req')}{' '}
            </a>
          </li>
          <li
            role="presentation"
            className={activeTab === tabs[2].tab ? 'active' : ''}
          >
            <a
              href="#tab-3"
              aria-controls="tab-3"
              role="tab"
              data-toggle="tab"
              onClick={() => setActiveTab(tabs[2].tab)}
            >
              <i className="fas fa-dollar-sign"> </i> {t('fees')}
              {/* AED{t('fees')} */}
            </a>
          </li>
          <li
            role="presentation"
            className={activeTab === tabs[3].tab ? 'active' : ''}
          >
            <a
              href="#tab-4"
              aria-controls="tab-4"
              role="tab"
              data-toggle="tab"
              onClick={() => setActiveTab(tabs[3].tab)}
            >
              <i className="fas fa-book-reader"> </i> {t('reg_pro')}
            </a>
          </li>
          <li
            role="presentation"
            className={activeTab === tabs[4].tab ? 'active' : ''}
          >
            <a
              href="#tab-5"
              aria-controls="tab-5"
              role="tab"
              data-toggle="tab"
              onClick={() => setActiveTab(tabs[4].tab)}
            >
              <i className="fas fa-chalkboard-teacher"> </i>{' '}
              {t('lecture_details')}
            </a>
          </li>
          <li
            role="presentation"
            className={activeTab === tabs[5].tab ? 'active' : ''}
          >
            <a
              href="#tab-6"
              aria-controls="tab-6"
              role="tab"
              data-toggle="tab"
              onClick={() => setActiveTab(tabs[5].tab)}
            >
              <i className="fas fa-file-alt"> </i> {t('test_detail')}
            </a>
          </li>
        </ul>
        <div className="card-body">{showContent(activeTab)}</div>
      </div>
      <div
        style={{
          marginTop: 22,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <a href=`/bookCourse/${props.id}`` aria-controls="tab-7"> */}
        <Link className="btn btn-primary" to={redirect}>
          <i
            className="fas fa-user-plus"
            onClick={() => setActiveTab(tabs[6].tab)}
          >
            {' '}
          </i>{' '}
          {t('book_now')}
        </Link>
      </div>
    </div>
  );
}

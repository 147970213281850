import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  InputGroup,
  Alert,
} from 'react-bootstrap';
import { Formik } from 'formik';
import nations from '../../../../common/nationalities.json';
import countryDialCodes from '../../../../common/countryDialCodes';
import ImageCropper from 'common/ImageCropper';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from 'store';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { BASE_API_URL_V2, imageChecker } from 'store';
import axios from 'axios';
import { getStudentDetails } from '../../../../stateManagement/actions/studentActions';
import Footer from 'components/Footer/Footer';
const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  // password: yup
  //   .string()
  //   .min(8, 'Should be minimum 8 characters long')
  //   .required(),
  dob: yup.string().required('Date of Birth is required field'),
  nationality: yup.string().required(),
  courses: yup.string().required(),
  grade: yup.string().required(),
  driving: yup.string().required(),
  country: yup.string().required(),
  phoneCode: yup.string().required(),
  phone: yup.string().required(),
});

export default function ProfileUpdate() {
  // const [passwordHidden, setPasswordHidden] = useState(false);
  // const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [countryDialCode, setCountryDialCode] = useState('+ xyz');
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(t('profile_pic'));
  const [inputImg, setInputImg] = useState(null);

  // blob refers to croppedImage
  const [blob, setBlob] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [_id, set_Id] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getBlob = blob => {
    setBlob(blob);
  };

  const changeDialCode = e => {
    const index = countryDialCodes.findIndex(
      countryDialCode => countryDialCode.name === e.target.value,
    );
    setCountryDialCode(countryDialCodes[index]?.dialCode || '+ xyz');
  };

  // for handling change in image file.
  const onFileChange = e => {
    // setFileUrl(window.URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    setImgFile(file);
    console.log(file);
    const reader = new FileReader();
    setHidden(false);

    reader.addEventListener(
      'load',
      () => {
        setInputImg(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
    setFileName(file.name);
  };

  // getting state from redux
  const { accessToken, userId, studentId } = useSelector(
    state => state.studentLogin,
  );
  const {
    firstName,
    lastName,
    email,
    dob,
    nationality,
    residenceCountry,
    phone,
    grade,
    learningMethod,
    image,
  } = useSelector(state => state.studentDetails);
  console.log(useSelector(state => state.studentDetails));

  const [selectedGradeValue, setSelectedGradeValue] = useState(grade);

  const [selectedLearningMethod, setSelectedLearningMethod] = useState(
    learningMethod,
  );

  useEffect(() => {
    console.log('Here is my resident country: ', residenceCountry);
    // let residenceCountryVal = residenceCountry;
    const index = countryDialCodes.findIndex(
      countryDialCode => countryDialCode.name === residenceCountry,
    );
    setCountryDialCode(countryDialCodes[index]?.dialCode || '+ xyz');
  }, []);


  // onSubmit function for profile update form
  const handleUpdateSubmit = async res => {
    // setting the loading to true
    setSubmitLoading(true);

    // Creating new formdata object to send.
    var updateAccountData = new FormData();
    updateAccountData.append('userId', userId);
    updateAccountData.append('firstName', res.values.firstName);
    updateAccountData.append('lastName', res.values.lastName);
    updateAccountData.append('phoneNo', res.values.phone);
    updateAccountData.append('nationality', res.values.nationality);
    updateAccountData.append('residenceCountry', res.values.country);
    updateAccountData.append('testType', res.values.grade);
    updateAccountData.append('courseLearningMethod', res.values.driving);
    blob && updateAccountData.append('userImage', imgFile);
    // updateAccountData.append('email', res.values.email);
    // updateAccountData.append('dateOfBirth', res.values.dob);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.patch(
      `${BASE_API_URL_V2}/student/studentUpdate`,
      updateAccountData,
      config,
    );

    console.log(data);
    if (data.status) {
      // setting the loading to false
      setSubmitLoading(false);
      dispatch(getStudentDetails(studentId));
      setUpdateStatus(true);
      setShowAlert(true);
    } else if (data.status === false) {
      setSubmitLoading(false);
      setErrorMessage(data.error);
      setUpdateStatus(false);
      setShowAlert(true);
    } else {
      // setting the loading to false
      setSubmitLoading(false);
      window.alert(data.error);
    }
  };
  const dispatch = useDispatch();

  const history = useHistory();
  useEffect(() => {
    if (!accessToken) {
      // redirecting if user isnt logged in.
      history.push('/login');
    }

    // firing the formatting function when dob loads.
    // if (dob) {
    //   dateString = dateStringFormatter(dob);
    // }

    // if update status is true redirecting to home page.
    if (updateStatus) {
      setTimeout(() => {
        history.push('/');
      }, 2000);
    }
  }, [accessToken, updateStatus]);

  return (
    <>
    <Container style={{ marginTop: '5%', marginBottom: '3%' }}>
      {showAlert ? (
        <Alert
          style={{
            position: 'fixed',
            top: '22%',
            left: '10%',
            zIndex: 1500,
            width: '80%',
            alignContent: 'center',
          }}
          variant={updateStatus === true ? 'success' : 'danger'}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {updateStatus === true ? (
            <>
              <Alert.Heading>Update Successful</Alert.Heading>
              <p>Your Profile Updated Successfully</p>
            </>
          ) : (
            <>
              <Alert.Heading>Update Failed</Alert.Heading>
              <p> {errorMessage} </p>
            </>
          )}
        </Alert>
      ) : null}
      <h2 style={{ textAlign: 'center' }}>{t('profile_update')}</h2>
      <Row className="mt-5">
        <Col xs={12} sm={12}>
          <div style={{ textAlign: 'center' }}>
            {blob ? (
              <img src={URL.createObjectURL(blob)} />
            ) : imgFile ? (
              <img src={imgFile} alt="profile" />
            ) : image ? (
              <img
                src={image}
                style={{ width: 120, height: 120 }}
                alt="profile"
              />
            ) : null}
          </div>
        </Col>
        <Col xs={12} sm={12}>
          {inputImg && !hidden && (
            <Row className="mt-5">
              <Col className="mb-3" xs={12} sm={12} style={{ height: '500px' }}>
                <ImageCropper getBlob={getBlob} inputImg={inputImg} />
              </Col>
              <Col xs={12} sm={12}>
                <div style={{ textAlign: 'center' }}>
                  <Button onClick={() => setHidden(true)}>Update</Button>
                </div>
              </Col>
            </Row>
          )}
        </Col>
        <Col sm={12}>
          <Formik
            enableReinitialize
            validationSchema={schema}
            onSubmit={values => {
              console.log(values);
            }}
            initialValues={{
              firstName: firstName || '',
              lastName: lastName || '',
              email: email || '',
              // password: '',
              // dob: dateString || '',
              nationality: nationality || '',
              grade: selectedGradeValue || '',
              driving: selectedLearningMethod || '',
              country: residenceCountry || '',
              phoneCode: '',
              phone: phone || '',
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
            }) => (
              <Form noValidate>
                <Form.Group>
                  <Form.Label>{t('profile_pic')}</Form.Label>
                  <Form.File
                    name="profilePicture"
                    label={fileName}
                    id="profilePicture"
                    onChange={e => onFileChange(e)}
                    accept="image/*"
                    custom
                  />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="firstName">{t('first_name')}</label>
                  <Form.Control
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    isInvalid={!!errors.firstName && touched.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('last_name')}</Form.Label>
                  <Form.Control
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    value={values.lastName}
                    onBlur={handleBlur}
                    isInvalid={!!errors.lastName && touched.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group>
                  <Form.Label>{t('nationality')}</Form.Label>
                  <Form.Control
                    name="nationality"
                    as="select"
                    value={values.nationality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.nationality && touched.nationality}
                    custom
                  >
                    <option value="">{values.nationality}</option>
                    {nations.map(nation => (
                      <option key={nation.num_code} value={nation.alpha_2_code}>
                        {nation.nationality}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {errors.nationality}
                  </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group>
                  <Form.Label>{t('residence_country')}</Form.Label>
                  <Form.Control
                    name="country"
                    as="select"
                    value={values.country}
                    onChange={e => {
                      handleChange(e);
                      changeDialCode(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.country && touched.country}
                    custom
                  >
                    <option value="">{values.country}</option>
                    {countryDialCodes.map(dialCode => (
                      <option key={dialCode.name} value={dialCode.name}>
                        {dialCode.name}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('phone')}</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <Form.Control
                        name="phoneCode"
                        as="select"
                        value={countryDialCode}
                        onChange={handleChange}
                        type="number"
                        custom
                        disabled
                      >
                        {countryDialCodes.map(dialCode => (
                          <option
                            key={dialCode.isoCode}
                            value={dialCode.dialCode}
                          >
                            {`${values.country} ${countryDialCode}`}
                          </option>
                        ))}
                      </Form.Control>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      type="number"
                      onBlur={handleBlur}
                      isInvalid={!!errors.phone && touched.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Button
                  className="btn btn-primary btn-md col-12"
                  onClick={() => {
                    handleUpdateSubmit({ values });
                  }}
                >
                  {!submitLoading ? (
                    <span>{t('update_account')}</span>
                  ) : (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                    ></span>
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
    <Footer/>
    </>
  );
}

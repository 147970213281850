import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { firestore, auth } from 'config/firebase';
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';
import { BASE_URL_V2 } from 'store';
import { imageChecker } from 'store';
import banner from 'images/banner.jpg';
import { Link } from 'react-router-dom';
import { get } from 'services/RestService'
import Footer from 'components/Footer';
import DocumentPic from 'assets/images/document.png'

import { useTranslation } from 'react-i18next';
export default function TermsConditions() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation()
  
  const getGalleryImages = () => {
    get('/student/getDocuments').then((data) => {
      setData(data);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.log(err)
    })
  }

  // fetching gallery images.
  useEffect(() => {
    getGalleryImages();
  }, []);


  const toDataURL = url => {
    return axios
      .get(url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        return URL.createObjectURL(blob);
      });
  };
  const download = e => {
    if (e.target.href.endsWith('df')) {
      console.log(e.target.href);
      fetch(e.target.href, {
        method: 'GET',
        headers: {},
      })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'document.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      console.log(e.target.href);
      fetch(e.target.href, {
        method: 'GET',
        headers: {},
      })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.png'); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  const getTo = url => {
    {
      return axios
        .get(BASE_URL_V2 + url.documentFilePath, {
          responseType: 'arraybuffer',
        })
        .then(response =>
          Buffer.from(response.data, 'binary').toString('base64'),
        );
    }
  };
  return (
    <>
      <div>
        <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
          <div className="overlay">
            <h1 className="text-white mb-2">{t('our_doc')}</h1>

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" className="font-weight-bold">
                   {t('home')}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-capitalize"
                  aria-current="page"
                >
                   {t('document')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        
        <div className="courseCategories section">
          <div className="container">
            <div className="row justify-content-center">
              {!loading && data
                ? data.map(course => (
                    <div
                      className="col-12 col-md-6 col-lg-4"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="card">
                        {(
                          <div
                            className="card-image"
                            style={{
                              backgroundImage: `url(${DocumentPic})`,
                            }}
                          ></div>
                        )}

                        {/* <i className="fas fa-ambulance"></i> */}
                        <a
                          href={BASE_URL_V2 + course.documentFilePath}
                          target="_blank"
                          className="font-weight-strong title"
                        >
                          {course.title}
                        </a>
                        {/* <p className="mb-0">{course.descriptionEnglish}</p> */}
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                          <a
                            onClick={e => window.open(course.documentFilePath)}
                          >
                          {t('view')}
                          </a>
                          <a
                            href={course.documentFilePath}
                            download
                            onClick={e => download(e)}
                            target="_blank"
                          >
                            {t('download')}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


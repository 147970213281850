import React, { useState, useEffect } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
// import { axiosClient } from '../../../../helper'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';
export default function SearchBar() {
  const [country, selectCountry] = useState('');
  const [region, selectRegion] = useState('');
  const [searchText, setSearchText] = useState('');
  const [categories, setcategories] = useState([]);
  const [searchtex, setsearchtex] = useState(false);

  const [loading, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState(null);
  const { t } = useTranslation();

  // function for getting About data.
  async function getAboutData(country, state) {
    console.log(country, state);
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${BASE_API_URL_V2}/student/searchCourse?country=${country}&state=${state}`,
      );
      console.log(data);
      setAboutData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!country || !region) selectRegion('');
    if (country !== '' && region !== '') {
      getAboutData(country, region);
    }
    console.log('Countries Data: ', country);
  }, [country, region]);

  const onSerach = () => {
    console.log(country, region);
    const searchData = { country, region, searchText };

    if (searchText.length < 1) {
      window.toast.error('Please type something you like to search');
      return;
    } else {
      const data = {
        country: country,
        title: searchText,
      };
      // firestore.collection('courses').where('country', '==', 'pakistan' )
      // axiosClient().post('api/student/courses/get-by-location', data)
      // .then(response => {
      //   let res = JSON.parse(JSON.stringify(response));
      if (aboutData.length === 0) {
        window.toast.error('No Course Available In This Region');
      } else {
        const ans = aboutData.filter(
          e =>
            e.nameEnglish.toLowerCase().includes(searchText) ||
            e.nameEnglish.toUpperCase().includes(searchText),
        );
        setcategories(ans);
        setsearchtex(true);
      }
      //   // window.location.reload();
      // }).catch(error => {
      //   alert("Failed")
      // });
    }
  };
  const handleChange = () => {
    window.location = './courses';
  };

  // const selectCountry = countries => {
  //   let data = countries;
  //   console.log('my data: ', data);
  // };
  return (
    <div className="searchBar">
      <div className="container">
        {/* <div className="row">
          <div className="col">
            <div className="content">
              <h3>{t('welcome')}</h3>
              <h1 className="mt-4 mb-4">
                {t('welcome_2')}
                <span>{t('welcome_3')}</span>
                {t('welcome_4')}
              </h1>
              <h6>{t('welcome_5')}</h6>
            </div>
          </div>
        </div> */}
        <div className="row mt-5">
          <div className="col">
            <div className="searchForm">
              <CountryDropdown
                value={country}
                country={country}
                onChange={val =>
                  selectCountry(val, console.log('Country: ', val))
                }
                id="countryDropdown"
                classes="search dropdown"
                // valueType="short"
                defaultOptionLabel={t('country')}
              />
              <RegionDropdown
                disableWhenEmpty={true}
                // countryValueType="short"
                country={country}
                value={region}
                onChange={selectRegion}
                blankOptionLabel={t('region')}
                id="regionDropdown"
                classes="search dropdown"
                // valueType="short"
              />
              <input
                type="text"
                className="search"
                value={!region ? t('please_type') : searchText}
                placeholder={t('search')}
                disabled={!region && true}
                onChange={e => {
                  setSearchText(e.target.value);
                }}
              />
              <button onClick={onSerach}>
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: 'white', marginTop: '1%', borderRadius: 5 }}
        >
          {searchtex === true ? (
            <div>
              {categories.map(x => (
                <div
                  className="d-flex align-items-center pb-2 pt-2 item-underlined"
                  // key={x.id}
                  onClick={handleChange}
                  style={{ cursor: 'pointer' }}
                >
                  {/* <img
                        src={x.logo}
                        width="80px"
                        height="80px"
                        className="pr-2"
                        alt={x.title_en}
                      /> */}
                  <Link
                    className="title"
                    style={{
                      marginLeft: '5%',
                      marginRight: '5%',
                      width: '30%',
                      textDecoration: 'none',
                      color: 'grey',
                    }}
                    to={{
                      pathname: `/courses`,
                      state: {
                        id: x.category,
                      },
                    }}
                  >
                    Title: {x.nameEnglish}
                  </Link>
                  {/* <h5 className="title" style={{marginLeft:'5%',marginRight:'5%',width:'30%'}}>Title: {x.nameEnglish}</h5> */}

                  {/* <div className="institute">{x.institute}</div>
                            <div className="institute">Location:    {x.locations[0]?.country}</div>  */}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import SideNavBar from '../../../common/SideNavBar';
import Header from 'components/Header';
import NavBar from '../../../common/NavBar';
import SiteRoutes from './SiteRoutes';
import StudentDashboardRoutes from './StudentDashboardRoutes';
import { auth } from 'firebase';

const MainContent = styled(Row)`
  width: 100%;
  margin: -10px;

  .right-content {
    height: calc(100vh - 144px);
  }

  @media (max-width: 1014px) and (min-width: 912px) {
    .right-content {
      height: calc(100vh - 160px);
    }
  }

  @media (max-width: 912px) and (min-width: 690px) {
    .right-content {
      height: calc(100vh - 170px);
    }
  }

  @media only screen and (max-width: 690px) {
    .right-content {
      height: calc(100vh - 198px);
    }
  }
`;

function RoutesFrontend(props) {
  const [hideSideBar, setHideSideBar] = useState(true);
  const [showSiteNavTop, setShowSiteNavTop] = useState(true);
  const [contentSize, setContentSize] = useState(2);
  const [sideBarSize, setSideBarSize] = useState(10);
  const [hideContent, setHideContent] = useState(false);
  const [size, setSize] = useState('');
  const [isUser, setUser] = useState('');
  var mq = window.matchMedia('(max-width: 1200px)');

  useEffect(() => {
    // initial check to toggle something on or off
    const toggle = () => {
      setSize(window.innerWidth);
    };

    // returns true when window is <= 768px
    mq.addListener(toggle);

    // unmount cleanup handler
    return () => mq.removeListener(toggle);
  }, []);

  // toggle something based on matchMedia event
  const toggle = () => {
    if (mq.matches) {
      // do something here
    } else {
      // do something here
    }
  };
  useEffect(() => {
    console.log('ji');
    const viewportWidth = window.innerWidth;
    if (viewportWidth < 1200) setShowSiteNavTop(false);
    if (viewportWidth > 1200) setShowSiteNavTop(true);
  }, [size]);
  useEffect(() => {
    auth().onAuthStateChanged(user => {
      // this.props.navigation.navigate(user ? 'Home' : 'Auth')
      // alert(user? 'true': 'false')
      // alert(user)
      setUser(user);
    });
  }, []);

  return (
    <>
      <Header />
      <MainContent>
        {hideSideBar ? null : (
          <Col
            xs={12}
            sm={12}
            md={3}
            style={{
              padding: '0px',
              paddingTop: '45px',
              position: 'absolute',
              zIndex: 100,
            }}
          >
            <SideNavBar isUser={isUser} showSiteNavTop={showSiteNavTop} />
          </Col>
        )}
        {!hideContent ? (
          <Col
            style={{
              overflowY: 'scroll',
              alignItems: 'center',
            }}
            className="right-content"
            sm={12}
            xs={12}
            md={hideSideBar ? 12 : 12}
          >
            <Row>
              <Col xs={12} sm={12}>
                <NavBar
                  hideSideBar={hideSideBar}
                  setHideSideBar={setHideSideBar}
                  showSiteNavTop={showSiteNavTop}
                />
              </Col>
              <Col
                sm={12}
                style={{ padding: '0px', paddingTop: '45px', marginBottom: 50 }}
              >
                <StudentDashboardRoutes />
                <SiteRoutes />
              </Col>
            </Row>
          </Col>
        ) : null}
      </MainContent>
    </>
  );
}

export default RoutesFrontend;

import React from 'react';
import TheoryTestForm from './theoryTestFormSections/InitialForm';
import Footer from 'components/Footer';

export default function Courses() {
  return (
    <div>
      <TheoryTestForm />
      <Footer />
    </div>
  );
}

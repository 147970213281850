import React from 'react';
import banner from 'images/banner.jpg';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
function CourseDetails(props) {
  const { t } = useTranslation();
  const { course, match } = props;

  const langRedux = useSelector(state => state.language);

  // const course = {
  //   id: '1',
  //   title: 'Car Driving',
  //   summary:
  //     'Civil Vocational Training Institutes / Civil Driving Institute (Civil Institutes) celebrates 9 years of successfully learner & advanced driver training and driver road risk reduction in the UAE and the greater GCC region.',
  // };

  return (
    <div className="course-details">
      <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
        <div className="overlay">
          <h1 className="text-white mb-2">{t('course_details')}</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="font-weight-bold">
                  {t('home')}
                </Link>
              </li>
              <li className="active text-capitalize" aria-current="page">
                {' / ' +
                  t('courses') +
                  ' / ' +
                  course?.[
                    langRedux.language === 'en' ? 'nameEnglish' : 'nameArabic'
                  ]}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container pt-5 pb-5">
        <h3 className="text-center">
          {course?.[langRedux.language === 'en' ? 'nameEnglish' : 'nameArabic']}
        </h3>
        <p className="text-center">{course?.summary}</p>
      </div>
    </div>
  );
}

export default withRouter(CourseDetails);

import { axiosClient } from '../helper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { contactUs } from 'action';
// import { firebase, firestore } from 'config/firebase'
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';
export default function ContactForm() {
  const { t } = useTranslation();
  const [state, setState] = useState({
    fullName: '',
    email: '',
    subject: '',
    query: '',
  });

  async function contactUs(data) {
    const resp = await axios({
      method: 'post',
      url: BASE_API_URL_V2 + '/student/contactRequest',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  }
  //Validations form
  //email
  const emailValidation = email => {
    let result;
    var re = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    if (email?.length === 0 || email === undefined) {
      result = 'Email is required';
    } else if (re.test(email) === false) {
      result = 'Invalid Email';
    }

    return result;
  };
  const [messageSent, setMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  let sendMessage = e => {
    e.preventDefault();

    const { fullName, email, subject, query } = state;

    fullName.trim();
    subject.trim();
    query.trim();

    if (fullName.length < 3) {
      window.toast.error(t('err_name'));
      return;
    }
    if (emailValidation(email) !== undefined) {
      window.toast.error(t('err_email'));
      return;
    }
    if (subject.length < 5) {
      window.toast.error(t('err_subject'));
      return;
    }
    if (query.length < 5) {
      window.toast.error(t('err_question'));
      return;
    }

    const datas = {
      email: email,
      name: fullName,
      query: query,
      subject: subject,
    };

    var data = Object.entries(datas).reduce(
      (a, [k, v]) => (v ? { ...a, [k]: v } : a),
      {},
    );
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    postData(formData);
  };
  async function postData(formData) {
    const resp = await contactUs(formData)
      .then(async f => {
        console.log(f);
        window.toast.success('Message Sent!');
      })

      .catch(f => {
        console.log(f, 'error');
      });
  }
  return (
    <div className="contactForm section">
      <div className="container">
        <div className="row mb-5">
          <div className="col">
            <h1 className="text-center text-white">{t('get_in_Touch')}</h1>
            <div className="divider"></div>
            <p className="text-center text-white">
              {t('get_in_Touch_shortly')}
            </p>
          </div>
        </div>

        {!messageSent ? (
          <form>
            <div className="form-row">
              <div className="col-12 col-md-6 form-group">
                <input
                  type="text"
                  name="fullName"
                  placeholder={t('full_name_imp')}
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 form-group">
                <input
                  type="text"
                  name="email"
                  placeholder={t('email_imp')}
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col form-group">
                <input
                  type="text"
                  name="subject"
                  placeholder={t('subject_imp')}
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row mb-5">
              <div className="col form-group">
                <textarea
                  name="query"
                  placeholder={t('Your_Question')}
                  className="form-control"
                  rows="10"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col text-center">
                <button
                  className="btn btn-outline-light btn-lg"
                  onClick={sendMessage}
                >
                  {!isLoading ? (
                    <span>{t('send_message')}</span>
                  ) : (
                    <span className="spinner-grow" role="status"></span>
                  )}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div className="row">
            <div className="col">
              <div
                className="card w-100"
                style={{ padding: '20px 40px', color: '#122035' }}
              >
                <h4 className="text-center margin0 strong">
                  {t('message_send')}
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  window.toast = toast;
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="m-0 text-white text-center m-0">
                &copy; {year}. {window.appName}.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer newestOnTop={true} />
    </>
  );
}

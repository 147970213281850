import React from 'react';
import ContactForm from '../../../common/ContactForm';
import SpecialOfferDetails from './specialOffer/SpecialOfferDetails';
import Footer from 'components/Footer';

export default function SpecialOffer() {
  return (
    <>
      <SpecialOfferDetails />
      {/* <ContactForm /> */}
      <Footer />
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Form, Col, InputGroup, Button, Row, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';

const Verification = props => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const history = useHistory();

  const notify = message => toast(message);

  const submitEmail = async () => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    var form = new FormData();
    form.append('email', props.data);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/trainer/sendTrainerOtpEmail`,
      form,
      config,
    );
    if (data.status === 'success') {
      console.log(data);
    } else {
      console.log(data);
    }
  };
  console.log('Props.data', props.data);
  console.log('OTP', otp);

  const submitOtp = async () => {
    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      var form = new FormData();
      form.append('email', props.data);
      form.append('otpCode', otp);
      const { data } = await axios.post(
        `${BASE_API_URL_V2}/trainer/emailVerified`,
        form,
        config,
      );
      console.log('submit OTP response', data);
      if (data.statusText === 'Success') {
        notify(data.message);
        history.push('/');
        console.log(data);
      } else {
        notify(data.message);
        setOtp('');
        console.log('error in else', { data });
      }
    } catch (err) {
      console.log('submit OTP Error', err);
      notify(err.response.data.error);
    }
  };

  useEffect(() => {
    submitEmail();
  }, []);

  console.log(otp);

  const resendOTP = async () => {
    console.log('props.data', props?.data);
    // console.log('Email for agian otp', email);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    var form = new FormData();
    form.append('email', props?.data);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/trainer/sendTrainerOtpEmail`,
      form,
      config,
    );
    console.log({ data });
    if (data.statusText === 'Success') {
      // setSendOtp('Sent');
      notify('OTP SENT : ' + data.message);
      setOtp('');
      // window.alert(data.message);

      console.log(data);
    } else {
      notify('error: Email ' + data.message);
      console.log(data);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Form.Group>
        <label for="usr">Otp:</label>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{ color: 'green' }}
          containerStyle={{
            // backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'space-around',
          }}
          inputStyle={{
            height: 50,
            width: 50,

            // backgroundColor: 'gray',
          }}
        />
      </Form.Group>
      <div className="col-12 text-right">
        <p
          style={{
            cursor: 'pointer',
            color: 'blue',
            display: 'inline-block',
          }}
          onClick={() => resendOTP()}
          className="mb-10 RA-13-fnt-size "
        >
          resend OTP?
        </p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button
          onClick={() => submitOtp()}
          variant="outline-secondary"
          id="button-addon2"
        >
          Submit
        </Button>
        <ToastContainer newestOnTop={true} />
      </div>
    </div>
  );
};

export default Verification;

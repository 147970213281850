import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getBookedCourses } from 'stateManagement/actions/studentActions';
import { BASE_API_URL_V2, defineValue, defineDate } from 'store';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { axiosClient } from 'helper';
import { ToastContainer, toast } from 'react-toastify';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import ReactStars from 'react-rating-stars-component';
import moment from 'moment';
import TimeKeeper from 'react-timekeeper';

import { useTranslation } from 'react-i18next';
const TransactionCard = styled(Card)`
  h3,
  p {
    text-align: left;
  }
  height: 200px;
  &:hover {
    cursor: pointer;
  }
`;

const MenuItems = styled.h4`
  color: #fc8200;

  text-align: center;
  &:hover {
    cursor: pointer;
    color: #2c2e94;
  }
  &.active-item {
    color: #2c2e94;
    border: 1px solid orange;
    border-radius: 5px;
    background-color: orange;
    color: white;
  }
`;

const CustomBody = styled(Card.Body)`
  p {
    margin: 0px;
  }
`;

export default function MyBookings(props) {
  const langRedux = useSelector(state => state.language);

  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [data, setData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowReview, setModalShowReview] = useState(false);
  const [modalShowRescedule, setModalShowRescedule] = useState(false);
  const [reason, setReason] = useState('');
  const [cancel, setCancel] = useState(false);
  const [statusChanged, setStatusChanged] = useState({
    state: false,
    data: null,
  });
  useEffect(() => {
    if (statusChanged.state) {
      setData(statusChanged.data);
      setTimeout(() => {
        setModalShowReview(true);
      }, 1500);
    }
  }, [statusChanged]);
  const dispatch = useDispatch();

  const { studentId, userId } = useSelector(state => state.studentLogin);
  const { loading, error, bookedCourses } = useSelector(
    state => state.getBookedCourses,
  );

  window.toast = toast;

  // function to filter the courses by status.
  function filterCourses(status) {
    if (bookedCourses) {
      status === null
        ? setFilteredCourses(bookedCourses)
        : setFilteredCourses(
            bookedCourses.filter(course => course.status === status),
          );
    }
    console.log(bookedCourses, 'yeah');
  }

  // getting courses on page load and everytime user is changed.
  useEffect(() => {
    // getCourses();
    dispatch(getBookedCourses(userId));
  }, [userId]);

  // for filtering the courses everytime the filter is changed.
  useEffect(() => {
    filterCourses(filter);
  }, [filter]);

  // setting filtered courses to bookedCourses after it has loaded. for first intitial display with no filter selected. as filteredCourses is the array being rendered.
  useEffect(() => {
    if (!loading && bookedCourses && bookedCourses.length > 0) {
      setFilteredCourses(bookedCourses);
    }
  }, [bookedCourses]);

  //modal for course detail
  function MyVerticallyCenteredModal(props) {
    const langRedux = useSelector(state => state.language);

    const { t } = useTranslation();
    const { studentId, accessToken } = useSelector(state => state.studentLogin);
    const { courses } = useSelector(state => state.getCourses);

    const [loadingComp, setLoadingComp] = useState(false);
    const [loadingCanc, setLoadingCanc] = useState(false);
    const [rating, setRating] = useState(false);

    async function changeStatus(datas, val) {
      try {
        const { data, config } = await axiosClient().post(
          `student/createCourseBookingCancelReq`,
          datas,
        );
        console.log(data, config);
        window.toast.success('State Changed!');
        dispatch(getBookedCourses(studentId));
        props.onHide();
        if (val !== 'cancelled') {
          props.onStatusChanged();
        }
        setLoadingCanc(false);
        setCancel(false);
        setReason('');
        setLoadingComp(false);
      } catch (error) {
        console.log(error);
      }
    }

    async function postData(data, val) {
      if (val === 'cancelled') {
        setLoadingCanc(true);
      } else {
        setLoadingComp(true);
      }
      const formData = new FormData();
      formData.append('bookingId', data);
      formData.append('reason', reason);
      changeStatus(formData, val);
    }
    const ratings = rating => {
      const Star = <i className="fas fa-star stars"></i>;
      const stars = [];
      for (let i = 0; i < rating; i++) {
        stars.push(Star);
      }
      return stars;
    };
    const checkIt = val => {
      if (val === 'completed' || val === 'cancelled') return true;
      else return false;
    };
    const changeColor = val => {
      if (val === 'completed') {
        return 'green';
      }
      if (val === 'cancelled') {
        return 'red';
      }
      if (val === 'booked') {
        return 'orange';
      }
    };
    const getDiff = val => {
      const accessTokenReduxDate = moment(val);

      const mydatee = moment.utc().format();

      const dateDiff = accessTokenReduxDate.diff(mydatee, 'days');
      return dateDiff;
    };
    useEffect(() => {
      const ans = courses.filter(e => e._id === props.data?.course._id);
      console.log(ans);
      setRating(ans);
    }, []);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('about_booking')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'myBook-card'}>
          <div className="col-sm-12">
            <div className="card">
              <div
                className="card-image"
                style={{
                  backgroundImage: `url(${props.data?.course.imagesPath[0]})`,
                }}
              ></div>
              <div>
                {/* <i className="fas fa-ambulance"></i> */}

                <p className="font-weight-strong category">
                  {
                    props.data?.course[
                      langRedux.language === 'en' ? 'nameEnglish' : 'nameArabic'
                    ]
                  }
                </p>
              </div>
              <p className="font-weight-strong price">
                <div className="rating">
                  <div className="stars">
                    {ratings(rating[0]?.ratings ? rating[0].ratings : 0).map(
                      (star, index) => (
                        <i
                          key={index}
                          style={{ color: '#F6BA00', fontSize: 16 }}
                        >
                          {star}
                        </i>
                      ),
                    )}
                  </div>
                </div>
              </p>
              {checkIt(props.data?.status) === true ? (
                <hr />
              ) : (
                <>
                  <div className="buttonCont" style={{ border: 0 }}>
                    {/* <a
                      className="align-middle text-center"
                      onClick={() => postData(props.data, 'completed')}
                    >
                      {!loadingComp ? (
                        <span> {t('completed')}</span>
                      ) : (
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
                      )}
                    </a> */}
                    <a
                      className="align-middle text-center back"
                      onClick={() => setCancel(true)}
                    >
                      {!loadingCanc ? (
                        <span> {t('cancel')}</span>
                      ) : (
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
                      )}
                    </a>
                  </div>
                  {getDiff(props.data?.date) > 0 ? (
                    <div className="buttonCont">
                      <a
                        className="align-middle text-center rese"
                        style={{ width: '80%' }}
                        onClick={() => props.onRes()}
                      >
                        <span>{t('reschedule')}</span>
                      </a>
                    </div>
                  ) : null}
                </>
              )}
              {cancel && (
                <>
                  <textarea
                    placeholder={'Your Reason'}
                    className="form-control"
                    rows="5"
                    onChange={e => setReason.bind(e.target.value)}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 10,
                    }}
                  >
                    <Button
                      onClick={() => postData(props.data._id, 'cancelled')}
                      style={{ marginRight: 10 }}
                    >
                      Submit
                    </Button>
                    <Button onClick={() => setCancel(false)}>Cancel</Button>
                  </div>
                </>
              )}
              <div className="description">
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-database"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('status')}
                    </p>
                  </div>
                  <div>
                    <p
                      className="value"
                      style={{
                        marginBottom: 0,
                        fontSize: 20,
                        textTransform: 'uppercase',
                        color: changeColor(props.data?.status),
                      }}
                    >
                      {defineValue(props.data?.status)}
                    </p>
                  </div>
                </div>

                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-table"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('joining_date')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.date}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-clock"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {' '}
                      {t('time')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.time}
                    </p>
                  </div>
                </div>
                {/* <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-id-badge"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('transaction_ID')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.transaction.transaction}
                    </p>
                  </div>
                </div> */}
                <div className="item">
                  <div className="key">
                    <i
                      class="fa fa-usd"
                      aria-hidden="true"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('transaction_amt')}
                    </p>
                  </div>
                  <div>
                    <p
                      className="value"
                      style={{ marginBottom: 0, color: 'green' }}
                    >
                      {props.data?.transaction.amount} AED
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-map-marker"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {' '}
                      {t('institute')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {props.data?.course.institute}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fa fa-graduation-cap"
                      aria-hidden="true"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {t('instructed_by')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {defineValue(
                        props.data?.trainer.firstName +
                          ' ' +
                          props.data?.trainer.lastName,
                      )}
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="key">
                    <i
                      className="fas fa-calendar"
                      style={{ marginTop: 2, width: 20 }}
                    >
                      {' '}
                    </i>
                    <p style={{ marginBottom: 0, marginLeft: 14 }}>
                      {' '}
                      {t('booked_on')}
                    </p>
                  </div>
                  <div>
                    <p className="value" style={{ marginBottom: 0 }}>
                      {defineDate(props.data?.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function MyVerticallyCenteredModalReview(props) {
    const langRedux = useSelector(state => state.language);

    const { studentId, accessToken } = useSelector(state => state.studentLogin);
    const [rate, setRate] = useState(0);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const FormGroup = styled(Form.Group)`
      margin-bottom: 10px;
    `;
    console.log(props);
    const ratingChanged = newRating => {
      setRate(newRating);
    };
    const schema = yup.object({
      // fullName: yup.string().required(),
      category: yup.string().required(),
      course: yup.string().required(),
      Feedback: yup.string().required(),
    });

    const onSubmit = values => {
      var data = new FormData();
      // if (rate && rate >= 1) {
      data.append('courseId', props.data?.course._id);
      data.append('studentUserId', studentId);
      data.append('trainerId', props.data?.trainer._id);
      data.append('rating', rate);
      var d = { h: props.data.course._id, k: studentId, i: props.data.trainer };
      console.log(d);
      if (rate !== 0) {
        postDate(data);
      } else {
        window.toast.error('Fill the stars!');
      }
    };
    // Getting the reviews and setting them in local component state.
    async function postDate(value) {
      const c = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      setLoading(true);
      try {
        const { data, config } = await axios.post(
          `${BASE_API_URL_V2}/student/assignRating`,
          value,
          c,
        );

        if (data.status) {
          console.log('r');
          window.toast.success('Thanks for your rating!');
          setLoading(false);
          props.onHide();
          // setShowAlert(true);
        }
        // if the status is false which means booking unsuccessful.
        else if (data.status === false) {
          console.log('2', data, config);
          setLoading(false);
          window.toast.error(data.error);
        } else {
          setLoading(false);
          console.log(data);
          window.toast.success(data.message);
        }
      } catch (error) {
        console.log(error, 'hi');
        window.toast.success(data.message);
        setLoading(false);
      }
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('rate_trainer')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik enableReinitialize validationSchema={schema}>
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  onSubmit(values);
                }}
              >
                <Container>
                  <Row
                    className="justify-content-center align-items-top"
                    style={{ padding: '60px 0px' }}
                  >
                    <Col xs={12} sm={8}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: 20,
                          marginTop: -50,
                          flexDirection: 'column',
                        }}
                      >
                        <h3 style={{ textAlign: 'center' }}>
                          {t('how_was')}{' '}
                          {defineValue(
                            props.data?.trainer.firstName +
                              ' ' +
                              props.data?.trainer.lastName,
                          )}
                          ?
                        </h3>
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={40}
                          activeColor="#ffd700"
                        />
                      </div>

                      <Button type="submit" style={{ width: '100%' }}>
                        {t('submit')}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }

  function MyVerticallyCenteredModalReschedule(props) {
    const { studentId, accessToken } = useSelector(state => state.studentLogin);
    const [rate, setRate] = useState(0);
    const [timeValue, setTimeValue] = useState(props.data?.time);
    const [dateValue, setDateValue] = useState(props.data?.date);

    const [loading, setLoading] = useState(false);
    const FormGroup = styled(Form.Group)`
      margin-bottom: 10px;
    `;
    console.log(props);

    const onSubmit = values => {
      var data = new FormData();
      // if (rate && rate >= 1) {

      data.append('bookedCourseId', props.data?._id);
      data.append('date', dateValue);
      data.append('time', timeValue);

      postDate(data);
    };
    // Getting the reviews and setting them in local component state.
    async function postDate(value) {
      const c = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      setLoading(true);
      try {
        const { data, config } = await axios.post(
          `${BASE_API_URL_V2}/student/updateBookedCourseDate`,
          value,
          c,
        );
        console.log(data);
        if (data.status) {
          console.log('r');
          window.toast.success('Course Updated!');
          props.onPost();
          setTimeout(() => {
            props.onHide();
          }, 500);
          setLoading(false);
        }
        // if the status is false which means booking unsuccessful.
        else if (data.status === false) {
          console.log('2', data, config);
          setLoading(false);
          window.toast.error(data.error);
        } else {
          setLoading(false);
          console.log(data);
          window.toast.success(data.message);
        }
      } catch (error) {
        console.log(error, 'hi');
        window.toast.success(data.message);
        setLoading(false);
      }
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reschedule
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik enableReinitialize>
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  onSubmit(values);
                }}
              >
                <Container>
                  <Row
                    className="justify-content-center align-items-top"
                    style={{ padding: '60px 0px' }}
                  >
                    <Col xs={12} sm={8}>
                      <Form.Group>
                        <Form.Label>At What Time*</Form.Label>
                        <div>
                          <TimeKeeper
                            className="form-control"
                            time={timeValue}
                            name="time"
                            onChange={data => {
                              setTimeValue(data.formatted12);
                            }}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Date*</Form.Label>
                        <Form.Control
                          required
                          type="date"
                          name="date"
                          value={dateValue}
                          onChange={date => setDateValue(date.target.value)}
                        />
                      </Form.Group>
                      <Button type="submit" style={{ width: '100%' }}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <>
      <Container style={{ marginTop: '5%' }}>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onStatusChanged={() => setStatusChanged({ state: true, data: data })}
          onRes={() => {
            setModalShow(false);
            setModalShowRescedule(true);
          }}
          data={data}
        />
        <MyVerticallyCenteredModalReview
          show={modalShowReview}
          onHide={() => setModalShowReview(false)}
          data={data}
        />
        <MyVerticallyCenteredModalReschedule
          show={modalShowRescedule}
          onHide={() => setModalShowRescedule(false)}
          onPost={() => dispatch(getBookedCourses(studentId))}
          data={data}
        />
        <Col className="justify-content-center">
          <Col sm={12} xs={12}>
            <h2 style={{ textAlign: 'center' }}> {t('my_bookings')}</h2>
          </Col>
          <Col sm={12} xs={12} style={{ marginTop: 20 }}>
            <Row className="justify-content-space-around mb-5">
              <Col>
                <MenuItems
                  className={filter === null ? 'active-item' : ''}
                  onClick={() => {
                    setFilter(null);
                  }}
                >
                  {t('all')}
                </MenuItems>
              </Col>
              <Col>
                <MenuItems
                  className={filter === 'booked' ? 'active-item' : ''}
                  onClick={() => {
                    setFilter('booked');
                  }}
                >
                  {t('booked')}
                </MenuItems>
              </Col>
              <Col>
                <MenuItems
                  className={filter === 'completed' ? 'active-item' : ''}
                  onClick={() => {
                    setFilter('completed');
                  }}
                >
                  {t('completed')}
                </MenuItems>
              </Col>
              <Col>
                <MenuItems
                  className={filter === 'cancelled' ? 'active-item' : ''}
                  onClick={() => {
                    setFilter('cancelled');
                  }}
                >
                  {t('cancelled')}
                </MenuItems>
              </Col>
            </Row>
          </Col>

          <Row>
            {!loading &&
              filteredCourses.map(course => (
                <div className="myBook col-sm-12 col-lg-6" key={course._id}>
                  <div
                    // className="col-sm-12 col-lg-6"
                    style={{ marginBottom: 20 }}
                  >
                    {console.log(course)}

                    <div className="card">
                      <div
                        className="card-image"
                        style={{
                          backgroundImage: `url(${
                            course.course.nameEnglish === undefined
                              ? undefined
                              : course.course?.imagesPath[0]
                          })`,
                        }}
                      ></div>
                      <p className="font-weight-strong price">
                        <span style={{ color: '#81B662' }}>$ </span> 100
                      </p>
                      <div className="description">
                        {/* <i className="fas fa-ambulance"></i> */}
                        <h6 className="font-weight-strong">
                          {defineValue(
                            course.course.nameEnglish === undefined
                              ? undefined
                              : course.course[
                                  langRedux.language === 'en'
                                    ? 'nameEnglish'
                                    : 'nameArabic'
                                ],
                          )}
                        </h6>
                        <p className="font-weight-strong category">
                          {defineValue(
                            course.course.nameEnglish === undefined
                              ? undefined
                              : course.course.category[
                                  langRedux.language === 'en'
                                    ? 'nameEnglish'
                                    : 'nameArabic'
                                ],
                          )}
                        </p>
                        <p className="font-weight-strong small-detail">
                          <i className="fas fa-map-marker"></i> {t('offerd_by')}
                          : {course.course?.institute}
                        </p>
                        <p className="font-weight-strong small-detail">
                          <i className="fas fa-book"></i> {t('status')}:{' '}
                          {defineValue(course.status)}
                        </p>
                        <p className="font-weight-strong small-detail">
                          <i className="fas fa-book"></i> {t('booked_on')}:{' '}
                          {defineDate(course.createdAt)}
                        </p>
                      </div>
                      <a
                        onClick={() => {
                          setData(course);
                          setModalShow(true);
                        }}
                      >
                        <i className="fas fa-eye"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </Row>
        </Col>
      </Container>
      <ToastContainer newestOnTop={true} />
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Form, Col, InputGroup, Button, Row, Alert } from 'react-bootstrap';
import nations from '../../../../common/nationalities.json';
import countryDialCodes from '../../../../common/countryDialCodes.json';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageCropper from 'common/ImageCropper';
import placeholderPic from 'images/person.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../../../stateManagement/actions/studentActions';
import axios from 'axios';
import { BASE_API_URL_V2 } from 'store';

import { useTranslation } from 'react-i18next';
// Registration form schema
const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(8, 'Should be minimum 8 characters long')
    .required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
  dob: yup.date().required('Date of Birth is required field'),
  nationality: yup.string().required(),
  //courses: yup.string().required(),
  //testDate: yup.date().required(),
  otp: yup.string().required(),
  grade: yup.string().required(),
  //driving: yup.string().required(),
  country: yup.string().required(),
  phoneCode: yup.string().required(),
  phone: yup.string().required(),
  gender: yup.string().required(),
});

export default function StudentRegistration() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const notify = message => toast(message);
  // console.log('location.showOtpField', location.showOtpField);
  // getting available courses from redux state
  const { loading, error, courses } = useSelector(state => state.getCourses);
  const history = useHistory();

  // keeping track of registration status
  const [registerStatus, setRegisterStatus] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  // console.log('emailForOtp', emailForOtp);

  // loading for when waiting for response from server.
  const [submitLoading, setSubmitLoading] = useState(false);

  // Redirecting if registration is successful

  useEffect(() => {
    //Getting the course list from api.
    dispatch(getCourses());

    // redirecting if registered status is true
    if (registerStatus) {
      setTimeout(() => {
        history.push('/login');
      }, 2000);
    }
  }, [registerStatus]);
  const langRedux = useSelector(state => state.language);

  const [fileName, setFileName] = useState(t('profile_pic'));
  // const [url, setURL] = useState('');
  // const [fileParts, setFileParts] = useState(fileNamesArray);
  const [iosCode, setIsoCode] = useState();
  const [passwordHidden, setPasswordHidden] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(false);
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] = useState(
    'password',
  );
  const [countryDialCode, setCountryDialCode] = useState('+ xyz');
  const [blob, setBlob] = useState(null);
  const [userimage, setUserImage] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [inputImg, setInputImg] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [sendOtp, setSendOtp] = useState('Send OTP');
  const [successMess, setSuccessMedd] = useState({
    text1: '',
    text2: '',
    state: false,
  });

  const [sendVerify, setSendVerify] = useState({
    text: 'Resend',
    color: 'black',
  });

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // onSubmit function for register form.
  const dataSubmit = async res => {
    // setting the loading to true
    setSubmitLoading(true);

    // creating form data object to be sent.
    var registerAccountData = new FormData();
    registerAccountData.append('firstName', res.values.firstName);
    registerAccountData.append('lastName', res.values.lastName);
    registerAccountData.append('email', res.values.email);
    registerAccountData.append('password', res.values.password);
    registerAccountData.append('phoneNo', res.values.phone);
    registerAccountData.append('nationality', res.values.nationality);
    registerAccountData.append('residenceCountry', res.values.country);
    registerAccountData.append('gender', res.values.gender);
    //registerAccountData.append('testDate', res.values.testDate);
    //registerAccountData.append('testType', res.values.grade);
    registerAccountData.append('dateOfBirth', res.values.dob);
    //registerAccountData.append('courseLearningMethod', res.values.driving);
    //registerAccountData.append('courses', selectedCourses);
    blob && registerAccountData.append('userImage', userimage);

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/studentSignUp`,
      registerAccountData,
      config,
    );
    console.log('register response data', data);

    if (data.status) {
      submitEmail(res.values.email);
      // setShowOtp(true);

      setSubmitLoading(false);

      setSuccessMedd({
        text1: 'Otp Send',
        text2: 'Enter Otp sent to you email address.',
        state: true,
      });
      setShowAlert(true);
      history.push({
        pathname: '/register/Verifyotp',
        email: res.values.email,
      });
    } else if (data.status === false) {
      setSubmitLoading(false);
      setRegisterStatus(false);
      setErrorMessage(data.error);
      setShowAlert(true);
    } else {
      setSubmitLoading(false);
      notify(data.error);
    }
    setSubmitLoading(false);
    console.log(data);
  };

  const submitEmail = async val => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    console.log(val);
    var form = new FormData();
    form.append('email', val);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/sendStudentOtpEmail`,
      form,
      config,
    );
    if (data.statusText === 'Success') {
      setSendOtp('Sent');
      console.log(data);
    } else {
      window.alert(data.message);
      console.log(data);
    }
  };

  const submitOtp = async val => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    var form = new FormData();
    form.append('email', val.email);
    form.append('otpCode', val.otp);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/emailVerified`,
      form,
      config,
    );
    if (data.statusText === 'Success') {
      setSendVerify({
        text: 'Verified!',
        color: 'green',
      });

      setSubmitLoading(false);
      setRegisterStatus(true);
      setSuccessMedd({
        text1: 'Account Created Successfully',
        text2: 'Your new account has been registered.',
        state: true,
      });
      setShowAlert(true);
      console.log(data);
    } else {
      window.alert(data.message);
      console.log(data);
    }
  };

  // handle change in selected courses.
  const handleCourseChange = e => {
    var options = e.target.options;
    var value = selectedCourses;
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected && !value.includes(options[i].value)) {
        value.push(options[i].value);
      } else if (options[i].selected && value.includes(options[i].value)) {
        const index = value.indexOf(options[i].value);
        if (index > -1) {
          value.splice(index, 1);
        }
      }
    }
    setSelectedCourses(value);
    console.log('value', value);
    console.log('selectedCourses', selectedCourses);
  };

  const changeDialCode = e => {
    const index = countryDialCodes.findIndex(
      countryDialCode => countryDialCode.name === e.target.value,
    );
    setCountryDialCode(countryDialCodes[index]?.dialCode || '+ xyz');
  };
  const uploadimage = async e => {
    setHidden(true);
  };

  const getBlob = blob => {
    console.log(blob);
    setBlob(blob);
  };

  const getBase64 = file =>
    new Promise(function(resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject('Error: ', error);
    });

  const onFileChange = e => {
    // setFileUrl(window.URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    setImgFile(file);
    const reader = new FileReader();
    setHidden(false);

    getBase64(e.target.files[0])
      .then(result => {
        console.log(result, file);
        setUserImage(file);
      })
      .catch(e => console.log(e));

    reader.addEventListener(
      'load',
      () => {
        setInputImg(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
    setFileName(file.name);
  };

  const resendOTP = async email => {
    console.log('Email for agian otp', email);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    var form = new FormData();
    form.append('email', email);
    const { data } = await axios.post(
      `${BASE_API_URL_V2}/student/sendStudentOtpEmail`,
      form,
      config,
    );
    console.log({ data });
    if (data.statusText === 'Success') {
      setSendOtp('Sent');
      console.log(data);
    } else {
      window.alert(data.message);
      console.log(data);
    }
  };

  return (
    <>
      {showAlert ? (
        <Alert
          style={{
            position: 'fixed',
            top: '5%',
            left: '10%',
            zIndex: 500,
            width: '80%',
            alignContent: 'center',
          }}
          variant={successMess.state === true ? 'success' : 'danger'}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {successMess.state === true ? (
            <>
              <Alert.Heading>{successMess.text1}</Alert.Heading>
              <p> {successMess.text2}</p>
            </>
          ) : (
            <>
              <Alert.Heading>Account Creation Failed</Alert.Heading>
              <p> {errorMessage} </p>
            </>
          )}
        </Alert>
      ) : null}

      <Col xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          {blob ? (
            <img src={URL.createObjectURL(blob)} />
          ) : (
            <img className="img-fluid" src={placeholderPic} alt="profile" />
          )}
        </div>
      </Col>
      <Col xs={12} sm={12}>
        {inputImg && !hidden && (
          <Row className="mt-5">
            <Col className="mb-3" xs={12} sm={12} style={{ height: '500px' }}>
              <ImageCropper getBlob={getBlob} inputImg={inputImg} />
            </Col>
            <Col xs={12} sm={12}>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={uploadimage}>Update</Button>
              </div>
            </Col>
          </Row>
        )}
      </Col>

      <Col sm={12} xs={12}>
        <Formik
          enableReinitialize
          validationSchema={schema}
          onSubmit={() => {
            console.log(fileName, inputImg);
          }}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            dob: '',
            nationality: '',
            courses: [],
            testDate: '',
            grade: '',
            driving: '',
            country: '',
            phoneCode: '',
            phone: '',
            gender: '',
            otp: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate>
              <Form.Group>
                <Form.Label>{t('profile_pic')}</Form.Label>
                <Form.File
                  name="profilePicture"
                  label={fileName}
                  id="profilePicture"
                  onChange={e => onFileChange(e)}
                  accept="image/*"
                  custom
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="firstName">{t('first_name')}</label>
                <Form.Control
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  isInvalid={!!errors.firstName && touched.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('last_name')}</Form.Label>
                <Form.Control
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.lastName && touched.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('email')}</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>{t('password')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="password"
                    type={passwordFieldType}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.password && touched.password}
                  />
                  <InputGroup.Append>
                    <Button
                      style={{
                        backgroundColor: '#e9ecef',
                        border: '1px solid #ced4da',
                      }}
                      onClick={() => {
                        if (passwordHidden) {
                          setPasswordHidden(false);
                          setPasswordFieldType('password');
                        } else {
                          setPasswordHidden(true);
                          setPasswordFieldType('text');
                        }
                      }}
                    >
                      <i
                        className={
                          passwordHidden ? 'fas fa-eye-slash' : 'fas fa-eye'
                        }
                        style={{ color: '#495057' }}
                      ></i>
                    </Button>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('confirm_pass')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="confirmPassword"
                    type={confirmPasswordFieldType}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !!errors.confirmPassword && touched.confirmPassword
                    }
                  />
                  <InputGroup.Append>
                    <Button
                      style={{
                        backgroundColor: '#e9ecef',
                        border: '1px solid #ced4da',
                      }}
                      onClick={() => {
                        if (confirmPasswordHidden) {
                          setConfirmPasswordHidden(false);
                          setConfirmPasswordFieldType('password');
                        } else {
                          setConfirmPasswordHidden(true);
                          setConfirmPasswordFieldType('text');
                        }
                      }}
                    >
                      <i
                        className={
                          confirmPasswordHidden
                            ? 'fas fa-eye-slash'
                            : 'fas fa-eye'
                        }
                        style={{ color: '#495057' }}
                      ></i>
                    </Button>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('gender')}</Form.Label>
                <Form.Check
                  type="radio"
                  label="Male"
                  name="gender"
                  value="Male"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.gender && touched.gender}
                  required
                />
                <Form.Check
                  type="radio"
                  label="Female"
                  name="gender"
                  value="Female"
                  onChange={handleChange}
                  isInvalid={!!errors.gender && touched.gender}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.gender}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('date_of_birth')}</Form.Label>
                <Form.Control
                  name="dob"
                  type="date"
                  value={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.dob && touched.dob}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('nationality')}</Form.Label>
                <Form.Control
                  name="nationality"
                  as="select"
                  value={values.nationality}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.nationality && touched.nationality}
                  custom
                >
                  <option value="">-- {t('select_nationality')} --</option>
                  {nations.map(nation => (
                    <option key={nation.nationality} value={nation.nationality}>
                      {nation.nationality}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.nationality}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>{t('residence_country')}</Form.Label>
                <Form.Control
                  name="country"
                  as="select"
                  value={values.country}
                  onChange={e => {
                    handleChange(e);
                    changeDialCode(e);
                  }}
                  onBlur={handleBlur}
                  isInvalid={!!errors.country && touched.country}
                  custom
                >
                  <option value="">-- {t('select_residence')} --</option>
                  {countryDialCodes.map(dialCode => (
                    <option key={dialCode.isoCode} value={dialCode.name}>
                      {dialCode.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('phone')}</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend style={{ maxWidth: 100 }}>
                    <Form.Control
                      name="phoneCode"
                      as="select"
                      value={countryDialCode}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      custom
                    >
                      {countryDialCodes.map(dialCode => (
                        <option
                          key={dialCode.isoCode}
                          value={dialCode.dialCode}
                        >
                          {`${dialCode.isoCode} ${dialCode.dialCode}`}
                        </option>
                      ))}
                    </Form.Control>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.phone && touched.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              {showOtp ? (
                <>
                  <Form.Group>
                    <Form.Label>{t('otp')}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="otp"
                        type="otp"
                        value={values.otp}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.otp && touched.otp}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.otp}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="col-12 text-right">
                    <p
                      style={{
                        cursor: 'pointer',
                        color: 'blue',
                        display: 'inline-block',
                      }}
                      onClick={() => resendOTP(values.email)}
                      className="mb-10 RA-13-fnt-size "
                    >
                      resend OTP?
                    </p>
                  </div>
                  <Button
                    className="btn btn-primary btn-md col-12"
                    onClick={() => {
                      submitOtp(values);
                    }}
                  >
                    {!submitLoading ? (
                      <span>{t('verify_it')}</span>
                    ) : (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      ></span>
                    )}
                  </Button>
                </>
              ) : (
                <Button
                  className="btn btn-primary btn-md col-12"
                  onClick={() => {
                    dataSubmit({ values });
                  }}
                >
                  {!submitLoading ? (
                    <span>{t('create_acc')}</span>
                  ) : (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                    ></span>
                  )}
                </Button>
              )}

              <ToastContainer />
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
}

import {
  STUDENT_LOGIN_REQUEST,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGIN_FAIL,
  STUDENT_LOGOUT,
  STUDENT_REGISTER_REQUEST,
  STUDENT_REGISTER_SUCCESS,
  STUDENT_REGISTER_FAIL,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  GET_STUDENT_DETAILS_REQUEST,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_STUDENT_DETAILS_FAIL,
  STUDENT_DETAILS_RESET,
  GET_COURSE_DETAILS_REQUEST,
  GET_COURSE_DETAILS_SUCCESS,
  GET_COURSE_DETAILS_FAIL,
  GET_BOOKED_COURSES_REQUEST,
  GET_BOOKED_COURSES_SUCCESS,
  GET_BOOKED_COURSES_FAIL,
  GET_COURSES_RESET,
  GET_COURSE_DETAILS_RESET,
  CHANGE_LANG,
} from '../constants/studentConstants';

export const studentLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_LOGIN_REQUEST:
      return {
        loading: true,
      };

    case STUDENT_LOGIN_SUCCESS:
      return {
        loading: false,
        accessToken: action.payload.student_login_token,
        studentId: action.payload.studentId,
        userId: action.payload.userId,
      };
    case STUDENT_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case STUDENT_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const studentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STUDENT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_STUDENT_DETAILS_SUCCESS:
      return {
        loading: false,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        email: action.payload.user.email,
        dob: action.payload.user.dateOfBirth,
        nationality: action.payload.user.nationality,
        residenceCountry: action.payload.user.residenceCountry,
        phone: action.payload.user.phoneNo,
        image: action.payload.user.imagePath,
        grade: action.payload.testType,
        learningMethod: action.payload.courseLearningMethod,
        remarks: action.payload.remarks,
        _id: action.payload.user._id
      };
    case GET_STUDENT_DETAILS_FAIL:
      return {
        loading: false,
        
      };
    case STUDENT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const getCoursesReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case GET_COURSES_REQUEST:
      return {
        loading: true,
      };
    case GET_COURSES_SUCCESS:
      return {
        loading: false,
        courses: [...action.payload],
      };
    case GET_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_COURSES_RESET:
      return {
        loading: false,
        error: null,
        courses: [],
      };

    default:
      return state;
  }
};

export const getCourseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COURSE_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_COURSE_DETAILS_SUCCESS:
      return {
        loading: false,
        courseDetails: action.payload,
      };
    case GET_COURSE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_COURSE_DETAILS_RESET:
      return {
        loading: false,
        error: null,
        courseDetails: null,
      };

    default:
      return state;
  }
};

export const getBookedCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BOOKED_COURSES_REQUEST:
      return {
        loading: true,
      };
    case GET_BOOKED_COURSES_SUCCESS:
      return {
        loading: false,
        bookedCourses: action.payload,
      };
    case GET_BOOKED_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getLang = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return {
        language: action.payload,
      };

    default:
      return state;
  }
};

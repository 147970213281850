import React, { useState, useEffect } from 'react';
import banner from 'images/banner.jpg';
import { Link } from 'react-router-dom';
import { BASE_API_URL_V2 } from 'store';
import axios from 'axios';
import { get } from 'services/RestService';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
export default function AboutDetails() {
  const [loading, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState(null);
  const [profile, setProfile] = useState({});
  const [activeId, setActiveId] = useState(profile.id);
  const langRedux = useSelector(state => state.language);
  const { t } = useTranslation();
  // function for getting About data.
  const getAboutData = () => {
    setLoading(true);
    get('/student/getAbout')
      .then(data => {
        console.log(data);
        setAboutData(data);
        setLoading(false);
        setProfile({
          id: '1',
          title:
            data?.[
              langRedux.language === 'en'
                ? 'companyHeadingEnglish'
                : 'companyHeadingArabic'
            ],
          desc:
            data?.[
              langRedux.language === 'en'
                ? 'companyContentEnglish'
                : 'companyContentArabic'
            ],
        });
        setActiveId('1');
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  // fetching About data from api.
  useEffect(() => {
    getAboutData();
  }, []);
  const companyAbout = [
    {
      id: '1',
      title:
        aboutData?.[
          langRedux.language === 'en'
            ? 'companyHeadingEnglish'
            : 'companyHeadingArabic'
        ],
      desc:
        aboutData?.[
          langRedux.language === 'en'
            ? 'companyContentEnglish'
            : 'companyContentArabic'
        ],
    },
    {
      id: '2',
      title:
        aboutData?.[
          langRedux.language === 'en'
            ? 'missionHeadingEnglish'
            : 'missionHeadingArabic'
        ],
      desc:
        aboutData?.[
          langRedux.language === 'en'
            ? 'missionContentEnglish'
            : 'missionContentArabic'
        ],
    },
    {
      id: '3',
      title:
        aboutData?.[
          langRedux.language === 'en'
            ? 'visionHeadingEnglish'
            : 'visionHeadingArabic'
        ],
      desc:
        aboutData?.[
          langRedux.language === 'en'
            ? 'visionContentEnglish'
            : 'visionContentArabic'
        ],
    },
    {
      id: '4',
      title:
        aboutData?.[
          langRedux.language === 'en'
            ? 'clientHeadingEnglish'
            : 'clientHeadingArabic'
        ],
      desc:
        aboutData?.[
          langRedux.language === 'en'
            ? 'clientContentEnglish'
            : 'clientContentArabic'
        ],
    },
  ];

  return (
    <div className="about">
      <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
        <div className="overlay">
          <h1 className="text-white mb-2">{t('about')}</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="font-weight-bold">
                  {t('home')}
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-capitalize"
                aria-current="page"
              >
                {t('about')}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center align-items-center text-center quick-links">
          {!loading &&
            companyAbout.map(about => (
              <div key={about.key}>
                <div>
                  <div
                    className={`btn btn-md btn-primary space ${
                      activeId === about.id ? 'active-link' : null
                    }`}
                    onClick={() => {
                      setProfile(about);
                      setActiveId(about.id);
                    }}
                  >
                    {about.title}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="container">
        <h3 className="text-center">{profile.title}</h3>
        <p className="text-left">{profile.desc}</p>
      </div>
    </div>
  );
}

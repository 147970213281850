import React from 'react';

export default function Map() {
  return (
    <div className="mapBox">
      <iframe
        className="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.6725756413966!2d55.44910961538387!3d25.415768429034227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f57e40bff842f%3A0xa484fc8f76371307!2sCivil%20Academy%20Of%20Driving%20-%20Test%20Location!5e0!3m2!1sen!2s!4v1603710164450!5m2!1sen!2s"
        width="100%"
        frameBorder="0"
        style={{ border: 0 }}
        title={window.appName}
      ></iframe>
    </div>
  );
}

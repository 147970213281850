import React, { useState, useRef, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import axios from 'axios';
// Images
import client1 from 'images/our-clients/1.png';
import client2 from 'images/our-clients/2.png';
import client3 from 'images/our-clients/3.png';
import client4 from 'images/our-clients/4.png';
import client5 from 'images/our-clients/5.png';
import { BASE_API_URL_V2 } from 'store';
import { imageChecker } from 'store';
import { useTranslation } from 'react-i18next';
import { get } from 'services/RestService'
import { useSelector } from 'react-redux'
export default function Clients() {
  const [loading, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState(null);
  const langRedux = useSelector(state => state.language);

  const {t} = useTranslation()
  // function for getting About data.
  async function getAboutData() {
    setLoading(true);
    get('/student/getAbout').then((res)=> {
      setAboutData(res);
      setLoading(false);
    }).catch((error)=> {
      console.error(error);
      setLoading(false);
    })
  }

  // fetching About data from api.
  useEffect(() => {
    getAboutData();
  }, []);
  const [clients] = useState([
    { image: client1, title: '' },
    { image: client2, title: '' },
    { image: client3, title: '' },
    { image: client4, title: '' },
    { image: client5, title: '' },
  ]);

  // Carousel Custom Settings - Start https://github.com/sag1v/react-elastic-carousel/pull/17

  const carouselRef = useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(clients.length);
    }
  };

  const customArrow = ({ type, onClick }) => {
    const pointer = type === 'PREV' ? `left` : 'right';
    return (
      <button onClick={onClick} className="customArrow">
        <i className={`fas fa-long-arrow-alt-${pointer} fa-2x`}></i>
      </button>
    );
  };

  // Carousel Custom Settings - End

  return (
    <div className="clients">
      <div className="overlay">
        <div className="container">
          <div className="row mb-5">
            <div className="col">
              <h1 className="text-center">
              {
                      aboutData?.[
                        langRedux.language === 'en'
                          ? 'clientHeadingEnglish'
                          : 'clientHeadingArabic'
                      ]
                    }
              </h1>
              <div className="divider"></div>
              <p className="text-center">
              {
                      aboutData?.[
                        langRedux.language === 'en'
                          ? 'clientContentEnglish'
                          : 'clientContentArabic'
                      ]
                    }
              </p>
            </div>
          </div>

          <Carousel
            ref={carouselRef}
            itemsToShow={window.innerWidth <= 767 ? 1 : 4}
            pagination={false}
            renderArrow={customArrow}
            onNextStart={onNextStart}
            onPrevStart={onPrevStart}
            // enableAutoPlay={true}
            // autoPlaySpeed={3000}
          >
            {!loading &&
              aboutData?.clientImages.map((client, i) => {
                return (
                  <div key={i}>
                      <img
                        src={client}
                        alt={'client.title'}
                        title={'client.title'}
                      />
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import image from 'images/features.png';
import { useTranslation } from 'react-i18next';

export default function Features() {
  const {t} = useTranslation()

  const leftColData = [
    {
      icon: 'far fa-address-card',
      title: t('quick_license'),
      summary: t('quick_license_c'),
    },
    {
      icon: 'fas fa-car',
      title: t('unlimited_car_support'),
      summary: t('unlimited_car_support_c'),
    },
    {
      icon: 'fas fa-film',
      title: t('video_classes'),
      summary: t('video_classes_c'),
    },
  ];
  const rightColData = [
    {
      icon: 'fas fa-user-tie',
      title: t('experience_instructors'),
      summary: t('experience_instructors_c'),
    },
    {
      icon: 'far fa-clock',
      title: t('any_time_any_place'),
      summary: t('any_time_any_place_c'),
    },
    {
      icon: 'fas fa-map-signs',
      title: t('learning_roads'),
      summary:
      t('learning_roads_c'),
    },
  ];

  return (
    <div className="features section">
      <div className="container">
        <div className="row mb-5">
          <div className="col">
            <h1 className="text-center">{t('our_features')}</h1>
            <div className="divider"></div>
            <h6 className="text-center">
             {t('enjoy_features')}
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4 leftCol">
            {leftColData.map((d, i) => {
              return (
                <div key={i} className="media">
                  <i
                    className={`${d.icon} fa-2x mr-3 text-theme-secondary d-md-none`}
                  ></i>
                  <div className="media-body text-right">
                    <h5 className="m-0 text-theme-primary">{d.title}</h5>
                    <span className="text-muted">{d.summary}</span>
                  </div>
                  <i
                    className={`${d.icon} fa-2x ml-3 text-theme-secondary d-none d-md-block`}
                  ></i>
                </div>
              );
            })}
          </div>
          <div className="col-sm-12 col-md-4 imageCol">
            <img
              src={image}
              alt="Civil Academy Features"
              className="img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-4 rightCol">
            {rightColData.map((d, i) => {
              return (
                <div key={i} className="media">
                  <i
                    className={`${d.icon} fa-2x mr-3 text-theme-secondary`}
                  ></i>
                  <div className="media-body">
                    <h5 className="m-0 text-theme-primary">{d.title}</h5>
                    <span className="text-muted">{d.summary}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
